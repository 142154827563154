import React, { FunctionComponent } from 'react'
import { Rating } from '../HolidayCard/Rating'
import { TripadvisorLogo } from './TripadvisorLogo'
import { TripadvisorHotelInfo } from './TripadvisorHotelInfo'
import { TripadvisorVotesList } from './TripadvisorVotesList'
import { ReadMore } from '../ReadMore'

import './TripadvisorReviews.scss'

interface TripadvisorReviewsProps {
  hotelName: string
  hotelLocation: string
  tripadvisorRating: number
  tripadvisorReviews: string
}

const votesData = [
  { type: 'Exellent', value: 2034 },
  { type: 'Very Good', value: 1305 },
  { type: 'Average', value: 202 },
  { type: 'Poor', value: 134 },
  { type: 'Terrible', value: 20 },
]

const TripadvisorReviews: FunctionComponent<TripadvisorReviewsProps> = ({
  hotelName,
  hotelLocation,
  tripadvisorRating,
  tripadvisorReviews,
}: TripadvisorReviewsProps) => {

  return (
    <div className='tripadvisor-reviews-wrapper'>
      <div className='tripadvisor-reviews-top-section'>
        <TripadvisorLogo />
        <TripadvisorHotelInfo
          hotelName={hotelName}
          hotelLocation={hotelLocation}
          tripadvisorRating={tripadvisorRating}
          tripadvisorReviews={tripadvisorReviews}
        />
      </div>
      <h2 className='tripadvisor-votes-title'>Tripadvisor Traveller Rating</h2>
      <TripadvisorVotesList votesData={votesData} />

      {/* List of reviews will be generic after fetching proper reviews data */}

      <div className='tripadvisor-reviews-list'>
        <div className='tripadvisor-review'>
          <h2 className='tripadvisor-votes-title'>Amazing time!</h2>
          <div className='tripadvisor-rating-with-date'>
            <Rating type='circle' rating={4} />
            <span>Jun 2022</span>
          </div>
          <div className='tripadvisor-review-user-info'>Joeblogs84, Manchester, United Kingdom</div>
          <ReadMore>
            Lorem ipsum dolor sit amet. Est modi ipsa est dolorum aspernatur aut alias voluptate cum
            commodi perferendis. Ut deserunt perspiciatis aut voluptatem totam eum odio voluptate
            cum Quis illum quo dolores obcaecati non consequatur corrupti. Et tempore galisum vel
            aspernatur voluptatibus est soluta voluptatem. Ut dolorum aperiam sit omnis illo est
            omnis voluptatem eum sequi atque sit itaque commodi.
          </ReadMore>

          <div className='tripadvisor-review'>
            <h2 className='tripadvisor-votes-title'>Amazing time!</h2>
            <div className='tripadvisor-rating-with-date'>
              <Rating type='circle' rating={4} />
              <span>Jun 2022</span>
            </div>
            <div className='tripadvisor-review-user-info'>
              Joeblogs84, Manchester, United Kingdom
            </div>
            <ReadMore>
              Lorem ipsum dolor sit amet. Est modi ipsa est dolorum aspernatur aut alias voluptate
              cum commodi perferendis. Ut deserunt perspiciatis aut voluptatem totam eum odio
              voluptate cum Quis illum quo dolores obcaecati non consequatur corrupti. Et tempore
              galisum vel aspernatur voluptatibus est soluta voluptatem. Ut dolorum aperiam sit
              omnis illo est omnis voluptatem eum sequi atque sit itaque commodi.
            </ReadMore>
          </div>

          <div className='tripadvisor-review'>
            <h2 className='tripadvisor-votes-title'>Amazing time!</h2>
            <div className='tripadvisor-rating-with-date'>
              <Rating type='circle' rating={4} />
              <span>Jun 2022</span>
            </div>
            <div className='tripadvisor-review-user-info'>
              Joeblogs84, Manchester, United Kingdom
            </div>
            <ReadMore>
              Lorem ipsum dolor sit amet. Est modi ipsa est dolorum aspernatur aut alias voluptate
              cum commodi perferendis. Ut deserunt perspiciatis aut voluptatem totam eum odio
              voluptate cum Quis illum quo dolores obcaecati non consequatur corrupti. Et tempore
              galisum vel aspernatur voluptatibus est soluta voluptatem. Ut dolorum aperiam sit
              omnis illo est omnis voluptatem eum sequi atque sit itaque commodi.
            </ReadMore>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TripadvisorReviews
