import React, { useEffect, RefObject } from 'react'

type RefOrArray = RefObject<HTMLElement> | RefObject<HTMLElement>[];

export function useOutsideClick(refs: RefOrArray, handler: (e: Event) => void) {
  useEffect(() => {
    const listener = (event: Event) => {
      const refArray = Array.isArray(refs) ? refs : [refs];
      if (refArray.some(ref => ref.current && ref.current.contains(event.target as Node))) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
}

// How to use
//
// import { useOutsideClick } from '../../hooks/useOutsideClick'
// ---
// const innerRef = useRef<HTMLDivElement>(null)
// useOutsideClick(innerRef, outsideClickCallback)
// ---
// return (
//   <div className='custom-marker-wrapper' ref={innerRef}>
