import { FC, useState } from 'react';
import { IPagination } from './Interfaces';
import './Pagination.scss';
import MenuArrow from '.././assets/menu-arrow.svg';
export const Pagination: FC<IPagination> = ({ by, onChange, count, keyValue }) => {
  const [range, setRange] = useState(0);
  const [selected, setSelected] = useState(0);
  if (!count && !by) {
    return <></>;
  }

  const amount = Math.round(count / by);
  const arrayOfAmount = new Array(amount).fill('').map((val, index) => {
    return index;
  });

  const decrease = () => {
    if (range > 0) {
      setRange(Number(range - 1));
    }
  };
  const increase = () => {
    if (range < amount - 3) {
      setRange(Number(range + 1));
    }
  };

  return (
    <div className='pagination-flex-container'>
      <div className='pagination-circle' onClick={decrease}>
        <span className='pagination-img'>
          <img src={MenuArrow} height='15' className='pagination-left-arrow' />
        </span>
      </div>
      {arrayOfAmount.slice(range, range + 3).map((val, index) => {
        return (
          <div
            onClick={() => {
              setSelected(val);
              onChange(val);
            }}
            className={`pagination-circle ${selected === val ? 'pagination-selected' : ''}`}
            key={keyValue + index}
          >
            <span className='pagination-text'>{val + 1}</span>
          </div>
        );
      })}
      <div className='pagination-circle' onClick={increase}>
        <span className='pagination-img'>
          <img src={MenuArrow} height='15' className='pagination-right-arrow' />
        </span>
      </div>
    </div>
  );
};
