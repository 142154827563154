import { useState, FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface IFrameParams {
  children: any;
}

const IFrame: FC<IFrameParams> = ({ children }) => {
  const [ref, setRef] = useState<any>();
  const container = ref?.contentWindow?.document?.body;

  const [bindWindowMessage, setBindWindowMessage] = useState(false);

  /**
   useEffect(() => {
    if (!bindWindowMessage && ref?.window) {
      setBindWindowMessage(true);
      ref.window.onmessage = null;
      ref.window.addEventListener(
        'message',
        function (event: any) {
          console.log(event.origin);
          if (event.origin === 'https://centinelapistag.cardinalcommerce.com') {
            // const data = JSON.parse(event.data);
            // console.warn('Merchant received a message:', data);
            // if (data !== undefined && data.Status) {
            // Extract the value of SessionId for onward processing.
            // const paymentSessionId = data.SessionId;
            // Display , just for example.
            // const div = this.document.getElementById('payment_session_id');
            // console.log(div);
            // div.innerHTML = payment_session_id;
            // }
          }
        },
        false,
      );
    }
  }, [bindWindowMessage, ref]);
  **/

  return (
    <iframe style={{ display: 'none' }} ref={setRef}>
      {container && createPortal(children, container)}
    </iframe>
  );
};

export { IFrame };
