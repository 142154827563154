import { FunctionComponent, useState } from 'react'
import './MenuChild.scss'
import { IMenuChildOptions, MenuChildItemCategory, ISubChildMenuOptions } from './Interfaces'
import { Icons } from '../Menu/Interfaces'

const MenuSubChild: FunctionComponent<ISubChildMenuOptions> = ({ activeMenu, title, isMobile }) => {
  return (
    <div
      className={`MenuChild-Container-SubChild ${
        isMobile ? 'MenuChild-Container-SubChild-Mobile' : ''
      }`}
    >
      {activeMenu[0].map((submenu) => {
        return (
          <div
            key={`${submenu.category}`}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <span className='MenuChild-Container-SubChild-Title'>{submenu.category} </span>
            <div className='MenuChild-Container-SubChild-Col'>
              {submenu.title.map((val, i) => {
                return <span key={`${val}${i}${submenu.category}`}>{val}</span>
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const MenuChild: FunctionComponent<IMenuChildOptions> = ({ items, isMobile }) => {
  const [isOpen, setIsOpen] = useState<[MenuChildItemCategory[], string] | false>(false)

  return (
    <div>
      <div className='MenuChild-Container'>
        {items?.map((item, index) => (
          <div key={`${item.title}-MenuChild-${index}`}>
            <div
              className={`MenuChild-Grid ${
                item.menuChildren ? 'MenuChild-Grid-Children' : 'MenuChild-Grid-NoChildren'
              } ${isOpen && isOpen[1] === item.title ? 'MenuChild-Grid-Active' : ''}
              ${isMobile ? 'MenuChild-Grid-Mobile' : ''}
              `}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  if (item.onClick) item.onClick()
                  if (item.menuChildren)
                    setIsOpen(
                      isOpen && isOpen[1] === item.title ? false : [item.menuChildren, item.title],
                    )
                }}
              >
                {item.title}
                {item.menuChildren && (
                  <img
                    className={'MenuChild--Item-chevron'}
                    width={15}
                    height={15}
                    src={Icons.DOWN_ARROW}
                    alt={`${item.title}`}
                  />
                )}
              </span>
            </div>
            {isOpen && isMobile && isOpen[1] === item.title && (
              <MenuSubChild activeMenu={isOpen} title={item.title} isMobile={isMobile} />
            )}
          </div>
        ))}
      </div>
      {isOpen && !isMobile && (
        <MenuSubChild activeMenu={isOpen} title={isOpen[1]} isMobile={isMobile} />
      )}
    </div>
  )
}

export { MenuChild as MenuChild }
export { MenuSubChild }
