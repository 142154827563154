import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './RootStore';
import { IBasket } from '../components/Basket/Interfaces';
import image from '../components/assets/image.png';

export class BasketStore {
  constructor(RootStoreRef: RootStore) {
    this.rootStore = RootStoreRef;

    makeObservable(this, {
      basket: observable,
      bookingId: observable,
      setBasket: action,
      setDefault: action,
      setBookingId: action,
    });
  }

  rootStore: RootStore;
  bookingId = 0;

  basket: IBasket = {
    destination: {
      title: '',
      subtitle: '',
      images: [],
      breakfast: true,
      roomType: 'Double',
      adults: 2,
      children: 0,
    },
    rating: {
      tripAdvisor: 4,
      holidayPeople: 5,
      amount: 2300,
    },
    people: {
      adults: 2,
      children: 0,
    },
    outbounds: [],
    inbounds: [],
    extras: {},
  };

  setDefault = () => {
    this.basket = {
      destination: {
        title: '',
        subtitle: '',
        images: [],
        breakfast: true,
        roomType: '',
        adults: 2,
        children: 0,
      },
      rating: {
        tripAdvisor: 4,
        holidayPeople: 5,
        amount: 2300,
      },
      people: {
        adults: 2,
        children: 0,
      },
      outbounds: [],
      inbounds: [],
      extras: {},
    };
  };
  setBasket = (saveBasket: IBasket) => {
    this.basket = saveBasket;
  };

  setBookingId = (saveBookingId: number) => {
    this.bookingId = saveBookingId;
  };
}
