import { FC } from 'react';
import { ILoader } from './Interfaces';
import './Loader.scss';

import IconFlights from '../assets/loader-flights.svg';
import IconRooms from '../assets/loader-hotels.svg';
import IconExtras from '../assets/loader-extras.svg';
import IconLoaderTick from '../assets/loader-tick.svg';
import AtolIcon from '../assets/atol.svg';
import AtbaIcon from '../assets/atba.svg';

export const LoaderSpinner = () => {
  return (
    <div className='loader-ring'>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export const LoaderTick = () => {
  return (
    <div>
      <div className='loader-tick'>
        <img src={IconLoaderTick} height={40} width={40} />
      </div>
    </div>
  );
};

export const Loader: FC<ILoader> = ({ loading, disable, gridTemplate }) => {
  return (
    <div className='loader-container'>
      <div className={`loader-grid ${gridTemplate ? gridTemplate : ''}`}>
        {!disable?.flights && (
          <div>
            <div>Finding all flights options {loading.flights}</div>
            <div className='loader-icons-container'>
              <img className='loader-icons' src={IconFlights} height={100} width={100} />
            </div>
            <div className='loader-icons-container'>
              {!loading.flights && <LoaderSpinner />}
              {loading.flights && <LoaderTick />}
            </div>
          </div>
        )}
        {!disable?.rooms && (
          <div>
            <div>Finding all room options {loading.rooms}</div>
            <div className='loader-icons-container'>
              <img className='loader-icons' src={IconRooms} height={100} width={100} />
            </div>
            <div className='loader-icons-container'>
              {!loading.rooms && <LoaderSpinner />}
              {loading.rooms && <LoaderTick />}
            </div>
          </div>
        )}
        {!disable?.extras && (
          <div>
            <div>Finding travel extras {loading.extras}</div>
            <div className='loader-icons-container'>
              <img className='loader-icons' src={IconExtras} height={100} width={100} />
            </div>
            <div className='loader-icons-container'>
              {!loading.extras && <LoaderSpinner />}
              {loading.extras && <LoaderTick />}
            </div>
          </div>
        )}
      </div>
      Loading may take up to 30 seconds.
      <div>
        <img width={150} src={AtbaIcon} />
        <img width={50} src={AtolIcon} />
      </div>
    </div>
  );
};
