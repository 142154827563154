import React, { ReactNode, FunctionComponent } from 'react'
import { components, DropdownIndicatorProps } from 'react-select'
import './Room.scss'

export interface CustomDropdownIndicatorProps extends DropdownIndicatorProps {
  isDisabled: boolean
  isFocused: boolean
  isSelected: boolean
  children: ReactNode
  onChange: () => void
}

export const CustomDropdownIndicator: FunctionComponent<CustomDropdownIndicatorProps> = ({
  getStyles,
  isDisabled,
  isFocused,
  ...rest
}) => {

  return (
    <components.DropdownIndicator
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      getStyles={getStyles}
    >
      <div className='custom-dropdown-indicator'>
        <span className='custom-dropdown-indicator-icon'></span>
      </div>
    </components.DropdownIndicator>
  )
}
