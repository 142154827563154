import React, { useState, useEffect } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import './SearchResultsPage.scss';
import { noop } from 'lodash';
import { FiltersSidebar } from '../../components/FiltersSidebar/FiltersSidebar';
import { HolidayCard } from '../../components/HolidayCard';
import { PageLoader } from '../../components/PageLoader';
import { Option } from '../../components/Autocomplete';
import { useSearchParams } from 'react-router-dom';
import {
  generateRoomString,
  useAvailableBoards,
  useAvailableFeatures,
  useAvailableRatings,
  useAvailableTripRatings,
  useOffers,
} from '../../hooks/useRequest';
import { BOARD_TYPES, FACILITIES_TYPES } from './Interfaces';
import { SearchBar } from '../../components/SearchBar';
import { Guests } from '../../components/Room';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useModal } from '../../hooks/useModal';
import { MapModal } from '../../components/MapModal';
import { Button } from '../../components/Button';

import filterIcon from '../../components/assets/filters.svg';

export const getAirportsFromParams = (airportsOptions: any) => {
  const airports: string[] = [];
  if (!airportsOptions) return airports;
  airportsOptions.map((airport: Option, i: number) => {
    if (Array.isArray(airport.value)) {
      airports.push(...airport.value);
    } else {
      airports.push(airport.value as string);
    }
  });
  return [...new Set(airports)];
};

export const createRoomsFromParams = (rooms: { [key: string]: Guests }) => {
  if (!rooms) return JSON.stringify(['2']);

  const persons: string[] = [];

  Object.values(rooms).map((room: Guests, i: number) => {
    if (room.children === 0) {
      persons.push(`${room.adults}`);
    } else {
      persons.push(`${room.adults}-8,${room.children}`);
    }
  });

  return JSON.stringify(persons);
};

export const getDurationFromParams = (parsedSearchParams: any) => {
  return parsedSearchParams.duration?.value || 7;
};

export const getDestinationsFromParams = (parsedSearchParams: any, type: string) => {
  if (!parsedSearchParams.destinations) return [];

  return parsedSearchParams.destinations
    .filter((destination: Option) => destination.type === type)
    .map((destination: Option) => destination.value);
};

export const getDepartureDateFromParams = (parsedSearchParams: any, type: string) => {
  return parsedSearchParams?.departure_date && parsedSearchParams?.departure_date[type];
};

export default function SearchResultsPage() {
  const [offers, setOffers] = useState<any>([]); // TODO
  const [startIndex, setStartIndex] = useState<number>(0);

  const [boardFilterInformation, setBoardFilterInformation] = useState<any>({});

  const [filtersData, setFiltersData] = useState<any>({
    boardType: [],
    ratings: [],
    facilities: [],
    tripRatings: [],
  });
  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(isMobile ? true : false);
  const [isTotalPrice, setIsTotalPrice] = useState(false);
  const [actualParams, setActualParams] = useState<any>();
  const [searchItemType, setSearchItemType] = useState<string>();
  const [showFilters, setShowFilters] = useState(false);
  const [offerId, setOfferId] = useState<any>();
  const [isOpen, toggle] = useModal(false);

  const [searchParams] = useSearchParams();
  const parsedSearchParams = JSON.parse(Object.fromEntries([...searchParams]).params);

  const getValuesFromFilters = (type: string) => {
    const filterType = filtersData && filtersData[type];
    if (!filterType) {
      return '[]';
    }

    switch (type) {
      case 'boardType':
        return `[${filtersData['boardType'].map((i: any) => BOARD_TYPES[i.title])}]`;
      case 'facilities':
        return `[${filtersData['facilities'].map((i: any) => FACILITIES_TYPES[i.title])}]`;
      case 'ratings':
        return `[${filtersData['ratings'].map((i: any) => i.title)}]`;
      case 'tripRatings':
        return `[${filtersData['tripRatings'].map((i: any) => {
          const title = i.title;
          if (title === 1) {
            return parseFloat(title);
          }

          return `${parseFloat(title)}`;
        })}]`;
      default:
        return '[]';
    }
  };

  const getTripRatingValues = (tripRatingValues: any) => {
    if (!tripRatingValues) {
      return [];
    }

    const ratingFilters: any = [];

    tripRatingValues.forEach((item: any) => {
      const ratingValue = Object.assign({}, item);
      ratingFilters.push(ratingValue);
    });

    return ratingFilters;
  };

  const airports = getAirportsFromParams(parsedSearchParams.airports);
  const persons = generateRoomString(
    parsedSearchParams.rooms || [
      {
        adults: 2,
        children: 0,
      },
    ],
  );
  const duration = getDurationFromParams(parsedSearchParams);
  const destinations = getDestinationsFromParams(parsedSearchParams, 'destination');
  const regions = getDestinationsFromParams(parsedSearchParams, 'region');
  const resorts = getDestinationsFromParams(parsedSearchParams, 'resort');
  const departureDate = getDepartureDateFromParams(parsedSearchParams, 'value');
  const departureType = getDepartureDateFromParams(parsedSearchParams, 'label');

  const ratingValues = getValuesFromFilters('ratings');
  const boardsValues = getValuesFromFilters('boardType');
  const features = getValuesFromFilters('facilities');
  const tripRatings = getValuesFromFilters('tripRatings');

  const requestedData = useOffers(
    JSON.stringify(airports),
    JSON.stringify(destinations),
    JSON.stringify(regions),
    JSON.stringify(resorts),
    departureDate,
    duration,
    JSON.stringify(persons),
    startIndex === 0 ? 0 : startIndex * 25,
    ratingValues,
    boardsValues,
    features,
    departureType,
    tripRatings,
  );

  const {
    data: boardType,
    isLoading: boardsLoading,
    isSuccess: isBoardTypeSuccess,
    refetch: boardTypeRefetch,
  } = useAvailableBoards(
    JSON.stringify(persons),
    JSON.stringify(airports),
    duration,
    ratingValues,
    tripRatings,
    features,
    departureDate,
    departureType,
    JSON.stringify(regions),
    JSON.stringify(resorts),
    JSON.stringify(destinations),
  );

  const {
    data: ratings,
    isSuccess: isRatingSuccess,
    refetch: refetchRatings,
  } = useAvailableRatings(
    JSON.stringify(persons),
    JSON.stringify(airports),
    duration,
    boardsValues,
    tripRatings,
    features,
    departureDate,
    departureType,
    JSON.stringify(regions),
    JSON.stringify(resorts),
    JSON.stringify(destinations),
  );

  const {
    data: featuresData,
    isSuccess: isFeaturesSuccess,
    refetch: featuresRefetch,
  } = useAvailableFeatures(
    JSON.stringify(persons),
    JSON.stringify(airports),
    duration,
    boardsValues,
    ratingValues,
    tripRatings,
    departureDate,
    departureType,
    JSON.stringify(regions),
    JSON.stringify(resorts),
    JSON.stringify(destinations),
  );

  const {
    data: tripRatingsData,
    isSuccess: isTripRatingsSuccess,
    refetch: tripRatingRefetch,
  } = useAvailableTripRatings(
    JSON.stringify(persons),
    JSON.stringify(airports),
    duration,
    boardsValues,
    ratingValues,
    features,
    departureDate,
    departureType,
    JSON.stringify(regions),
    JSON.stringify(resorts),
    JSON.stringify(destinations),
  );

  useEffect(() => {
    isSearchBarOpen && !isMobile
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'scroll');
  }, [isSearchBarOpen]);

  useEffect(() => {
    setActualParams(parsedSearchParams);
  }, []);

  const onFiltersChange = (selectedFilters: any) => {
    setFiltersData(selectedFilters);
  };

  const onMapLinkClickHandler = (id: any) => {
    setOfferId(id);

    toggle();
  };

  useEffect(() => {
    if (boardType?.available_boards?.result) {
      setBoardFilterInformation((prevState: any) => ({
        ...prevState,
        boardType: boardType.available_boards.result,
      }));
    }
  }, [boardType]);

  useEffect(() => {
    if (ratings?.available_ratings?.result) {
      setBoardFilterInformation((prevState: any) => ({
        ...prevState,
        ratings: ratings.available_ratings.result,
      }));
    }
  }, [ratings]);

  useEffect(() => {
    if (featuresData?.available_features?.result) {
      setBoardFilterInformation((prevState: any) => ({
        ...prevState,
        facilities: featuresData.available_features.result,
      }));
    }
  }, [featuresData]);

  useEffect(() => {
    if (tripRatingsData?.available_trip_ratings?.result) {
      setBoardFilterInformation((prevState: any) => ({
        ...prevState,
        tripRatings: getTripRatingValues(tripRatingsData.available_trip_ratings.result),
      }));
    }
  }, [tripRatingsData]);

  useEffect(() => {
    if (requestedData.isLoading) {
      refetchRatings();
      tripRatingRefetch();
      boardTypeRefetch();
      featuresRefetch();
    }
  }, [requestedData.isLoading]);

  const isRequestsSuccess =
    isRatingSuccess && isBoardTypeSuccess && isFeaturesSuccess && isTripRatingsSuccess;
  const countOfHolidays = requestedData?.data?.offers?.count;

  return (
    <BaseLayout
      isOverlayOpen={isSearchBarOpen}
      onSearchItemClick={setSearchItemType}
      onLogin={noop}
      openMenu={noop}
      searchParams={actualParams}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
    >
      <div className='search-results-page'>
        {requestedData?.isLoading ? (
          <PageLoader />
        ) : (
          <>
            <div className={isSearchBarOpen ? 'search-bar-wrapper' : 'hidden'}>
              <SearchBar
                resetItemType={setSearchItemType}
                searchItemType={searchItemType}
                onSearchParamsChange={setActualParams}
                onOverlayClick={setIsSearchBarOpen}
                isBoardType={false}
                isSearchPage={true}
              />
            </div>
            {isRequestsSuccess && countOfHolidays > 0 && (
              <div className='search-results-filters-show-on-mobile'>
                <FiltersSidebar
                  onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
                  filtersData={boardFilterInformation}
                  isModalOpen={showFilters}
                  toggleModal={setShowFilters}
                  selectedValues={filtersData}
                  isTotalPriceFlag={isTotalPrice}
                  onFiltersChange={(event) => onFiltersChange(event)}
                />
              </div>
            )}
            <div className='search-bar-wrapper'>
              <h1 className='search-page-main-heading'>
                {(isRequestsSuccess && countOfHolidays) || 0} Holidays Found
              </h1>
              <div className='search-page-button-wrapper'>
                <Button
                  label='Show filters'
                  rightIcon={filterIcon}
                  onClick={() => {
                    setShowFilters(true);
                  }}
                />
              </div>
              <div className={'search-results-container'}>
                {isRequestsSuccess && countOfHolidays > 0 && (
                  <FiltersSidebar
                    onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
                    filtersData={boardFilterInformation}
                    isModalOpen={false}
                    selectedValues={filtersData}
                    isTotalPriceFlag={isTotalPrice}
                    onFiltersChange={(event) => onFiltersChange(event)}
                  />
                )}

                <div className={'holiday-card-container'}>
                  {isRequestsSuccess &&
                    requestedData?.data &&
                    requestedData?.data?.offers?.result?.map((offer: any, i: number) => {
                      return (
                        <HolidayCard
                          onMapLinkClick={() => onMapLinkClickHandler(offer.id)}
                          isTotalPriceFlag={isTotalPrice}
                          pricePerPerson={offer.price_per_person}
                          key={`${offer.id}-${i}`}
                          id={offer.id}
                          name={offer.accommodation.name}
                          location={`${offer.accommodation.resort.regions[0].destinations[0].name}, ${offer.accommodation.resort.regions[0].name}, ${offer.accommodation.resort.name}`}
                          coordinates={{
                            lat: parseFloat(offer.accommodation.lat),
                            lng: parseFloat(offer.accommodation.long),
                          }}
                          tripadvisorRating={offer.accommodation.trip_advisor_rating}
                          rating={Math.round(parseInt(offer.accommodation.rating))}
                          date={offer.duration}
                          images={offer.accommodation.images}
                          airports={offer.flights}
                          discount={offer.discount}
                          searchParams={parsedSearchParams || null}
                          meals={offer.rooms}
                          {...offer}
                        ></HolidayCard>
                      );
                    })}
                </div>
              </div>
            </div>

            <MapModal
              offers={requestedData?.data?.offers?.result}
              isOpen={isOpen}
              offerId={offerId}
              toggle={toggle}
              searchParams={parsedSearchParams || ''}
              onTotalPriceChange={(e: any) => setIsTotalPrice(e)}
              isTotalPriceFlag={isTotalPrice}
            />
          </>
        )}
      </div>
    </BaseLayout>
  );
}
