import React, { FunctionComponent } from 'react';
import '../SummaryCard.scss';
import { camelCase } from 'lodash';

export interface FlightsProps {
  title: string;
  icon: string;
  flightsData: {
    outboundDate: string;
    outboundAirline: string;
    outboundDateRange: string;
    inboundDate: string;
    inboundAirline: string;
    inboundDateRange: string;
    atolProtection?: string;
  };
}

export const FlightsCard: FunctionComponent<FlightsProps> = ({
  title,
  icon,
  flightsData,
}: FlightsProps) => {
  const { atolProtection } = flightsData;
  const generateDateRanges = (data: any, outboundAirline: string) => {
    return data.split('-').map((item: string, index: number) => {
      return (
        <>
          <div key={`${camelCase(item)}-${index}`} className='summary-card-list--item'>
            <span className={'light-title'}>{!index ? outboundAirline : ''}</span>
            <span>{item}</span>
          </div>
          {!index && (
            <div key={`${camelCase(item)}-separator`} className=' separator'>
              <span> </span>
              <span>-</span>
            </div>
          )}
        </>
      );
    });
  };

  return (
    <div className='summary-card-wrapper'>
      <div className='summary-card-wrapper--header'>
        <img src={icon} alt='Section icon' />
        <span className={'card-title'}>{title}</span>
      </div>

      <div className='summary-card-wrapper--body'>
        <div className='summary-card-list'>
          <div className='summary-card-list--item'>
            <span>Outbound</span>
            <span>{flightsData.outboundDate}</span>
          </div>
          {generateDateRanges(flightsData.outboundDateRange, flightsData.outboundAirline)}
          <div className='summary-card-list--item'>
            <span>Inbound</span>
            <span>{flightsData.inboundDate}</span>
          </div>
          {generateDateRanges(flightsData.inboundDateRange, flightsData.inboundAirline)}
        </div>
        {atolProtection && (
          <>
            <div className='summary-divider'></div>
            <span className={'card-title'}>Atol Protection</span>
            <div className='summary-card-list'>
              <div className='summary-card-list--item'>
                <span>Atol Protected</span>
                <span>{atolProtection}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
