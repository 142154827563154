import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import './Board.scss';

export interface BoardProps {
  type: string;
  priceInfo?: string;
  selected?: boolean;
  onClick?: () => void;
}

export const Board: FunctionComponent<BoardProps> = ({
  type,
  priceInfo,
  selected = false,
  onClick,
}: BoardProps) => {
  return (
    <div className={cn('board', { selected: selected })} onClick={onClick}>
      <div className='board-type'>{type}</div>
      <div className='board-info'>
        {selected ? (
          <span className='price-bold yellow'>Selected</span>
        ) : (
          <div>
            {priceInfo && (
              <>
                From <span className='price-bold'>{priceInfo}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
