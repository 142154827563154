import React, { FunctionComponent } from 'react'
import logo from '../assets/tripadvisor_huge.svg'

import './TripadvisorLogo.scss'

export const TripadvisorLogo: FunctionComponent = () => {
  
  return (
    <div className='tripadvisor-logo-wrapper'>
      <span className='tripadvisor-logo-title'>Traveller reviews powered by</span>
      <img className='tripadvisor-logo' src={logo} alt='Tripadvisor' />
    </div>
  )
}
