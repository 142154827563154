import { FunctionComponent } from 'react';

import './SuccessIcon.scss';
import successIcon from '../../assets/success.svg'

export const SuccessIcon: FunctionComponent = () => {
  return (
    <>
      <div className='success-icon-wrapper'>
        <div className='success-icon-img'>
          <img src={successIcon} alt='' />
        </div>
        <p>Booking Confirmed!</p>
      </div>
    </>
  );
};
