import React, { FunctionComponent } from 'react';
import './HolidayCard.scss';
import { Image, ImageCarousel } from '../ImageCarousel';
import NameSection from './NameSection/NameSection';
import HolidayDetails from './HolidayDetails/HolidayDetails';
import HolidayCardPrice from './HolidayCardPrice/HolidayCardPrice';
import moment from 'moment';
import { Coordinates } from '../MapModal';

export interface Room {
  board_description: string;
}

export interface Flight {
  departure_airport: {
    code: string;
    name: string;
  };
  arrival_airport: {
    code: string;
    name: string;
  };
  arrival: string;
  departing: string;
  id: number;
  return_departing: string;
  return_arrival: string;
  return_number: string;
  return_airline: string;
  number: string;
  airline: string;
}

interface HolidayCardProps {
  id: string;
  name: string;
  images: Array<Image>;
  rating: number;
  coordinates: Coordinates;
  location: string;
  tripadvisorRating: number;
  tripadvisorReviews: string;
  date: string;
  rooms: Room[];
  flights: Flight[];
  price: number;
  pricePerPerson: number;
  discount: number;
  isTotalPriceFlag: boolean;
  searchParams: string;
  onMapLinkClick: () => void;
}

const HolidayCard: FunctionComponent<HolidayCardProps> = ({
  id,
  name,
  images,
  rating,
  onMapLinkClick,
  coordinates,
  location,
  tripadvisorRating,
  tripadvisorReviews,
  date,
  rooms,
  flights,
  price,
  pricePerPerson,
  discount,
  isTotalPriceFlag,
  searchParams,
}: HolidayCardProps) => {
  const generateDateValue = () => {
    const nightString = +date > 1 ? 'nights' : 'night';
    const departingValue = flights ? flights[0].departing : new Date();

    return `${moment(departingValue).format('D MMM YYYY')} - ${date} ${nightString}`;
  };
  return (
    <div className={'holiday-card-wrapper'}>
      <div className='holiday-card-wrapper--carousel-wrapper'>
        <ImageCarousel images={images} />
      </div>
      <div className='holiday-card-wrapper--details'>
        <NameSection
          onMapLinkClick={onMapLinkClick}
          location={location}
          name={name}
          rating={rating}
          tripadvisorRating={tripadvisorRating}
          tripadvisorReviews={tripadvisorReviews}
        />

        <HolidayDetails date={generateDateValue()} rooms={rooms} flights={flights} />

        <HolidayCardPrice
          searchParams={searchParams}
          id={id}
          price={isTotalPriceFlag ? price : pricePerPerson}
          discount={discount}
          isTotalPriceFlag={isTotalPriceFlag}
        />
      </div>
    </div>
  );
};

export default HolidayCard;
