export interface IStepper {
  onChange?: (value: number, direction?: stepDirection) => void;
  maxValue?: number;
  minValue?: number;
  defaultValue?: number;
}

export enum stepDirection {
  DOWN,
  UP,
}
