import React, { FunctionComponent, useState, useEffect } from 'react';
import { noop } from 'lodash';
import { Modal } from '../Modal';
import Map, { Place, Coordinates } from './Map';
import './MapModal.scss';
import { HolidayCard } from '../HolidayCard';
import { Toggle } from '../Toggle/Toggle';
import useMultipleMedia from '../../hooks/useMultipleMedia';

interface MapModalProps {
  offers?: any;
  offerId: string;
  shouldShowInfoBox?: boolean;
  isOpen: boolean;
  places?: Place[];
  center?: Coordinates;
  searchParams?: string;
  isTotalPriceFlag?: boolean;
  toggle?: () => void;
  setNewOffer?: (name: string) => void;
  onTotalPriceChange?: (v: boolean) => void;
}

const MapModal: FunctionComponent<MapModalProps> = ({
  offers,
  offerId,
  shouldShowInfoBox = false,
  places,
  center,
  searchParams,
  isOpen,
  isTotalPriceFlag = true,
  toggle = noop,
  setNewOffer = noop,
  onTotalPriceChange = noop,
}: MapModalProps) => {
  const getOfferById = (id: string) => {
    const _offers = offers || [];
    return _offers?.find((offer: any) => {
      return offer.id === id;
    });
  };

  const getPlaceById = (id: string) => {
    const _offers = offers || [];
    const place = _offers?.find((offer: any) => {
      return offer.id === id;
    });

    return {
      id: place?.id,
      price: isTotalPriceFlag ? place?.price : place?.price_per_person,
      title: place?.accommodation.name,
      position: {
        lat: parseFloat(place?.accommodation.lat),
        lng: parseFloat(place?.accommodation.long),
      },
    };
  };

  const [selectedPlace, setSelectedPlace] = useState<Place>(getPlaceById(offerId));
  const [actualOffer, setActualOffer] = useState<any>();
  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);

  useEffect(() => {
    setActualOffer(getOfferById(offerId));
    setSelectedPlace(getPlaceById(offerId));
  }, [offerId]);

  useEffect(() => {
    if (!selectedPlace) return;

    setActualOffer(getOfferById(selectedPlace.id));
  }, [selectedPlace]);

  const allPlaces = offers?.map((offer: any, i: number) => {
    return {
      id: offer.id,
      price: isTotalPriceFlag ? offer.price : offer.price_per_person,
      title: offer.accommodation.name,
      position: {
        lat: parseFloat(offer.accommodation.lat),
        lng: parseFloat(offer.accommodation.long),
      },
    };
  });

  const onMapLinkClickHandler = (id: string) => {
    setSelectedPlace(getOfferById(id));

    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className='map-modal-content-wrapper'>
        {selectedPlace && (
          <>
            <div className={`map-modal-sidebar ${isMobile ? 'mobile' : ''}`}>
              <Toggle
                onToggleChange={(v) => onTotalPriceChange(v)}
                value={isTotalPriceFlag}
                size={'large'}
                firstLabel={'Price Per Person'}
                secondLabel={'Total Price'}
              />
              <HolidayCard
                images={actualOffer?.accommodation.images}
                onMapLinkClick={() => onMapLinkClickHandler(selectedPlace.id)}
                location={actualOffer?.accommodation.address}
                name={actualOffer?.accommodation.name}
                rating={actualOffer?.accommodation.rating}
                tripadvisorRating={actualOffer?.accommodation.trip_advisor_rating}
                tripadvisorReviews={'100'} // TODO Should be changed when API will return relevant value
                date={actualOffer?.duration}
                rooms={actualOffer?.rooms}
                flights={actualOffer?.flights}
                searchParams={searchParams || ''}
                id={actualOffer?.id}
                price={isTotalPriceFlag ? actualOffer?.price : actualOffer?.price_per_person}
                discount={actualOffer?.discount}
                isTotalPriceFlag={isTotalPriceFlag}
                pricePerPerson={actualOffer?.price_per_person}
                coordinates={{
                  lat: parseFloat(actualOffer?.accommodation.lat),
                  lng: parseFloat(actualOffer?.accommodation.long),
                }}
              />
            </div>

            <Map
              shouldShowInfoBox={shouldShowInfoBox}
              places={allPlaces}
              center={
                allPlaces?.find((place: Place) => place.id === actualOffer?.id)?.position || {}
              }
              hotelName={actualOffer?.name}
              hotelLocation={actualOffer?.accommodation.address}
              rating={actualOffer?.accommodation.rating}
              tripadvisorRating={actualOffer?.accommodation.trip_advisor_rating}
              tripadvisorReviews={'100'}
              price={actualOffer?.price}
              onMarkerClick={(place: Place) => setSelectedPlace(place)}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default MapModal;
