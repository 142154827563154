import { noop } from 'lodash';
import React, { FunctionComponent, ReactNode } from 'react';
import { Header } from '../Header/Header';

import './BaseLayout.scss';
import { ProgressBar, StepState } from '../ProgressBar/ProgressBar';

interface BaseLayoutProps {
  children: ReactNode;
  searchParams?: any; // TODO Fix typing
  isOverlayOpen?: boolean;
  onLogin: () => void;
  openMenu: () => void;
  onSearchButtonClick?: () => void;
  onSearchItemClick?: (type: string) => void;
  progressBar?: StepState[];
  isBookingConfirmation?: boolean
}

const BaseLayout: FunctionComponent<BaseLayoutProps> = ({
  onLogin,
  openMenu,
  onSearchItemClick = noop,
  onSearchButtonClick = noop,
  isOverlayOpen,
  searchParams,
  children,
  progressBar,
  isBookingConfirmation = false
}: BaseLayoutProps) => {
  return (
    <>
      <Header
        onSearchItemClick={onSearchItemClick}
        onLogin={onLogin}
        openMenu={openMenu}
        progressBar={progressBar}
        onSearchButtonClick={onSearchButtonClick}
        searchParams={searchParams}
        isBookingConfirmation={isBookingConfirmation}
        isOverlayOpen={isOverlayOpen}
      />

      {children}
    </>
  );
};

export default BaseLayout;
