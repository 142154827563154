import React, { FunctionComponent } from 'react';
import './MonthPicker.scss';

interface MonthPickerProps {
  size?: 'small' | 'medium' | 'large';
  numberYears?: number;
  onMonthClick: (month: string) => void;
  availableMonths?: {
    enabled: number;
    month: number;
    month_text: string;
    year: number;
  }[];
}

export const MonthPicker: FunctionComponent<MonthPickerProps> = ({
  size = 'large',
  onMonthClick,
  availableMonths,
}: MonthPickerProps) => {
  const generateArray = () => {
    const tempArr: number[] = [];
    const availableYears = new Set<number>();
    if (availableMonths) {
      availableMonths.forEach((availableMonth) => {
        if (availableMonth.enabled) {
          availableYears.add(availableMonth.year);
        }
      });
    }
    for (const year of availableYears) {
      tempArr.push(year);
    }
    return tempArr;
  };

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return (
    <div className={`month-picker-wrapper month-picker-wrapper--${size}`}>
      <div className={`month-picker-wrapper--item month-picker-wrapper--item-${size}`}>
        {generateArray().map((item, index) => {
          const tempYear = item;
          const tempMonths = months.filter((month) => {
            return (
              availableMonths?.some((availableMonth) => {
                return (
                  availableMonth.enabled &&
                  availableMonth.month_text === month &&
                  availableMonth.year === tempYear
                );
              }) ?? false
            );
          });

          return (
            <div key={index} className={'year-wrapper'}>
              <div className="year-title">{tempYear}</div>
              <div className="months">
                {tempMonths.map((month) => {
                  return (
                    <span
                      key={month}
                      onClick={() => {
                        onMonthClick(`${month} ${tempYear}`);
                      }}
                    >
                      {month} {tempYear}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

