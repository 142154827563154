import React, { ReactNode, FunctionComponent, useState, useEffect } from 'react'
import { noop } from 'lodash'
import { Modal } from '../Modal'
import Map, { Place, Coordinates } from '../MapModal/Map'
import { HolidayTopDetails } from '../HolidayDetails'
import SimpleTabs from './SimpleTabs'
import hotelIcon from '../assets/hotel.svg'
import locationIcon from '../assets/location.svg'
import tripadvisorIcon from '../assets/tripadvisor_logo.svg'
import { ImageCarousel, Image } from '../ImageCarousel'
import { TripadvisorReviews } from '../Tripadvisor'

import './ModalWithTabs.scss'

interface ModalWithTabsProps {
  isOpen: boolean
  activeTabNumber?: number,
  toggle?: () => void
  places?: Place[]
  center: Coordinates
  hotelName: string
  hotelLocation: string
  rating: number
  tripadvisorRating: number
  tripadvisorReviews: string
  price: number
  images: Image[]
}

interface TabsData {
  tabsList: {
    name: string
    icon: string
  }[]
  tabsContent: ReactNode[]
}

const ModalWithTabs: FunctionComponent<ModalWithTabsProps> = ({
  isOpen,
  activeTabNumber = 0,
  toggle = noop,
  places,
  center,
  hotelName,
  hotelLocation,
  rating,
  tripadvisorRating,
  tripadvisorReviews,
  price,
  images,
}: ModalWithTabsProps) => {
  const [selectedPlace, setSelectedPlace] = useState<Place>()
  const [activeTab, setActiveTab] = useState<number>(activeTabNumber)

  const MapContent = (
    <Map
      shouldShowInfoBox={true}
      key={activeTab}
      places={places}
      center={center}
      hotelName={hotelName}
      hotelLocation={hotelLocation}
      rating={rating}
      tripadvisorRating={tripadvisorRating}
      tripadvisorReviews={tripadvisorReviews}
      price={price}
      onMarkerClick={(place: Place) => setSelectedPlace(place)}
    />
  )

  const HotelInformationContent = (
    <div className='hotel-information-wrapper'>
      <HolidayTopDetails
        hotelName={hotelName}
        hotelLocation={hotelLocation}
        tripadvisorRating={tripadvisorRating}
        tripadvisorReviews={tripadvisorReviews}
        rating={rating}
      />
      <div className='hotel-information-slider-wrapper'>
        <ImageCarousel images={images} />
      </div>
      <h3>Description</h3>
      <p>
        Lorem ipsum dolor sit amet. Est modi ipsa est dolorum aspernatur aut alias voluptate cum
        commodi perferendis. Ut deserunt perspiciatis aut voluptatem totam eum odio voluptate cum
        Quis illum quo dolores obcaecati non consequatur corrupti. Et tempore galisum vel aspernatur
        voluptatibus est soluta voluptatem. Ut dolorum aperiam sit omnis illo est omnis voluptatem
        eum sequi atque sit itaque commodi.{' '}
      </p>
    </div>
  )

  const TripadvisorReviewsContent = (
    <TripadvisorReviews
      hotelName={hotelName}
      hotelLocation={hotelLocation}
      tripadvisorRating={tripadvisorRating}
      tripadvisorReviews={tripadvisorReviews}
    />
  )

  const tabsData: TabsData = {
    tabsList: [
      { name: 'Hotel Information', icon: hotelIcon },
      { name: 'Map', icon: locationIcon },
      { name: 'Tripadvisor Reviews', icon: tripadvisorIcon },
    ],
    tabsContent: [HotelInformationContent, MapContent, TripadvisorReviewsContent],
  }

  return (
    <div className='modal-with-tabs'>
      <Modal isOpen={isOpen} toggle={toggle}>
        <SimpleTabs activeTabNumber={activeTabNumber} tabsData={tabsData} onTabChange={setActiveTab} />
      </Modal>
    </div>
  )
}

export default ModalWithTabs
