import React, { FunctionComponent } from 'react';
import './Rating.scss';
import star from '../../assets/star.svg';
import filledCircle from '../../assets/filledCircle.svg';
import circle from '../../assets/circle_empty.svg';

interface HolidayCardProps {
  type: 'star' | 'circle';
  rating: number;
}

const Rating: FunctionComponent<HolidayCardProps> = ({ type, rating }: HolidayCardProps) => {
  const validateRating = (rating: number) => {
    if (rating < 0) {
      return 0;
    }

    if (rating > 5) {
      return 5;
    }

    return rating || 5;
  };

  const validatedRating = validateRating(rating);

  const StarsRating = () => {
    return (
      <div className='rating'>
        {Array(validatedRating)
          .fill(null)
          .map((item, index) => {
            return <img className={'star'} key={index} src={star} alt='Star image' />;
          })}
      </div>
    );
  };

  const CirclesRating = () => {
    return (
      <div className={'rating'}>
        {Array(5)
          .fill(null)
          .map((item, index) => {
            const isRating = index < validatedRating;
            if (Number(validatedRating) - index === 0.5)
              return (
                <div className='rating-empty-circle' key={index}>
                  <div className='rating-fill-circle-half' />
                </div>
              );
            if (Number(validatedRating) - index > 0)
              return (
                <div className='rating-empty-circle' key={index}>
                  <div className='rating-fill-circle' />
                </div>
              );
            if (Number(validatedRating) - index <= 0)
              return <div className='rating-empty-circle' key={index} />;
          })}
      </div>
    );
  };

  return <>{type === 'circle' ? <CirclesRating /> : <StarsRating />}</>;
};

export default Rating;
