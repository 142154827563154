import { FC, useState } from 'react';
import { IBasket } from './Interfaces';
import { ImageCarousel } from '../ImageCarousel';
import './Basket.scss';
import TripAdvisorLogo from '../assets/tripadvisor-logo.png';
import { Rating } from '../HolidayCard/Rating';
import moment from 'moment';
import { FlightDetails } from '../HolidaySummary/FlightDetails';
import { IProductAPI } from '../../pages/SelectPage/Interfaces';

const formatToDate = (inboundDate: string, outboundDate: string): string => {
  const format = 'ddd DD MMM yyyy';
  const inboundDateFormat = moment(inboundDate).format(format);
  const outboundDateFormat = moment(outboundDate).format(format);

  const duration = moment(inboundDate).diff(outboundDate, 'days');
  return `${outboundDateFormat} - ${inboundDateFormat} (${duration} night${
    duration > 1 ? 's' : ''
  })`;
};

const generateProductType = (product: IProductAPI): string => {
  let ptype = '';
  switch (product.product_type.id) {
    case 0:
      ptype = 'Hotels';
      break;
    case 1:
      ptype = 'Flights';
      break;
  }
  return ptype;
};

interface IPrice {
  price: number;
}

const RenderPrice: FC<IPrice> = ({ price }) => {
  return (
    <div className='basket-standout-price-container'>
      <span className='basket-standout-price-title'>Total price:</span>
      <span className='basket-standout-price'>£{price.toFixed(2)}</span>
    </div>
  );
};

export const Basket: FC<IBasket> = ({
  destination,
  rating,
  inbounds,
  outbounds,
  showMoreButton = false,
  basketData,
}) => {
  const lastOutBoundDate = outbounds[outbounds.length - 1]?.destination?.arrivedDate || false;
  const [showMore, setShowMore] = useState<boolean>(false);
  const showMenu = !showMoreButton ? true : false || (showMoreButton && showMore);

  return (
    <>
      <div className='basket-container'>
        <ImageCarousel images={destination.images || []} />
        <h4 className='basket-title'>{destination.title}</h4>
        <div className='basket-subtitle'>{destination.subtitle}</div>
        <div className='basket-container-rating'>
          <div>
            <Rating type='star' rating={rating.holidayPeople} />
          </div>
          <div>
            <img className='basket-rating-trip-logo' src={TripAdvisorLogo} height={15} width={20} />
            <Rating type='circle' rating={rating.tripAdvisor} />
            <div className='basket-container-rating-reviews'>{rating.amount} reviews</div>
          </div>
        </div>
        <div className='basket-container-date-formatted basket-container-generic-text'>
          {lastOutBoundDate && formatToDate(inbounds[0].destination.arrivedDate, lastOutBoundDate)}
        </div>
        <div className='basket-container-generic-text'>{destination.roomType}</div>
        <div className='basket-container-generic-text'>
          {destination.breakfast ? 'Bed & Breakfast' : 'Bed'}
        </div>
        <div className='basket-container-generic-text basket-container-generic-lastchild'>
          Adults: {destination.adults || 0}, Children: {destination.children || 0}
        </div>
        {!showMenu && (
          <>
            <div className='basket-divider'></div>
            <div className='basket-show-more'>
              <span
                className='basket-show-more-btn'
                onClick={() => {
                  setShowMore(true);
                }}
              >
                Show more
              </span>
            </div>
          </>
        )}
        {showMenu && (
          <>
            {outbounds.length > 0 && <div className='basket-divider'></div>}
            <div className='basket-inbound-flights'>
              {outbounds.map((outbound, index) => {
                return (
                  <FlightDetails
                    key={`outbound${index}`}
                    state={{
                      date: outbound.destination.arrivedDate,
                      flightType: 'outbound',
                      airlineLogo: outbound.destination.flight.company || '',
                      airlineCode: outbound.destination.flight.flightNumber || '',
                      departureTime: outbound.destination.travelToDate,
                      departurePlace: outbound.destination.travelFrom,
                      arrivalPlace: outbound.destination.travelTo,
                      arrivalTime: outbound.destination.arrivedDate,
                      isViewAlternatives: false,
                      duration: 0,
                    }}
                  />
                );
              })}
            </div>
            {inbounds.length > 0 && <div className='basket-divider'></div>}
            <div className='basket-inbound-flights'>
              {inbounds.map((inbound, index) => {
                return (
                  <FlightDetails
                    key={`inbound${index}`}
                    state={{
                      date: inbound.destination.arrivedDate,
                      flightType: 'inbound',
                      airlineLogo: inbound.destination.flight.company || '',
                      airlineCode: inbound.destination.flight.flightNumber || '',
                      departureTime: inbound.destination.travelToDate,
                      departurePlace: inbound.destination.travelFrom,
                      arrivalPlace: inbound.destination.travelTo,
                      arrivalTime: inbound.destination.arrivedDate,
                      isViewAlternatives: false,
                      duration: 0,
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
        {basketData?.basket?.result?.products &&
          basketData?.basket?.result?.products.length > 0 && (
            <>
              {basketData?.basket?.result?.products.map((product, index) => {
                return (
                  <div key={`${index}productbreakdown`}>
                    <div className='basket-standout-price-container'>
                      <span className='basket-standout-price-subtitle'>
                        {generateProductType(product) !== ''
                          ? generateProductType(product)
                          : product.product_type.name}
                      </span>
                    </div>
                    <div className='basket-standout-price-container'>
                      <span className='basket-standout-price-title basket-standout-price-title-large'>
                        Price per person:
                      </span>
                      <span className='basket-standout-price basket-standout-price-large'>
                        £{product.price_per_person.toFixed(2)}
                      </span>
                    </div>
                    <div className='basket-standout-price-container'>
                      <span className='basket-standout-price-title basket-standout-price-title'>
                        Total:
                      </span>
                      <span className='basket-standout-price basket-standout-price'>
                        £{product.price.toFixed(2)}
                      </span>
                    </div>
                    <div className='basket-divider' />
                  </div>
                );
              })}
            </>
          )}
        {basketData?.basket?.result?.price && (
          <RenderPrice price={basketData?.basket?.result?.price} />
        )}
      </div>
    </>
  );
};
