import { IBasket } from '../../components/Basket/Interfaces';
import image from '../../components/assets/image.png';

export const BasketDummy: IBasket = {
  destination: {
    title: 'GF Isabel',
    subtitle: 'Canaries, Tenerife, Los Cristianos',
    images: [
      { src: image, alt: '' },
      { src: image, alt: '' },
      { src: image, alt: '' },
    ],
    breakfast: true,
    roomType: 'Double',
    adults: 2,
    children: 0,
  },
  rating: {
    tripAdvisor: 4,
    holidayPeople: 5,
    amount: 2300,
  },
  people: {
    adults: 2,
    children: 0,
  },
  outbounds: [
    {
      destination: {
        travelFrom: 'London Gatwick',
        travelTo: 'Tenerife South',
        arrivedDate: new Date('2022-03-20 13:25').toISOString(),
        travelToDate: new Date('2022-03-20 10:25').toISOString(),
        flight: {
          flightNumber: 'FN3921044',
          company: 'Ryanair',
        },
      },
    },
  ],
  inbounds: [
    {
      destination: {
        travelTo: 'London Gatwick',
        travelFrom: 'Tenerife South',
        arrivedDate: new Date('2022-03-29 09:25').toISOString(),
        travelToDate: new Date('2022-03-29 06:25').toISOString(),
        flight: {
          flightNumber: 'BN921044',
          company: 'Ryanair',
        },
      },
    },
  ],
  extras: {},
};
