import { StepState } from './ProgressBar';

export const ProgressBarDefault: StepState[] = [
  {
    title: 'Flights & Room',
    isActive: false,
    isCompleted: false,
  },
  {
    title: 'Extras',
    isActive: false,
    isCompleted: false,
  },
  {
    title: 'Passenger Details',
    isActive: false,
    isCompleted: false,
  },
  {
    title: 'Payment',
    isActive: false,
    isCompleted: false,
  },
];
