import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './RootStore';

export interface IUserSession {
  sessionId?: string;
}

export class SessionStore {
  constructor(RootStoreRef: RootStore) {
    this.rootStore = RootStoreRef;

    makeObservable(this, {
      userSession: observable,
      setUserSession: action,
    });
  }

  rootStore: RootStore;
  userSession: IUserSession = {
    sessionId: undefined,
  };

  setUserSession = (sessionId: string) => {
    this.userSession.sessionId = sessionId;
  };
}
