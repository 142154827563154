export const BOARD_TYPES: Record<string, number> = {
  'Room Only': 0,
  'Self Catering': 1,
  'Bed & Breakfast': 2,
  'Half Board': 3,
  'Full Board': 4,
  'All Inclusive': 5,
};

export const FACILITIES_TYPES: Record<string, number> = {
  'Close to Beach': 0,
  'Couples': 1,
  'Family': 2,
  'Golfing': 3,
  'Kids club': 4,
  'Water park': 5,
  'Young & Lovely': 6,
  'Water Slides': 7,
  'Beach Front': 8,
};
