import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';

import { HomePage } from './pages/HomePage/HomePage';
import SearchResultsPage from './pages/SearchResultsPage/SearchResultsPage';
import HolidayPage from './pages/HolidayPage/HolidayPage';
import ExtrasPage from './pages/ExtrasPage/ExtrasPage';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import SelectPage from './pages/SelectPage/SelectPage';
import { Provider } from 'mobx-react';
import { RootStore } from './stores/RootStore';
import PassengerDetailsPage from './pages/PassengerDetailsPage/PassengerDetailsPage';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import BookingConfirmed from './pages/BookingConfirmed/BookingConfirmed';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: 'search',
    element: <SearchResultsPage />,
  },
  {
    path: 'holiday/:id',
    element: <HolidayPage />,
  },
  {
    path: 'extras',
    element: <ExtrasPage />,
  },
  {
    path: 'select',
    element: <SelectPage />,
  },
  {
    path: 'passengers',
    element: <PassengerDetailsPage />,
  },
  {
    path: 'payment',
    element: <PaymentPage />,
  },
  {
    path: 'booking-confirmed',
    element: <BookingConfirmed />,
  },
  {
    path: 'passengers',
    element: <PassengerDetailsPage />,
  },
]);
const rootStore = new RootStore();

root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Provider RootStore={rootStore}>
        <RouterProvider router={router} />
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
