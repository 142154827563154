import { Icons, MenuColor } from '../../components/Menu/Interfaces';

const holidays = [
  {
    category: 'Costa Blanca',
    title: [
      'Albir',
      'Alicante',
      'Altea',
      'Benidorm',
      'Calpe',
      'Denia',
      'Elche',
      'Javea',
      'Moraira',
      'Torrevieja',
      'Villajoyosa',
    ],
  },
  { category: 'Costa Brava', title: ['Blanes', 'Calella', 'Lloret De Mar'] },
  { category: 'Costa Del Sol', title: ['Location A', 'Location B', 'Location C'] },
  { category: 'Costa Dorada', title: ['Cambrils', 'Coma', 'La Pineda', 'Port Aventura', 'Salou'] },
];

const data = {
  items: [
    {
      icon: Icons.ACCOUNT_ORANGE,
      title: 'My Bookings',
      color: MenuColor.ORANGE,
    },
    {
      icon: Icons.HEART_UNFILLED_WHITE,
      title: 'Holiday Shortlist',
    },
    {
      title: 'Destinations',
      menuChildren: [
        { title: 'Spain', menuChildren: holidays },
        { title: 'Canaries', menuChildren: holidays },
        { title: 'Turkey', menuChildren: holidays },
        { title: 'Greece', menuChildren: holidays },
        { title: 'Scotland', menuChildren: holidays },
        { title: 'Brazil', menuChildren: holidays },
        { title: 'France', menuChildren: holidays },
        { title: 'Japan', menuChildren: holidays },
      ],
    },
    {
      title: 'Holiday Types',
      menuChildren: [
        { title: 'All inclusive' },
        { title: 'Package Holidays' },
        { title: 'Winter Holidays' },
        { title: 'Ski Holidays' },
        { title: 'City Breaks' },
        { title: 'Summer 2023' },
        { title: 'UK Breaks' },
        { title: 'Late Deals' },
      ],
    },
    {
      title: 'Holiday Extras',
      menuChildren: [
        {
          title: 'Holiday Money',
        },
        { title: 'Travel Money Card' },
        { title: 'Travel Insurance' },
      ],
    },
    { title: 'Cruise Holidays' },
    { title: 'Faraway Holidays' },
    {
      title: 'Careers',
      color: MenuColor.ORANGE,
      hideDivider: true,
    },
  ],
};

export { data as MenuData };
