import React, { FunctionComponent, useRef, useState } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './BoardType.scss';
import { useModal } from '../../hooks/useModal';
import { Modal } from '../Modal';
import { Option } from '../Autocomplete';
import { Button } from '../Button';
import down from '../assets/close-icon.svg';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import Board from '../Board/Board';
import { compact } from 'lodash';

interface BoardTypeProps {
  clickApplyHandler: (option: Option, name: string) => void;
  clickCancelHandler?: () => void;
}
const testOptions = [
  { value: 0, label: 'Room Only' },
  { value: 1, label: 'Self Catering' },
  { value: 2, label: 'Bed & Breakfast' },
  { value: 3, label: 'Half Board' },
  { value: 4, label: 'Full Board' },
  { value: 5, label: 'All Inclusive' },
];

const BoardTypeSelect: FunctionComponent<BoardTypeProps> = ({
  clickApplyHandler,
  clickCancelHandler,
}) => {
  const [isOpen, toggle] = useModal(false);
  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any>([]); // TODO Fix typing

  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const searchBarInputFieldRef = useRef<HTMLDivElement>(null);

  const handleApplyValues = (option: any) => {
    setSelectedValues([...option]);
    clickApplyHandler([...option] as any, 'boardType');
  };

  const icon = <img src={down} width='22' alt='arrow-down' />;

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Select Duration</h5>
    </div>
  );

  const modalFooter = (
    <div className='modal-mobile-footer'>
      <Button label='Cancel' isTransparent onClick={clickCancelHandler} />
      <Button
        label='Apply'
        primary
        onClick={() => () => {
          handleApplyValues(selectedValues);
        }}
      />
    </div>
  );

  const getSelectedValuesLabel = () => {
    const selectedValuesLength = selectedValues && selectedValues.length;

    if (!selectedValuesLength) {
      return 'Any';
    }

    if (selectedValuesLength === 1) {
      return `${selectedValues[0].label}`;
    }

    return `${selectedValues[0].label} and ${selectedValuesLength - 1} more options`;
  };

  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            setSelectExpanded(false);
          }}
          className='board-select-overlay'
        ></div>
      )}
      <div className='search-bar-input-field-autocomplete board-type-wrapper'>
        <SearchBarInputField
          title='Board Type'
          value={getSelectedValuesLabel()}
          onClick={() => setSelectExpanded(!selectExpanded)}
          icon={icon}
          ref={searchBarInputFieldRef}
        />
        {selectExpanded && (
          <div className='board-select-desktop-view'>
            <Board
              selectedValues={selectedValues}
              options={testOptions}
              onChange={(option) => handleApplyValues(option)}
            />
          </div>
        )}
      </div>
      <div className='board-select-mobile-view'>
        <Modal
          isOpen={selectExpanded}
          toggle={() => setSelectExpanded(false)}
          header={modalHeader}
          footer={modalFooter}
        >
          <Board
            selectedValues={selectedValues}
            options={testOptions}
            onChange={setSelectedValues}
          />
        </Modal>
      </div>
    </>
  );
};

export default BoardTypeSelect;
