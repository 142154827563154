import { BasketStore } from './BasketStore';
import { HolidayStore } from './HolidayStore';
import { SessionStore } from './SessionStore';

export class RootStore {
  constructor() {
    this.sessionStore = new SessionStore(this);
    this.basketStore = new BasketStore(this);
    this.holidayStore = new HolidayStore(this);
  }
  sessionStore: SessionStore;
  basketStore: BasketStore;
  holidayStore: HolidayStore;
}
