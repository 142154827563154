import { FC, useState, useEffect } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { ProgressBarDefault } from '../../components/ProgressBar/ProgressBarDefaults';
import { noop } from 'lodash';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { Basket } from '../../components/Basket/Basket';
import './PaymentPage.scss';
import { SelectPayment } from '../../components/SelectPayment/SelectPayment';
import { useNavigate } from 'react-router-dom';
import { usePaymentPayMonthly } from '../../hooks/useRequest';
import { CreditCard } from '../../components/CreditCard/CreditCard';
import { IPriceDate } from '../../components/SelectPayment/Interfaces';

interface IPaymentPage {
  onLogin?: () => void;
  openMenu?: () => void;
}

const PaymentPage: FC<IPaymentPage> = ({ onLogin = noop, openMenu = noop }) => {
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);
  const {
    RootStore: {
      sessionStore: { userSession },
      basketStore: { basket, setBookingId },
      holidayStore: { holiday },
    },
  } = useStores() as { RootStore: RootStore };

  const navigate = useNavigate();
  const [price, setPrice] = useState(basket.basketData?.basket.result.price);

  useEffect(() => {
    if (!userSession.sessionId) {
      navigate('/');
    }
  }, [userSession.sessionId]);

  const results = userSession.sessionId
    ? usePaymentPayMonthly(1, userSession?.sessionId, basket.basketData?.basket?.result?.price || 0)
    : null;

  ProgressBarDefault.forEach((progress) => {
    progress.isActive = false;
  });
  ProgressBarDefault[3].isActive = true;

  const combineBasket = { ...basket, ...(holiday || {}) };
  const instalments: IPriceDate[] = [];
  if (
    results?.data?.basket_payment_plan?.result &&
    results?.data?.basket_payment_plan?.result.plan.length > 0
  ) {
    results?.data?.basket_payment_plan?.result.plan.forEach((plan: any) => {
      instalments.push({ price: plan.amount, date: plan.date_due });
    });
  }
  return (
    <BaseLayout
      onLogin={onLogin}
      openMenu={openMenu}
      progressBar={ProgressBarDefault}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
    >
      <div className='payment-page-container'>
        <div className='payment-page-col payment-page-col-basket payment-page-col-basket-mobile'>
          {basket && <Basket {...combineBasket} showMoreButton={true} />}
        </div>
        <div className='payment-page-col'>
          <SelectPayment
            payment={{
              payInFull: true,
              instalments: results?.data?.basket_payment_plan?.result ? true : false,
              deposit: true,
            }}
            instalments={instalments}
            basket={basket}
            onPriceChange={(newPrice) => {
              setPrice(newPrice);
            }}
          />
          <CreditCard
            price={price}
            sessionId={userSession.sessionId || ''}
            onBooking={(val) => {
              setBookingId(val);
            }}
          />
        </div>
        <div className='payment-page-col payment-page-col-basket'>
          {basket && <Basket {...combineBasket} showMoreButton={false} />}
        </div>
      </div>
    </BaseLayout>
  );
};

export default observer(PaymentPage);
