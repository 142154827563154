import React, { FunctionComponent, useState, useCallback } from 'react'
import { noop } from 'lodash'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import CustomMarker from './CustomMarker'
import './MapModal.scss'

export type GoogleMapType = google.maps.Map
export interface Coordinates {
  lat: number
  lng: number
}

export interface Place {
  id: string
  title: string
  position: Coordinates
  price?: number;
}

interface MapProps {
  center?: Coordinates
  places?: Place[]
  onMarkerClick?: (place: Place) => void
  hotelName?: string
  hotelLocation?: string
  rating?: number
  tripadvisorRating?: number
  tripadvisorReviews?: string
  price?: number
  shouldShowInfoBox?: boolean
}

const containerStyle = {
  width: '100%',
  height: 'inherit',
}

const Map: FunctionComponent<MapProps> = ({
  places,
  center,
  onMarkerClick = noop,
  hotelName,
  hotelLocation,
  rating,
  tripadvisorRating,
  tripadvisorReviews,
  price,
  shouldShowInfoBox,
}: MapProps) => {
  const [map, setMap] = useState<GoogleMapType | undefined>()

  const onMarkerClickHandler = (place: Place) => {
    onMarkerClick(place)
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  })

  const onLoad = useCallback((map: GoogleMapType) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(undefined)
  }, [])

  const getMarkerStatus = () => (places?.length === 1)

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {places &&
        places.map((place, i) => (
          <CustomMarker
            shouldShowInfoBox={shouldShowInfoBox}
            map={map}
            isActive={getMarkerStatus()}
            key={place.title + i}
            place={place}
            hotelName={hotelName}
            hotelLocation={hotelLocation}
            rating={rating}
            tripadvisorRating={tripadvisorRating}
            tripadvisorReviews={tripadvisorReviews}
            price={price}
            onClick={(place) => onMarkerClickHandler(place)}
          />
        ))}
    </GoogleMap>
  ) : null
}

export default Map
