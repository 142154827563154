import { FC, useState } from 'react';
import './Accomodation.scss';
import { IAccomodation, IRooms } from './Interfaces';
import RoomIcon from '../assets/room.svg';
import { Button } from '../Button';
import { ICarPrice } from '../CarHire/Interfaces';

export const Accomodation: FC<IAccomodation> = ({ title, rooms, onClick, selected }) => {
  const [selectedAccomdation, setSelectedAccomdation] = useState<number>(selected || 0);

  const setSelected = (selected: number, selectedRoom?: IRooms) => {
    setSelectedAccomdation(selected);
    if (onClick && selectedRoom) onClick(selectedRoom);
  };

  const RenderPrice: FC<ICarPrice> = ({ price }) => {
    return (
      <span>
        {`+ £${price.toFixed(2)}`} <br />{' '}
        <span className='accomodation-container-per-person'>Per Person</span>
      </span>
    );
  };

  return (
    <div>
      {/* desktop */}
      <div className='accomodation-container'>
        <h4 className='accomodation-title'>
          <img className='accomodation-icon' src={RoomIcon} height='20' width='20' />
          <span className='accomodation-title-text'>{title}</span>
        </h4>
        {/* Desktop */}
        {rooms?.map((room) => {
          return (
            <div
              key={room.id + 'room'}
              className={`accomodation-container-child ${
                room.id === selectedAccomdation ? 'accomodation-container-child-active' : ''
              }`}
            >
              <div>
                <h4 className='accomodation-container-title'>{room.title}</h4>
                <span className='accomodation-container-room'>{room.type}</span>
              </div>
              <div className='accomodation-container-terms'>Cancellation terms</div>
              <div className='accomodation-container-price'>
                {room.price && <RenderPrice price={room.price} />}
              </div>
              <div>
                <Button
                  overrideClassNames={`accomodation-button ${
                    room.id === selectedAccomdation ? 'accomodation-button-active' : ''
                  }`}
                  isTransparent={true}
                  label={room.id === selectedAccomdation ? 'Selected' : 'Select'}
                  onClick={() => {
                    setSelected(room.id, room);
                  }}
                />
              </div>
            </div>
          );
        })}
        {/* Mobile */}
        {rooms?.map((room) => {
          return (
            <div
              key={room.id + 'room'}
              className={`accomodation-container-child-mobile ${
                room.id === selectedAccomdation ? 'accomodation-container-child-mobile-active' : ''
              }`}
            >
              <div>
                <h4 className='accomodation-container-title'>{room.title}</h4>
                <span className='accomodation-container-room'>{room.type}</span>
                <div className='accomodation-container-terms'>Cancellation terms</div>
              </div>
              <div>
                <div className='accomodation-container-price'>
                  {room.price && <RenderPrice price={room.price} />}
                </div>
                <Button
                  overrideClassNames={`accomodation-button ${
                    room.id === selectedAccomdation ? 'accomodation-button-active' : ''
                  }`}
                  isTransparent={true}
                  label={room.id === selectedAccomdation ? 'Selected' : 'Select'}
                  onClick={() => {
                    setSelected(room.id, room);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
