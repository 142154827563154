import React, { FunctionComponent } from 'react'
import './HolidayDetails.scss'
import { Rating } from '../HolidayCard/Rating'
import tripLogo from '../assets/tripadvisor_logo.svg'

interface HolidayDetailsTopProps {
  hotelName: string
  hotelLocation: string
  rating: number
  tripadvisorRating: number
  tripadvisorReviews: string
}

export const HolidayTopDetails: FunctionComponent<HolidayDetailsTopProps> = ({
                                                                               hotelName,
                                                                               hotelLocation,
                                                                               rating,
                                                                               tripadvisorRating,
                                                                               tripadvisorReviews,
                                                                             }: HolidayDetailsTopProps) => {
  return (
    <>
      <div className='holiday-details-component-wrapper--top-information'>
        <p className='title'>{hotelName}</p>
        <div className='subtitle'>{hotelLocation}</div>
        <div className='ratings-section'>
          <Rating type={'star'} rating={rating} />
          <div className={'trip-logo'}>
            <img className={'logo'} src={tripLogo} alt='Trip logo' />
            <Rating type={'circle'} rating={tripadvisorRating} />
          </div>
          <span>{tripadvisorReviews}</span>
        </div>
      </div>
    </>
  )
}
