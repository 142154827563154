import React, { FunctionComponent, useState, useEffect } from 'react';
import { useGetAddress } from '../../hooks/useRequest';
import { noop } from 'lodash';
import Select, { StylesConfig } from 'react-select';
import PassengerInformation from './PassengerInformation';
import { Option } from '../Autocomplete';
import { Button } from '../Button';
import person from '../assets/person.svg';
import attention from '../assets/attention.svg';
import './LeadPassengerInformation.scss';

interface LeadPassengerInformationProps {
  onChange: (selectedValues: any, type: string) => void;
  isFormValid?: boolean;
}

export interface Address {
  addressline1: string;
  addressline2: string;
  addressline3: string;
  addressline4: string;
  postcode: string;
  posttown: string;
}

const LeadPassengerInformation: FunctionComponent<LeadPassengerInformationProps> = ({
  onChange,
  isFormValid,
}) => {
  const [selectedValues, setSelectedValues] = useState<any>();
  const [checked, setChecked] = useState<boolean>(false);
  const [postcode, setPostcode] = useState<string>('');
  const [postcode2, setPostcode2] = useState<string>(' ');
  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [address3, setAddress3] = useState<string>('');
  const [address4, setAddress4] = useState<string>('');
  const [postTown, setPostTown] = useState<string>('');

  const customStyles: StylesConfig = {
    container: (base) => ({
      ...base,
      margin: '8px 0 20px 0',
    }),
    control: (base, state) => ({
      ...base,
      borderColor: '#99b4ca',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#01447B',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      marginTop: 0,
      marginBottom: 0,
      borderColor: '#99b4ca',
    }),
    menu: (base) => ({
      ...base,
      borderColor: '#99b4ca',
      backgroundColor: 'white',
      position: 'absolute',
    }),
  };

  useEffect(() => {
    onChange(selectedValues, 'leadPassenger');
  }, [selectedValues]);

  const handleChange = (option: Option | string, type: string) => {
    setSelectedValues({
      ...selectedValues,
      [type]: typeof option === 'string' ? option : option.value,
    });
  };

  const onAddressSelect = (option: any, type: string) => {
    handleChange(option, 'address');
    setAddress1(option.value.addressline1);
    setAddress2(option.value.addressline2);
    setAddress3(option.value.addressline3);
    setAddress4(option.value.addressline4);
    setPostcode(option.value.postcode)
    setPostTown(option.value.posttown)
  };

  const addresses = useGetAddress(JSON.stringify(postcode2));

  const getLabel = (address: Address) => {
    return (
      <div>
        <div>{address.addressline1}</div>
        <div>{address.addressline2}</div>
        <div>{address.addressline3}</div>
        <div>{address.addressline4}</div>
        <div>{address.postcode}</div>
        <div>{address.posttown}</div>
      </div>
    );
  };

  const getOptionsFromAddresses = (addresses: any) => {
    return addresses.map((address: any) => {
      return { value: address, label: getLabel(address) };
    });
  };

  return (
    <div className='lead-passenger-information'>
      <div className='lead-passenger-title'>
        <img src={person} width='22' alt='person' />
        <span className='lead-passenger-title-text'>Lead Passenger Details</span>
      </div>
      <div className='passenger-attention'>
        <span className='passenger-attention-text'>
          Please ensure that all details match those on your passport
        </span>
        <img src={attention} width='22' alt='attention' />
      </div>
      <PassengerInformation onChange={onChange} isFormValid={isFormValid} />
      <div className='passenger-main-info-desktop'>
        <label className='select-label passenger-email' aria-required>
          Email address*
          <div className='passenger-input-wrapper'>
            <input
              className='passenger-info-input'
              type='email'
              name='email'
              onChange={(e) => handleChange(e.target.value, 'email')}
              required
            />
            <span className='error-message'>Please enter your email address</span>
          </div>
        </label>
        <label className='select-label passenger-confirm-email' aria-required>
          Confirm email address*
          <div className='passenger-input-wrapper'>
            <input
              className='passenger-info-input'
              type='email'
              name='confirmEmail'
              onChange={(e) => handleChange(e.target.value, 'confirmEmail')}
              required
            />
            <span className='error-message'>Please confirm your email address</span>
          </div>
        </label>
      </div>
      <div className='checkbox-wrapper'>
        <div
          className='autocomplete-custom-checkbox-container'
          onClick={() => setChecked(!checked)}
        >
          <input
            className='autocomplete-custom-checkbox'
            type='checkbox'
            checked={checked}
            name='newsletter'
            onChange={noop}
          />
          <span className='autocomplete-custom-checkbox-checkmark'></span>
        </div>

        <span className='text-above-checkbox'>
          Please tick, if you`d like to opt in to receive exclusive deals, offers and money off
          vouchers via our newsletter.
        </span>
      </div>
      <div className='postcode-wrapper'>
        <label className='select-label' aria-required>
          Postcode*
          <div className='passenger-input-wrapper passenger-postcode'>
            <input
              className='passenger-info-input'
              type='text'
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              name='postcode'
              required
            />
            <span className='error-message'>Please enter your postcode</span>
            <Button primary label='Find address' onClick={() => setPostcode2(postcode)} />
          </div>
        </label>
      </div>
      {addresses.data?.address_lookup.result.length ? (
        <div className='addresses-select'>
          <Select
            options={
              addresses.isSuccess
                ? getOptionsFromAddresses(addresses.data.address_lookup.result)
                : []
            }
            onChange={(option) => onAddressSelect(option as Option, 'postcode2')}
            name='postcode2'
          />
        </div>
      ) : null}
      <div className='passenger-main-info-desktop'>
        <div className='address-fields-wrapper'>
          <label className='select-label passenger-email' aria-required>
            Address line 1*
            <div className='passenger-input-wrapper'>
              <input
                className='passenger-info-input'
                name='address1'
                onChange={(e) => setAddress1(e.target.value)}
                value={address1}
                required
              />
              <span className='error-message'>Please enter your address1</span>
            </div>
          </label>
          <label className='select-label passenger-email' aria-required>
            Town / City*
            <div className='passenger-input-wrapper'>
              <input
                className='passenger-info-input'
                name='address3'
                onChange={(e) => setPostTown(e.target.value)}
                value={postTown}
                required
              />
              <span className='error-message'>Please enter your town / city</span>
            </div>
          </label>
        </div>
        <div className='address-fields-wrapper'>
          <label className='select-label passenger-email'>
            Address line 2 (optional)
            <div className='passenger-input-wrapper'>
              <input
                className='passenger-info-input'
                name='address2'
                onChange={(e) => setAddress2(e.target.value)}
                value={address2}
              />
            </div>
          </label>
          <label className='select-label passenger-email' aria-required>
            County*
            <div className='passenger-input-wrapper'>
              <input
                className='passenger-info-input'
                name='address4'
                onChange={(e) => setAddress3(e.target.value)}
                value={address3}
                required
              />
              <span className='error-message'>Please enter your county</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default LeadPassengerInformation;
