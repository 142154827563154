import React, { FunctionComponent, useEffect, useState } from 'react';
import './ImageCarousel.scss';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import image from '../../components/assets/image.png';

export interface Image {
  url?: string;
  src?: string;
  alt?: string;
}

interface ImageCarouselProps {
  images: Image[];
}

const ImageCarousel: FunctionComponent<ImageCarouselProps> = ({ images }: ImageCarouselProps) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [activeImages, setActiveImages] = useState<any>([]);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    renderMode: 'performance',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    const maxIndex = currentSlide + 5;
    const imgs: any = [];
    images?.forEach((img, index) => {
      if (index <= maxIndex) {
        imgs.push(img);
      }
    });
    setActiveImages([...imgs]);
  }, [images, currentSlide]);

  if (!images) return null;
  if (activeImages.length === 0) return null;

  const returnImage = (image: Image, i: number) => {
    return (
      <img
        className={'keen-slider__slide number-slide' + (i + 1)}
        key={i}
        src={activeImages[i]?.url ? `http://holidaypeople.co/${activeImages[i].url}` : image.src}
        alt={image.alt || `image_${i}`}
      />
    );
  };

  return (
    <div className='image-carousel'>
      <div className='navigation-wrapper'>
        <div ref={sliderRef} className='keen-slider'>
          {images && images.map((image: Image, i: number) => returnImage(image, i))}
        </div>
      </div>
      {loaded && instanceRef.current && (
        <div className='dots'>
          {[
            ...Array(
              images.length > 4 ? 4 : instanceRef?.current?.track?.details?.slides?.length,
            ).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={'dot' + (currentSlide === idx ? ' active' : '')}
              ></button>
            );
          })}
          {images.length > 4 ? (
            <button
              onClick={() => {
                instanceRef.current?.moveToIdx(4);
              }}
              className={'dot' + (currentSlide >= 4 ? ' active' : '')}
            ></button>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;
