import { FunctionComponent } from 'react';
import SearchButton from '../SearchBar/SearchButton';
import cn from 'classnames';

import './Header.css';
import { Logo } from '../Logo/Logo';
import { Heart } from '../HeartIcon/Heart';
import { HeaderIcon } from '../HeaderIcon/HeaderIcon';

import MenuIcon from '../assets/burger.svg';
import AccountIcon from '../assets/account.svg';
import { ProgressBar, StepState } from '../ProgressBar/ProgressBar';
import { SuccessIcon } from './SuccessIcon/SuccessIcon';

import atolIcon from '../assets/atol.svg';
import atbaIcon from '../assets/atba.svg';

interface HeaderProps {
  searchParams: any; // TODO Fix typing
  progressBar?: StepState[];
  isOverlayOpen?: boolean;
  onLogin: () => void;
  openMenu: () => void;
  onSearchButtonClick: () => void;
  onSearchItemClick: (type: string) => void;
  isBookingConfirmation?: boolean;
}

export const Header: FunctionComponent<HeaderProps> = ({
  onLogin,
  openMenu,
  onSearchButtonClick,
  onSearchItemClick,
  isOverlayOpen,
  progressBar,
  searchParams,
  isBookingConfirmation = false,
}: HeaderProps) => {
  return (
    <header className={cn({ noneBorder: isBookingConfirmation })}>
      <div className='wrapper'>
        <div className={'header-logo'}>
          <Logo width={163} height={53} route={'/'} />
        </div>
        <div className='main-search'>
          {isBookingConfirmation ? (
            <SuccessIcon />
          ) : (
            <>
              {(!progressBar || searchParams) && (
                <SearchButton
                isOverlayOpen={isOverlayOpen}
                  onClick={onSearchButtonClick}
                  searchParams={searchParams}
                  onSearchItemClick={onSearchItemClick}
                />
              )}
              {progressBar && <ProgressBar state={progressBar} />}
            </>
          )}
        </div>
        <div className={'header-icons-section'}>
          {isBookingConfirmation ? (
            <>
              <div className='booking-confirmed-icons'>
                <div className='img-wrapper'>
                  <img src={atbaIcon} alt='Atba logo' />
                </div>
                <div className='img-wrapper'>
                  <img src={atolIcon} alt='Atol logo' />
                  <span>ATOL Protected</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <Heart width={34} height={30} numberFavourites={2} />
              <div className='menu-wrapper'>
                <HeaderIcon width={24} height={30} img={MenuIcon} onClick={openMenu} />
                <HeaderIcon width={37} height={36} img={AccountIcon} onClick={onLogin} />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={'mobile-wrapper'}>
        <div className={'header-icons-section'}>
          {!isBookingConfirmation && <Heart width={34} height={30} numberFavourites={0} />}
        </div>
        <div className={'header-logo'}>
          <Logo width={129} height={40} route={'/'} />
        </div>
        <div className={'header-icons-section'}>
          {!isBookingConfirmation && (
            <HeaderIcon width={24} height={30} img={MenuIcon} onClick={openMenu} />
          )}
        </div>
      </div>
    </header>
  );
};
