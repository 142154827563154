import React, { FunctionComponent, useState } from 'react';
import './Toggle.scss';
import cn from 'classnames';

export interface ToggleProps {
  firstLabel: string;
  secondLabel: string;
  value?: boolean
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large';
  onToggleChange: (s: boolean) => void;
}

export const Toggle: FunctionComponent<ToggleProps> = ({
                                                         firstLabel,
                                                         secondLabel,
                                                         backgroundColor,
                                                         size = 'large',
                                                         value = false,
                                                         onToggleChange,
                                                       }: ToggleProps) => {
  const [toggleState, setToggleState] = useState(value);

  const toggle = () => {
    setToggleState(!toggleState);
    onToggleChange(!toggleState);
  }

  return (
    <div className={`toggle toggle--${size}`} style={{ backgroundColor }} onClick={() => toggle()}>
      <span className={cn({ 'selected': !toggleState })}>{firstLabel}</span>
      <span className={cn({ 'selected': toggleState })}>{secondLabel}</span>
    </div>
  )
}
