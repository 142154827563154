import React, { FunctionComponent, ReactNode } from 'react';
import { noop } from 'lodash';
import cn from 'classnames';
import './Modal.scss';

interface ModalProps {
  children?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  additionalHeaderClassName?: string;
  additionalBodyClassName?: string;
  mobileHeading?: string | ReactNode;
  shouldShowCloseButton?: boolean;
  isOpen: boolean;
  shouldCloseOnOverlayClick?: boolean;
  toggle?: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({
  children,
  footer,
  header,
  additionalHeaderClassName,
  additionalBodyClassName,
  shouldShowCloseButton = true,
  shouldCloseOnOverlayClick = true,
  isOpen = false,
  toggle,
}: ModalProps) => {
  return (
    <>
      {isOpen && (
        <div className='modal-overlay' onClick={shouldCloseOnOverlayClick ? toggle : noop}>
          <div onClick={(e) => e.stopPropagation()} className='modal-window'>
            <div className={cn('modal-header', additionalHeaderClassName)}>{header}</div>
            {shouldShowCloseButton && (
              <a className='modal-close' href={void 0} onClick={toggle}></a>
            )}
            <div className={cn('modal-content', additionalBodyClassName)}>{children}</div>

            {footer && <div className='modal-footer'>{footer}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
