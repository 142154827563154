import React, { FunctionComponent, useEffect, useState } from 'react';
import './Datepicker.scss';
import { SearchBarInputField } from '../SearchBarInputField';
import { Toggle } from '../Toggle/Toggle';
import { MonthPicker } from '../MonthPicker/MonthPicker';
import { Modal } from '../Modal';
import moment from 'moment';
import calendar from '../assets/calendar.svg';
import { PriceDatepicker } from '../PriceDatepicker';
import { Button } from '../Button';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { generateRoomString, useAvailableDays, useAvailableMonths } from '../../hooks/useRequest';
import { Option } from '../Autocomplete';
import {
  createRoomsFromParams,
  getAirportsFromParams,
  getDestinationsFromParams,
  getDurationFromParams,
} from '../../pages/SearchResultsPage/SearchResultsPage';

type Prices = {
  [date: string]: number;
};

interface DatepickerProps {
  datePrices?: Prices;
  onChange?: () => void;
  availableDates?: string[];
  selectedSearchParams: any;
  clickApplyHandler: (option: Option, name: string) => void;
}

const Datepicker: FunctionComponent<DatepickerProps> = ({
  datePrices,
  selectedSearchParams,
  clickApplyHandler,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | Date | null>('Any date');
  const [date, setDate] = useState<Date | null>();
  const [isCalendar, setIsCalendar] = useState(true);

  const [activeDate, setActiveDate] = useState<Date | undefined>();

  const rooms = selectedSearchParams.rooms
    ? JSON.stringify(generateRoomString(selectedSearchParams.rooms))
    : JSON.stringify(['2']);
  const airports = getAirportsFromParams(selectedSearchParams.airports);
  const duration = getDurationFromParams(selectedSearchParams);
  const destinations = getDestinationsFromParams(selectedSearchParams, 'destination');

  const { data: availableDays, isLoading: isDaysLoading } = useAvailableDays(
    rooms,
    moment(activeDate).get('month') + 1,
    moment(activeDate).get('year'),
    JSON.stringify(airports),
    duration,
    JSON.stringify(destinations),
  );
  const { data: availableMonths } = useAvailableMonths(
    rooms,
    JSON.stringify(airports),
    duration,
    JSON.stringify(destinations),
  );

  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);

  const generateSelectedDate = (date: Date | null) => {
    const sDate = moment(date).format('Do MMM YYYY');
    return `${sDate}`;
  };

  const onDatepickerChange = (date: Date | null) => {
    setDate(date);
    setSelectedDate(generateSelectedDate(date));
    const departureDateOption = {
      value: moment(date).format('YYYY-MM-DD 00:00'),
      label: 0,
    };
    clickApplyHandler(departureDateOption, 'departure_date');
    setIsCalendarOpen(false);
    setIsCalendar(true);
  };

  const getToggleValue = (val: boolean) => {
    setIsCalendar(!val);
  };

  const onMonthSelected = (date: string) => {
    setSelectedDate(null);
    const departureDateOption = {
      value: moment(date).format('YYYY-MM-DD 00:00'),
      label: 2,
    };
    clickApplyHandler(departureDateOption, 'departure_date');
    setSelectedDate(date);
    setIsCalendarOpen(false);
  };

  const icon = <img src={calendar} width='22' alt='calendar' />;

  useEffect(() => {
    const dropdownMonth = document.getElementsByClassName(
      'react-datepicker__month-read-view--selected-month',
    );

    if (dropdownMonth && dropdownMonth[0]) {
      (dropdownMonth[0] as HTMLElement).style.visibility = 'hidden';
      (dropdownMonth[1] as HTMLElement).style.visibility = 'hidden';
    }

    const dropdownArrow = document.getElementsByClassName(
      'react-datepicker__month-read-view--down-arrow',
    );

    if (dropdownArrow && dropdownArrow[0]) {
      (dropdownArrow[0] as HTMLElement).style.visibility = 'visible !important';
      (dropdownArrow[1] as HTMLElement).style.visibility = 'visible !important';
    }
  });

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Select Departure date</h5>
    </div>
  );

  const modalFooter = (
    <div className='modal-mobile-footer'>
      <Button label='Cancel' isTransparent onClick={() => setIsCalendarOpen(false)} />
      <Button label='Apply' primary onClick={() => setIsCalendarOpen(false)} />
    </div>
  );

  return (
    <>
      {isCalendarOpen && (
        <div
          onClick={() => {
            setIsCalendarOpen(false);
          }}
          className='datepicker-overlay'
        ></div>
      )}
      <div className='datepicker-wrapper'>
        <SearchBarInputField icon={icon} title={'Date'} onClick={() => setIsCalendarOpen(true)}>
          {selectedDate ? (
            <span className={'selected-date'}>{selectedDate.toString()}</span>
          ) : (
            <span>Any date</span>
          )}
        </SearchBarInputField>

        {isCalendarOpen && (
          <div className='datepicker-wrapper-inputs datepicker-large-view'>
            <Toggle
              value={!isCalendar}
              firstLabel={'Specific date'}
              secondLabel={'Whole month'}
              onToggleChange={(toggleValue) => getToggleValue(toggleValue)}
            />

            {isCalendar ? (
              <div className={'datepicker-component-wrapper'}>
                <PriceDatepicker
                  selectedDate={date ? date : undefined}
                  availableDates={availableDays?.available_days?.result || []}
                  datePrices={datePrices}
                  onChange={(value: any) => onDatepickerChange(value)}
                  onMonthChange={(v: any) => setActiveDate(v)}
                />
              </div>
            ) : (
              <MonthPicker
                availableMonths={availableMonths?.available_months?.result || []}
                numberYears={3}
                onMonthClick={(month) => onMonthSelected(month)}
              />
            )}
          </div>
        )}
      </div>
      <div className='datepicker-mobile-view'>
        <Modal
          isOpen={isCalendarOpen}
          toggle={() => setIsCalendarOpen(!isCalendarOpen)}
          header={modalHeader}
          footer={modalFooter}
        >
          <Toggle
            size={'medium'}
            value={!isCalendar}
            firstLabel={'Specific date'}
            secondLabel={'Whole month'}
            onToggleChange={(v) => getToggleValue(v)}
          />
          {isCalendar ? (
            <div className={'datepicker-component-wrapper'}>
              <PriceDatepicker
                selectedDate={date ? date : undefined}
                availableDates={availableDays?.available_days?.result || []}
                datePrices={datePrices}
                onChange={(value: any) => onDatepickerChange(value)}
                onMonthChange={(v: any) => setActiveDate(v)}
              />
            </div>
          ) : (
            <div className={'datepicker-component-wrapper'}>
              <MonthPicker
                availableMonths={availableMonths?.available_months?.result || []}
                numberYears={3}
                onMonthClick={(month) => onMonthSelected(month)}
              />
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default Datepicker;
