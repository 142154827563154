import React, { FunctionComponent } from 'react';
import { compact, difference, noop } from 'lodash';
import { AutocompleteProps, Option } from '../Autocomplete';
import Select, { StylesConfig } from 'react-select';
import '../Autocomplete/Autocomplete.scss';
import { CustomBoardOption } from './CustomBoardOption';

const Board: FunctionComponent<AutocompleteProps> = ({
  selectedValues,
  options,
  onChange = noop,
}: AutocompleteProps) => {
  const customStyles: StylesConfig = {
    control: () => ({
      display: 'none',
    }),
    menu: () => ({
      height: 'auto',
      width: '100%',
      zIndex: 1,
      boxSizing: 'border-box',
      border: 'solid 1px #99b4ca',
      borderRadius: '4px',
      marginTop: '8px',
      color: '#01447B',
      backgroundColor: 'white',
      position: 'absolute',
    }),
  };

  const handleSelectChange = (option: any) => {
    // TODO Fix typing

    const isExists = selectedValues
      ? selectedValues?.find((item) => item.label === option.label)
      : [];

    if (isExists) {
      onChange(selectedValues?.filter((item) => item.label !== option.label) as any);
      return;
    }

    onChange(selectedValues?.concat(option) as any);
  };

  return (
    <>
      <Select
        className='board-select-mobile'
        closeMenuOnSelect={true}
        menuIsOpen={true}
        styles={customStyles}
        options={options}
        components={{
          Option: (props: any) => (
            <CustomBoardOption
              {...props}
              isMobile={true}
              isSelected={
                selectedValues &&
                selectedValues.length &&
                selectedValues.some((selectedOption) => selectedOption.label === props.data.label)
              }
            ></CustomBoardOption>
          ), // TODO Fix typing
        }}
        onChange={handleSelectChange}
        value={selectedValues}
      />
      <Select
        className='board-select-desktop'
        closeMenuOnSelect={true}
        menuIsOpen={true}
        styles={customStyles}
        options={options}
        components={{
          Option: (props: any) => (
            <CustomBoardOption
              {...props}
              isSelected={
                selectedValues &&
                selectedValues.length &&
                selectedValues.some((selectedOption) => selectedOption.label === props.data.label)
              }
            ></CustomBoardOption>
          ), // TODO Fix typing
        }}
        onChange={handleSelectChange}
        value={selectedValues}
      />
    </>
  );
};

export default Board;
