import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import './PriceDatepicker.scss';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import MonthSelect from './MonthSelect/MonthSelect';
import cn from 'classnames';
import moment from 'moment';

type Prices = {
  [date: string]: number;
};

interface PriceDatepickerProps {
  datePrices?: Prices;
  value?: Date | null;
  availableDates?: string[] | null;
  onMonthChange?: (date: Date) => void;
  onChange: (s: Date | null | undefined | [Date | null, Date | null]) => void;
  selectedDate?: Date;
}

const PriceDatepicker: FunctionComponent<PriceDatepickerProps> = ({
  datePrices,
  value,
  onChange,
  onMonthChange,
  selectedDate,
  availableDates = null,
}: PriceDatepickerProps) => {
  const [date, setDate] = useState<Date | null | undefined | [Date | null, Date | null]>(
    value ? value : null,
  );
  const [activeDate, setActiveDate] = useState<Date | undefined>(new Date());
  const minPrice = Math.min(...Object.values(datePrices || []));

  useEffect(() => {
    if (value) {
      setDate(value);
      setActiveDate(value);
    }
  }, [value]);

  const changeActualMonth = (month: string, year: string) => {
    const newDate = new Date(`${moment().month(month).year(+year)}`);
    setActiveDate(newDate);
    if (onMonthChange) onMonthChange(newDate);
  };

  const handleDateChange = (
    value: Date | [Date] | [Date, Date],
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();
    if (Array.isArray(value)) {
      if (
        availableDates?.length &&
        !availableDates.some((availableDate) => moment(availableDate).isSame(value[0], 'day'))
      ) {
        return;
      }

      if (
        datePrices &&
        Object.keys(datePrices) &&
        !Object.keys(datePrices).some((availableDate) =>
          moment(availableDate).isSame(value[0], 'day'),
        )
      ) {
        return;
      }

      setDate(value[0]);
      onChange(value[0]);
    } else {
      if (
        availableDates?.length &&
        !availableDates.some((availableDate) => moment(availableDate).isSame(value, 'day'))
      ) {
        return;
      }

      if (
        datePrices &&
        Object.keys(datePrices) &&
        !Object.keys(datePrices).some((availableDate) => moment(availableDate).isSame(value, 'day'))
      ) {
        return;
      }

      setDate(value);
      onChange(value);
    }
  };

  return (
    <div className={'calendar-wrapper'}>
      <Calendar
        activeStartDate={activeDate}
        selectRange={false}
        calendarType={'US'}
        onChange={handleDateChange}
        value={date}
        tileClassName={({ date, view }) => {
          let isSelected = false;
          let isDisabled = false;

          if (date && view === 'month') {
            if (Array.isArray(date) && date.length > 1 && date[0] && date[1]) {
              isSelected = date ? date >= date[0] && date <= date[1] : false;
            }

            if (
              availableDates &&
              availableDates?.length >= 0 &&
              !availableDates.some((availableDate) => moment(availableDate).isSame(date, 'day'))
            ) {
              isDisabled = true;
            }

            if (
              datePrices &&
              Object.keys(datePrices) &&
              !Object.keys(datePrices).some((availableDate) =>
                moment(availableDate).isSame(date, 'day'),
              )
            ) {
              isDisabled = true;
            }
          }

          if (selectedDate && moment(date).isSame(selectedDate, 'day')) {
            isSelected = true;
          }

          return cn({ 'selected-date': isSelected, 'disabled-date': isDisabled });
        }}
        tileContent={({ date }: CalendarTileProperties) => {
          const dateFormat = moment(date).format('YYYY-MM-DD');
          const price = (datePrices && datePrices[dateFormat]) || null;

          return price ? (
            <div className={cn({ 'low-price': price === minPrice })}>{`£${price}`}</div>
          ) : null;
        }}
      />
      <MonthSelect
        value={value && `${value}`}
        years={[String(moment().year()), String(moment().add(1, 'year').year())]}
        onChange={(date) => {
          changeActualMonth(date.month, date.year);
        }}
      />
    </div>
  );
};

export default PriceDatepicker;
