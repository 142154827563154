import React, { FunctionComponent } from 'react';
import './HeaderIcon.css'

interface HeartProps {
  width: number;
  height: number;
  img: string;
  onClick?: () => void;
}

export const HeaderIcon: FunctionComponent<HeartProps> = ({ width, height, img, onClick }: HeartProps) => {
  return (
    <>
      <img className={'header-icon'} width={width} height={height} src={img} onClick={onClick} alt="Header icon"/>
    </>
  )
}
