import React, { FunctionComponent } from 'react'
import { TripadvisorVotes } from './TripadvisorVotes'
import { Rating } from '../HolidayCard/Rating'

import './TripadvisorVotesList.scss'

interface Vote {
  type: string
  value: number
}

interface TripadvisorVotesListProps {
  votesData: Vote[]
}

export const TripadvisorVotesList: FunctionComponent<TripadvisorVotesListProps> = ({
  votesData,
}: TripadvisorVotesListProps) => {
  const totalVotes = votesData.reduce((accumulator, vote) => accumulator + vote.value, 0)
  const pixelsPerScore = 200 / totalVotes

  return (
    <div className='tripadvisor-votes-list-wrapper'>
      <div className='tripadvisor-votes-score-list left'>
        <div className='tripadvisor-votes-score-list-item'>
          <span className='item-name'>Location</span>
          <Rating type='circle' rating={5} />
        </div>
        <div className='tripadvisor-votes-score-list-item'>
          <span className='item-name'>Cleanliness</span>
          <Rating type='circle' rating={3} />
        </div>
        <div className='tripadvisor-votes-score-list-item'>
          <span className='item-name'>Service</span>
          <Rating type='circle' rating={4} />
        </div>
        <div className='tripadvisor-votes-score-list-item'>
          <span className='item-name'>Value</span>
          <Rating type='circle' rating={2} />
        </div>
      </div>
      <div className='tripadvisor-votes-score-list right'>
        {votesData.map((vote: Vote) => {
          return (
            <div key={vote.type} className='tripadvisor-votes-score-list-item'>
              <span className='item-name'>{vote.type}</span>
              <TripadvisorVotes votes={vote.value} width={pixelsPerScore * vote.value} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
