import React, { FunctionComponent } from 'react'
import './PageLoader.scss'

const PageLoader: FunctionComponent = () => {
  const FakeFilterToggle = () => (
    <div className='filter-section gradient-animation'>
      <div className='fake-toggle'></div>
    </div>
  )

  const FakeFilterItem = () => (
    <div className='filter-section gradient-animation'>
      <div className='fake-heading'></div>
      <div className='fake-paragraph fake-paragraph-s'></div>
      <div className='fake-paragraph fake-paragraph-m'></div>
      <div className='fake-paragraph fake-paragraph-l'></div>
      <div className='fake-paragraph fake-paragraph-xl'></div>
    </div>
  )

  const FakeHolidayCard = () => (
    <div className='holiday-card-wrapper gradient-animation'>
      <div className='fake-image'></div>
      <div className='holiday-card-wrapper--details'>
        <div className='name-section-wrapper'>
          <div className='fake-paragraph fake-paragraph-xl'></div>
          <div className='fake-paragraph fake-paragraph-m'></div>
          <div className='fake-paragraph fake-paragraph-l'></div>
        </div>
        <div className='holiday-details-wrapper'>
          <div className='fake-paragraph fake-paragraph-m'></div>
          <div className='fake-paragraph fake-paragraph-xl'></div>
          <div className='fake-paragraph fake-paragraph-l'></div>
        </div>
        <div className='price-section'>
          <div className='fake-heading'></div>
          <div className='fake-heading'></div>
          <div className='fake-heading'></div>
        </div>
      </div>
    </div>
  )

  return (
    <div className='search-bar-wrapper loader'>
      <div className='fake-main-heading-section'>
        <h1 className='page-loader-main-heading'>Finding your holiday...</h1>
        <div className='fake-toggle'></div>
      </div>

      <div className='search-results-container'>
        <div className='filters-wrapper-desktop'>
          <FakeFilterToggle />
          <FakeFilterItem />
          <FakeFilterItem />
          <FakeFilterItem />
          <FakeFilterItem />
          <FakeFilterItem />
          <FakeFilterItem />
        </div>

        <div className='holiday-card-container'>
          <FakeHolidayCard />
          <FakeHolidayCard />
          <FakeHolidayCard />
          <FakeHolidayCard />
          <FakeHolidayCard />
        </div>
      </div>
    </div>
  )
}

export default PageLoader
