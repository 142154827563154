import React, { FunctionComponent, useState, useEffect } from 'react';
import { noop } from 'lodash';
import Select, { StylesConfig } from 'react-select';
import { CustomDropdownIndicator } from './CustomDropdownIndicator';
import { Option } from '../Autocomplete';
import './Room.scss';
import { Button } from '../Button';

export interface Guests {
  children: number;
  adults: number;
  childAge: Record<number, string>;
}
interface RoomProps {
  adultsOptions: Option[];
  childrenOptions: Option[];
  roomNumber: number;
  onApply: (room: Guests) => void;
  removeRoom?: (num: number) => void;
  defaultValues: {
    adults: number;
    children: number;
    childAge: Record<number, string>;
  };
}

const ageRange: Option[] = [];

for (let i = 0; i < 13; i++) {
  ageRange.push({
    label: i,
    value: i,
  });
}

const Room: FunctionComponent<RoomProps> = ({
  adultsOptions,
  childrenOptions,
  onApply = noop,
  roomNumber,
  defaultValues,
  removeRoom,
}: RoomProps) => {
  const [remove, setRemove] = useState(false);
  const [room, setRoom] = useState<Guests>({
    adults: defaultValues.adults || 2,
    children: defaultValues.children || 0,
    childAge: defaultValues.childAge || {},
  });

  const handleChange = (option: Option, type: string) => {
    setRoom({
      ...room,
      [type]: option.value,
    });
  };

  const changeAge = (option: Option, index: number) => {
    const childAges = { ...room.childAge };
    childAges[index] = String(option.value);
    setRoom({
      ...room,
      childAge: childAges,
    });
  };

  useEffect(() => {
    if (!remove) onApply(room);
  }, [room, remove]);

  const customStyles: StylesConfig = {
    container: (base) => ({
      ...base,
      marginTop: '16px',
    }),
    control: (base) => ({
      ...base,
      borderColor: '#99b4ca',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#01447B',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      marginTop: 0,
      marginBottom: 0,
      borderColor: '#99b4ca',
    }),
    menu: (base) => ({
      ...base,
      borderColor: '#99b4ca',
      backgroundColor: 'white',
      position: 'absolute',
    }),
  };

  const childrenSelectorArray = [...Array(room.children).keys()];

  return (
    <div className='room-container'>
      {roomNumber !== 1 && (
        <Button
          label='Remove room'
          onClick={() => {
            setRemove(true);
            if (removeRoom) removeRoom(roomNumber);
          }}
        />
      )}
      <h4 className='room-number'>Room {roomNumber || 1}</h4>
      <div className='room-grid-2-2'>
        <label className='select-label'>
          Select Adults
          <Select
            options={adultsOptions}
            value={{ value: room.adults, label: room.adults }}
            onChange={(option) => handleChange(option as Option, 'adults')}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
          />
        </label>
        <label className='select-label'>
          Select Children
          <Select
            options={childrenOptions}
            value={{ value: room.children, label: room.children }}
            onChange={(option) => handleChange(option as Option, 'children')}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
          />
        </label>
      </div>
      {childrenSelectorArray.length > 0 && (
        <div className='room-child-age-title'>Enter age of child on return date</div>
      )}
      <div className='room-grid-2-2'>
        {childrenSelectorArray.map((val: any, index) => {
          return (
            <label key={`room${roomNumber}-${index}`} className='select-label'>
              Child {index + 1}
              <Select
                options={ageRange}
                value={{
                  value: room.childAge[index] || 0,
                  label: room.childAge[index] || 0,
                }}
                onChange={(option) => changeAge(option as Option, index)}
                styles={customStyles}
                components={{ DropdownIndicator: CustomDropdownIndicator as any }}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default Room;
