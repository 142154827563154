import React, { Fragment, FunctionComponent } from 'react';
import './FilterSection.scss';
import star from '../assets/star.svg';
import circle from '../assets/circle.svg';
import emptyCircle from '../assets/circle_empty.svg';

interface FilterItem {
  title: string;
  numberItems: string | number;
  isChecked: boolean;
}

interface FilterSectionProps {
  title: string;
  titleIcon?: string | null;
  type: 'string' | 'stars' | 'circle';
  items: Array<FilterItem> | null;
  onChange: (type: any, item: any) => void;
}

export const FilterSection: FunctionComponent<FilterSectionProps> = ({
  title,
  titleIcon,
  type,
  items,
  onChange,
}: FilterSectionProps) => {
  const isTypeStars = () => {
    return type === 'stars';
  };

  const isTypeCircle = () => {
    return type === 'circle';
  };

  const isTypeString = () => {
    return type === 'string';
  };

  return (
    <>
      <div className='filter-wrapper'>
        <div className='filter-wrapper--title'>
          {titleIcon && <img src={titleIcon} alt='' />}
          {title}
        </div>
        <div className='filter-wrapper--filters-wrapper'>
          {items &&
            isTypeString() &&
            items.map((item, key) => {
              return (
                <Fragment key={`${key}-string`}>
                  {item && item.numberItems > 0 && (
                    <div className={'filter-item'}>
                      <input
                        disabled={!item?.numberItems}
                        className={'filter-item--checkbox'}
                        type='checkbox'
                        onChange={() => onChange(title, item)}
                        value={item.isChecked as any}
                        checked={item.isChecked}
                      />
                      <span className={'filter-item--title'}>{item.title}</span>
                      <span className={'filter-item--numberItems'}>{item?.numberItems}</span>
                    </div>
                  )}
                </Fragment>
              );
            })}
          {items &&
            isTypeStars() &&
            items.map((item, key) => {
              return (
                <Fragment key={`${key}-stars`}>
                  {item && item.numberItems > 0 && (
                    <div className={'filter-item'}>
                      <input
                        disabled={!item?.numberItems}
                        className={'filter-item--checkbox'}
                        type='checkbox'
                        onChange={() => onChange('ratings', item)}
                        value={item.isChecked as any}
                        checked={item.isChecked}
                      />
                      <div className='stars'>
                        {Array(5 - key)
                          .fill(null)
                          .map((item, starsKey) => {
                            return <img className={'star'} key={starsKey} src={star} alt='' />;
                          })}
                      </div>
                      <span className={'filter-item--numberItems'}>{item?.numberItems}</span>
                    </div>
                  )}
                </Fragment>
              );
            })}
          {items &&
            isTypeCircle() &&
            items
              .sort((a, b) => {
                return Number(a.title) < Number(b.title) ? 1 : -1;
              })
              .map((item, key) => {
                return (
                  <Fragment key={`${key}-circle`}>
                    {item && item.numberItems > 0 && (
                      <div className={'filter-item'}>
                        <input
                          disabled={!item?.numberItems}
                          className={'filter-item--checkbox'}
                          type='checkbox'
                          onChange={() => onChange('tripRatings', item)}
                          value={item.isChecked as any}
                          checked={item.isChecked}
                        />
                        <div className='stars'>
                          {Array(5)
                            .fill(null)
                            .map((emptyCircleI, emptyCircleK) => {
                              if (Number(item.title) - emptyCircleK === 0.5)
                                return (
                                  <div className='filter-empty-circle' key={emptyCircleK}>
                                    <div className='filter-fill-circle-half' />
                                  </div>
                                );
                              if (Number(item.title) - emptyCircleK > 0)
                                return (
                                  <div className='filter-empty-circle' key={emptyCircleK}>
                                    <div className='filter-fill-circle' />
                                  </div>
                                );
                              if (Number(item.title) - emptyCircleK <= 0)
                                return <div className='filter-empty-circle' key={emptyCircleK} />;
                            })}
                        </div>
                        <span className={'filter-item--numberItems'}>{item?.numberItems}</span>
                      </div>
                    )}
                  </Fragment>
                );
              })}
        </div>
      </div>
    </>
  );
};
