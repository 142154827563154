import React, { FunctionComponent, useEffect } from 'react';
import cn from 'classnames';
import PassengerInformation from './PassengerInformation';
import LeadPassengerInformation from './LeadPassengerInformation';
import { useAddLeadPerson, useAddAdditionalPerson } from '../../hooks/useRequest';
import personIcon from '../assets/person.svg';
import attention from '../assets/attention.svg';
import './PassengerDetails.scss';
import { useNavigate } from 'react-router-dom';

export interface PassengerDetailsProps {
  passengersData: any;
  additionalPassengers: number;
  isFormValid: boolean;
  sessionId: string;
  setIsFormValid: (isValid: boolean) => void;
  onChange: (selectedValues: any, type: string) => void;
}

const PassengerDetails: FunctionComponent<PassengerDetailsProps> = ({
  additionalPassengers,
  isFormValid,
  sessionId,
  passengersData,
  onChange,
  setIsFormValid,
}) => {
  const addLeadPerson = useAddLeadPerson(sessionId, passengersData?.leadPassenger);
  const addAdditionalPerson = useAddAdditionalPerson(sessionId, passengersData?.additionalPassenger1);
  
  const navigate = useNavigate();

  useEffect(() => {
    addLeadPerson.isSuccess && addAdditionalPerson.isSuccess && navigate('/payment');
    addLeadPerson.isError || addAdditionalPerson.isError && console.log('Something went wrong'); // TODO error handling
  }, [addLeadPerson, addAdditionalPerson])

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    setIsFormValid(false);
    event.preventDefault();
   
    addLeadPerson.mutate();
    addAdditionalPerson.mutate();
  };

  return (
    <div className={cn('passengers-details-container', { invalid: !isFormValid })}>
      <form id='passengersForm' onSubmit={handleSubmit}>
        <LeadPassengerInformation onChange={onChange} isFormValid={isFormValid} />

        {Array.from(Array(additionalPassengers).keys()).map((person, i) => {
          return (
            <div key={person} className='additional-passenger-information'>
              <div className='additional-passenger-title'>
                <img src={personIcon} width='22' alt='person' />
                <span className='additional-passenger-title-text'>
                  {`Additional Passenger ${i + 1} Details`}
                </span>
              </div>
              <div className='passenger-attention'>
                <span className='passenger-attention-text'>
                  Please ensure that all details match those on your passport
                </span>
                <img src={attention} width='22' alt='attention' />
              </div>
              <PassengerInformation index={i + 1} onChange={onChange} isFormValid={isFormValid} />
            </div>
          );
        })}
      </form>
    </div>
  );
};

export default PassengerDetails;
