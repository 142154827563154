import React, { FunctionComponent, ReactElement } from 'react';
import './Button.css';
import cn from 'classnames';

export interface ButtonProps {
  primary?: boolean;
  isSearchIcon?: boolean;
  isPlusIcon?: boolean;
  isTransparent?: boolean;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large';
  label: string;
  borderRadius?: string;
  overrideClassNames?: string;
  disabled?: boolean;
  icon?: string;
  rightIcon?: string;
  onClick?: () => void;
}

export const Button: FunctionComponent<ButtonProps> = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  borderRadius,
  isSearchIcon,
  isPlusIcon,
  isTransparent,
  label,
  icon,
  rightIcon,
  overrideClassNames,
  ...props
}: ButtonProps) => {
  const mode = primary ? 'button--primary' : 'button--secondary';

  return (
    <button
      type='button'
      className={
        overrideClassNames
          ? overrideClassNames
          : cn(
              `button button--${size}`,
              mode,
              { 'button--search': isSearchIcon },
              { 'button--transparent': isTransparent },
              { 'button--plus': isPlusIcon },
            )
      }
      style={{
        backgroundColor,
        borderRadius,
      }}
      {...props}
    >
      {icon && (
        <span style={{ position: 'relative' }}>
          <img className='button--icon' src={icon} height='20' width='20' />
          <span className='button--label'>{label}</span>
        </span>
      )}
      {rightIcon && (
        <span style={{ position: 'relative' }}>
          <span className='button--label'>{label}</span>
          <img className='button--icon' src={rightIcon} height='20' width='20' />
        </span>
      )}
      {!icon && !rightIcon && label}
    </button>
  );
};

export default Button;
