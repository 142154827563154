import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './DestinationSelect.scss';

import { Modal } from '../Modal';
import { Autocomplete, Option } from '../Autocomplete';
import { Button } from '../Button';
import location from '../assets/location.svg';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import {
  useGetDefaultDestinatios,
  useGetAvailableDestinatios,
  useGetAvailableRegions,
  useGetAvailableResorts,
} from '../../hooks/useRequest';
import { compact, noop, uniqBy } from 'lodash';
import { getDepartureDateFromParams } from '../../pages/SearchResultsPage/SearchResultsPage';

interface DestinationSelectProps {
  clickApplyHandler: (option: Option, name: string) => void;
  clickCancelHandler?: () => void;
  resetItemType?: (type: string) => void;
  searchItemType?: string;
  date: string;
  duration: any;
  rooms: string;
  airports: any;
  selectedValue: any;
  selectedSearchParams: any;
}

const DestinationSelect: FunctionComponent<DestinationSelectProps> = ({
  clickApplyHandler,
  clickCancelHandler,
  resetItemType = noop,
  searchItemType,
  date,
  duration,
  rooms,
  airports,
  selectedValue,
  selectedSearchParams,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<any>(selectedValue); // TODO Fix typing
  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const searchBarInputFieldRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const departureDate = getDepartureDateFromParams(selectedSearchParams, 'value');
  const departureType = getDepartureDateFromParams(selectedSearchParams, 'label');

  const getAirportsFromParams = () => {
    const parsedAirports: string[] = [];

    if (!airports) return parsedAirports;

    airports.map((airport: Option, i: number) => {
      if (Array.isArray(airport.value)) {
        parsedAirports.push(...airport.value);
      } else {
        parsedAirports.push(airport.value as string);
      }
    });

    return [...new Set(parsedAirports)];
  };

  const requestObject = {
    departure: JSON.stringify(getAirportsFromParams()),
    date: '2023-12-05 00:00',
    duration: duration.value || 7,
    rooms: rooms || JSON.stringify(['2']),
  };

  const { data: dataDefault, isSuccess: isSuccessDefault } = useGetDefaultDestinatios(
    [airports, duration, rooms, departureDate, departureType],
    JSON.stringify(getAirportsFromParams()),
    departureDate,
    duration.value || 7,
    rooms || JSON.stringify(['2']),
    departureType,
  );
  const { data: dataRegions, isSuccess: isSuccessRegions } = useGetAvailableRegions(
    inputText,
    JSON.stringify(getAirportsFromParams()),
    departureDate,
    duration.value || 7,
    rooms || JSON.stringify(['2']),
    departureType,
  );
  const { data: dataDestinations, isSuccess: isSuccessDestinations } = useGetAvailableDestinatios(
    inputText,
    JSON.stringify(getAirportsFromParams()),
    departureDate,
    duration.value || 7,
    rooms || JSON.stringify(['2']),
    departureType,
  );
  const { data: dataResorts, isSuccess: isSuccessResorts } = useGetAvailableResorts(
    inputText,
    JSON.stringify(getAirportsFromParams()),
    departureDate,
    duration.value || 7,
    rooms || JSON.stringify(['2']),
    departureType,
  );
  const getParsedValues = (result: any, type: string) => {
    if (!result) return;

    return result.map((item: any) => {
      return { value: item.id, label: item.name, type: type };
    });
  };

  useEffect(() => {
    if (searchItemType === 'Destination' && !selectExpanded) {
      resetItemType('');
      setSelectExpanded(true);
    }
  }, [searchItemType]);

  useEffect(() => {
    if (isSuccessDefault && !inputText) {
      const options =
        dataDefault.available_default_destinations?.result?.destinations?.map(
          (destination: any) => {
            return { value: destination.id, label: destination.name, type: 'destination' };
          },
        ) || [];

      const mergedOptions = uniqBy([...options, ...(selectedValues || [])], 'value');

      setOptions(compact(mergedOptions));
    } else if (inputText && isSuccessRegions && isSuccessDestinations && isSuccessResorts) {
      const destinations = getParsedValues(
        dataDestinations?.find_available_destinations?.result,
        'destination',
      );
      const regions = getParsedValues(dataRegions?.find_available_regions?.result, 'region');
      const resorts = getParsedValues(dataResorts?.find_available_resorts?.result, 'resort');
      setOptions([...(destinations || []), ...(regions || []), ...(resorts || [])]);
    }
  }, [
    dataDefault,
    dataDestinations,
    dataRegions,
    dataResorts,
    inputText,
    selectedValue,
    date,
    duration,
    airports,
    rooms,
  ]);

  const handleApplyValues = (option: Option, close?: boolean) => {
    setSelectedValues(option);
    clickApplyHandler(option, 'destinations');
    if (close) setSelectExpanded(false);
  };

  const getInputFieldText = () => {
    if (selectedValues && selectedValues.length === 1) {
      return selectedValues[0].label;
    } else if (selectedValues && selectedValues.length === 2) {
      return `${selectedValues[0].label} and 1 other`;
    } else if (selectedValues && selectedValues.length > 2) {
      return `${selectedValues[0].label} + ${selectedValues.length - 1} More`;
    } else {
      return 'Any Destination';
    }
  };

  const icon = <img src={location} width='22' alt='destination' />;

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Select Airport</h5>
    </div>
  );

  const modalFooter = (
    <div className='modal-mobile-footer'>
      <Button
        label='Cancel'
        isTransparent
        onClick={() => {
          setSelectExpanded(false);
        }}
      />
      <Button label='Apply' primary onClick={() => handleApplyValues(selectedValues, true)} />
    </div>
  );

  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            setSelectExpanded(false);
          }}
          className='destination-select-overlay'
        ></div>
      )}
      <div className='search-bar-input-field-autocomplete'>
        <SearchBarInputField
          title='Select Destination'
          value={getInputFieldText()}
          onClick={() => setSelectExpanded(!selectExpanded)}
          icon={icon}
          ref={searchBarInputFieldRef}
        />
        {selectExpanded && (
          <div className='destination-select-desktop-view'>
            <Autocomplete
              options={options}
              onChange={(option) => handleApplyValues(option)}
              selectedValues={selectedValues}
              onInputChange={setInputText}
            />
          </div>
        )}
      </div>
      <div className='destination-select-mobile-view'>
        <Modal
          isOpen={selectExpanded}
          toggle={() => {
            setSelectExpanded(false);
          }}
          header={modalHeader}
          footer={modalFooter}
        >
          <Autocomplete
            options={options}
            onChange={setSelectedValues}
            selectedValues={selectedValues}
            onInputChange={setInputText}
          />
        </Modal>
      </div>
    </>
  );
};

export default DestinationSelect;
