import { FunctionComponent, useEffect, useState } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';
import './SelectPage.scss';
import { SelectFlights } from '../../components/SelectFlights';
import bigPlane from '../../components/assets/plane-big.svg';
import Logo from '../../components/assets/logo.svg';
import {
  addProductPrechecks,
  getBasket,
  productFlights,
  productRooms,
  sessionNew,
} from '../../hooks/useRequest';
import { FlightDetailsState } from '../../components/HolidaySummary/FlightDetails';
import moment from 'moment';
import { IBasketAPI, IFlights } from './Interfaces';
import { Basket } from '../../components/Basket/Basket';
import { IBasket, IFlightBounds } from '../../components/Basket/Interfaces';
import { Accomodation } from '../../components/Accommodation/Accomodation';
import { IRooms } from '../../components/Accommodation/Interfaces';
import { Loader } from '../../components/Loader/Loader';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { Button } from '../../components/Button';

import { useNavigate } from 'react-router-dom';
import { SelectBoard } from '../../components/SelectBoard';
import { Board } from '../../components/SelectBoard/SelectBoard';
import { HOTEL_TYPE } from './MapHotelType';
import { ProgressBarDefault } from '../../components/ProgressBar/ProgressBarDefaults';

interface HomePageProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

const SelectPage: FunctionComponent<HomePageProps> = ({ onLogin = noop, openMenu = noop }) => {
  const {
    RootStore: {
      sessionStore: { setUserSession, userSession },
      basketStore: { setBasket, basket },
      holidayStore: { holiday },
    },
  } = useStores() as { RootStore: RootStore };
  const navigate = useNavigate();

  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);
  const [flightDetails, setFlightDetails] = useState<FlightDetailsState[][]>([]);
  const [selectedFlight, setSelectedFlight] = useState<number>(0);
  const [flightXHR, setFlightsXHR] = useState<boolean>(false);
  const [roomXHR, setRoomXHR] = useState<boolean>(false);

  const [roomDetailsFiltered, setRoomDetailsFiltered] = useState<IRooms[]>([]);
  const [roomDetails, setRoomDetails] = useState<IRooms[]>([]);
  const [roomLoaded, setRoomLoaded] = useState<boolean>(false);
  const [everythingLoaded, setEverythingLoaded] = useState<boolean>(false);
  const [flightLoaded, setFlightLoaded] = useState<boolean>(false);
  const [boardDetails, setBoardDetails] = useState<Board[]>([]);
  const [newSession, setNewSession] = useState(false);
  // we may need to store the last accomidation id on the browser localstorage.
  if (!holiday?.accommodation.id) {
    navigate('/');
  }

  useEffect(() => {
    if (!newSession) {
      const performSessionXHR = async () => {
        const sessionResults = await sessionNew();

        if (sessionResults?.session_new?.result?.session) {
          setUserSession(sessionResults?.session_new?.result?.session);
        }
      };

      setNewSession(true);
      setUserSession('');
      performSessionXHR();
    }
  }, [userSession, newSession]);

  const SelectFlightsProps = {
    title: 'Select Flights',
    titleImage: bigPlane,
    flightsDetails: flightDetails,
    selectedId: selectedFlight,
    onClick: async (outbounds: FlightDetailsState, inbound: FlightDetailsState) => {
      basket.outbounds = [
        {
          destination: {
            travelFrom: outbounds.departurePlace,
            travelTo: outbounds.arrivalPlace,
            arrivedDate: new Date(outbounds.arrivalTime).toISOString(),
            travelToDate: new Date(outbounds.departureTime).toISOString(),
            flight: {
              flightNumber: outbounds.airlineCode,
              company: outbounds.airlineLogo,
            },
          },
        },
      ];

      basket.inbounds = [
        {
          destination: {
            travelFrom: inbound.departurePlace,
            travelTo: inbound.arrivalPlace,
            arrivedDate: new Date(inbound.arrivalTime).toISOString(),
            travelToDate: new Date(inbound.departureTime).toISOString(),
            flight: {
              flightNumber: inbound.airlineCode,
              company: inbound.airlineLogo,
            },
          },
        },
      ];

      setBasket({ ...basket });
      setSelectedFlight(outbounds.id || 0);
      // add a flight

      if (outbounds.id && userSession.sessionId) {
        await addProductPrechecks(userSession.sessionId, outbounds.id, 1);
        const basketResponse: IBasketAPI = await getBasket(userSession.sessionId);
        setBasket({ ...basket, ...{ basketData: basketResponse } });
      }
    },
  };

  const selectRoom = async (sessionId: string, room: IRooms) => {
    await addProductPrechecks(sessionId, room.id, 0);
    const basketResponse: IBasketAPI = await getBasket(sessionId);
    setBasket({ ...basket, ...{ basketData: basketResponse } });
  };

  const getRooms = async () => {
    const data = await productRooms(userSession.sessionId || '', {
      accommodationId: holiday?.accommodation.id || 0,
      date: holiday?.accommodation.date || moment().format('YYYY-MM-DD'),
    });
    const roomsData: IRooms[] = [];
    const boards: Board[] = [];
    // make an interface for this xhrcall.
    const roomsBoardsAsso: Record<number, number> = {};

    let firstId = 0;
    data.rooms.result.forEach((data: any) => {
      data.rooms.forEach((room: any) => {
        if (!firstId) firstId = room.id;
        roomsData.push({
          title: room.description,
          type: room.board_description,
          terms: room.cancellation_policy,
          price: room.price_per_person,
          id: room.id,
          boardId: room.board,
        });
        if (roomsBoardsAsso[room.board] && roomsBoardsAsso[room.board] < room.price_per_person) {
          roomsBoardsAsso[room.board] = room.price_per_person.toFixed(2);
        } else {
          roomsBoardsAsso[room.board] = room.price_per_person.toFixed(2);
        }
      });
    });

    Object.keys(roomsBoardsAsso).forEach((key) => {
      const keyToNumber = Number(key);
      const val = roomsBoardsAsso[keyToNumber];
      boards.push({
        type: HOTEL_TYPE[keyToNumber],
        price: val,
        id: keyToNumber,
      });
    });

    setBoardDetails(boards);
    setRoomDetails(roomsData);
    setRoomDetailsFiltered(roomsData);
    if (userSession.sessionId && firstId) {
      await addProductPrechecks(userSession.sessionId, firstId, 0);
      loadBasket(basket);
    }
    setRoomLoaded(true);
  };

  const transformToIFlight = (flight: FlightDetailsState): IFlightBounds => {
    return {
      destination: {
        travelFrom: flight.departurePlace,
        travelTo: flight.arrivalPlace,
        arrivedDate: new Date(flight.arrivalTime).toISOString(),
        travelToDate: new Date(flight.departureTime).toISOString(),
        flight: {
          flightNumber: flight.airlineCode,
          company: flight.airlineLogo,
        },
      },
    };
  };

  const getFlights = async () => {
    const data = await productFlights(userSession.sessionId || '', {
      duration: holiday?.accommodation.duration || 7,
      arrival: holiday?.accommodation.arrival || '',
      departure: holiday?.accommodation.departure || '',
      date: holiday?.accommodation.date || '',
    });

    if (data.flights?.result) {
      const flightDetails: FlightDetailsState[][] = [[], []];
      data.flights.result.forEach((flight: IFlights) => {
        flightDetails[0].push({
          date: flight.departing,
          duration: moment(flight.departing).diff(flight.arrival, 'days'),
          airlineLogo: flight.airline,
          airlineCode: flight.number,
          flightType: 'Outbound',
          departureTime: flight.departing,
          departurePlace: flight.departure_airport.name,
          arrivalTime: flight.arrival,
          arrivalPlace: flight.arrival_airport.name,
          price: flight.price_per_person,
          id: flight.id,
        });
        flightDetails[1].push({
          date: flight.return_departing,
          duration: moment(flight.return_departing).diff(flight.return_arrival, 'days'),
          airlineLogo: flight.return_airline,
          airlineCode: flight.return_number,
          flightType: 'Inbound',
          departureTime: flight.return_departing,
          departurePlace: flight.arrival_airport.name,
          arrivalTime: flight.return_arrival,
          arrivalPlace: flight.departure_airport.name,
          price: flight.price_per_person,
          id: flight.id,
        });
      });

      setFlightDetails(flightDetails);

      if (userSession.sessionId && flightDetails[0][0]?.id) {
        setSelectedFlight(flightDetails[0][0].id || 0);
        const results = await addProductPrechecks(userSession.sessionId, flightDetails[0][0].id, 1);
        basket.inbounds = [transformToIFlight(flightDetails[1][0])];
        basket.outbounds = [transformToIFlight(flightDetails[0][0])];
        await loadBasket(basket);
      }
      setFlightLoaded(true);
    }
  };

  const loadBasket = async (_basket: IBasket) => {
    if (userSession.sessionId) {
      const basketResponse: IBasketAPI = await getBasket(userSession.sessionId);
      setBasket({ ..._basket, basketData: basketResponse });
    }
    return true;
  };

  // api is sometimes locking on flights so we need to sequence flights->get then add products first then rooms.
  useEffect(() => {
    if (!flightXHR && userSession.sessionId && userSession.sessionId.length > 0 && newSession) {
      setFlightsXHR(true);
      getFlights();
    }

    if (userSession.sessionId && userSession.sessionId.length > 0 && newSession && flightLoaded) {
      getRooms();
    }
  }, [roomXHR, flightXHR, userSession.sessionId, newSession, flightLoaded]);

  let fadeOut = false;
  if (flightLoaded && roomLoaded && !everythingLoaded) {
    fadeOut = true;
    setTimeout(() => setEverythingLoaded(true), 1000);
  }

  if (!flightLoaded || !roomLoaded || !everythingLoaded) {
    return (
      <div className={`loader-page-container ${fadeOut ? 'loader-page-fadeout' : ''}`}>
        <img className='loader-page-logo' src={Logo} height={100} />
        <Loader
          loading={{
            flights: flightLoaded,
            extras: roomLoaded,
            rooms: roomLoaded,
          }}
        />
      </div>
    );
  }

  const filterBoard = (board: Record<number, boolean>) => {
    let allDeselected = true;
    Object.values(board).forEach((boardVal) => {
      if (boardVal) allDeselected = false;
    });

    if (allDeselected) {
      setRoomDetailsFiltered(roomDetails);
    } else {
      const rooms = roomDetails.filter((room) => {
        return board[room.boardId];
      });

      setRoomDetailsFiltered(rooms);
    }
  };

  ProgressBarDefault.forEach((progress) => {
    progress.isActive = false;
  });
  ProgressBarDefault[0].isActive = true;

  const combineBasket = { ...basket, ...(holiday || {}) };
  return (
    <BaseLayout
      onLogin={onLogin}
      openMenu={openMenu}
      progressBar={ProgressBarDefault}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
    >
      <div className='select-page-container'>
        <div className='select-page-col select-page-col-basket select-page-col-basket-mobile'>
          {basket && <Basket {...combineBasket} showMoreButton={true} />}
        </div>
        <div className='select-page-col'>
          <div className='select-page-container-select-flights'>
            <SelectFlights {...SelectFlightsProps} />
          </div>
          <div className='select-page-divider'> </div>
          {boardDetails && (
            <div className='select-page-board-details'>
              <SelectBoard onClick={filterBoard} boards={boardDetails} />
            </div>
          )}
          <Accomodation
            title='Select Rooms'
            selected={roomDetails[0]?.id}
            rooms={roomDetailsFiltered}
            onClick={(room) => {
              if (userSession.sessionId && room) selectRoom(userSession.sessionId, room);
            }}
          />

          <div className='select-page-divider'></div>
          <div className='select-page-continue-mobile'>
            <Button
              label={'Continue'}
              primary={true}
              isTransparent={false}
              onClick={() => {
                navigate('/extras');
              }}
            />
          </div>
        </div>

        <div className='extras-page-col extras-page-col-basket'>
          {flightDetails.length > 0 && basket && (
            <Basket {...combineBasket} showMoreButton={false} />
          )}
          <div className='select-page-divider'></div>
          <div>
            <Button
              label={'Continue'}
              primary={true}
              isTransparent={false}
              onClick={() => {
                navigate('/extras');
              }}
            />
          </div>
          <div className='select-page-divider'></div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default observer(SelectPage);
