import React, { ReactNode, forwardRef, Ref } from 'react'
import cn from 'classnames'
import './SearchBarInputField.scss'

interface SearchBarInputFieldProps {
  title: string
  additionalSearchBarInputFieldClassName?: string
  children?: string | ReactNode
  value?: string
  focus?: boolean
  onClick?: () => void
  icon?: ReactNode
}

const SearchBarInputField = forwardRef(
  (
    {
      title,
      additionalSearchBarInputFieldClassName,
      children,
      value = '',
      focus = false,
      icon,
      onClick,
    }: SearchBarInputFieldProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const getValue = () =>
      value && value.length ? (
        <span className={'selected-value'}>{value}</span>
      ) : (
        <span className={'default-value'}>{children}</span>
      )

    return (
      <div
        ref={ref}
        onClick={onClick}
        className={cn('input-field-wrapper', { 'focus': focus }, additionalSearchBarInputFieldClassName)}
      >
        <p className={'input-field--title'}>{title}</p>
        <div className={'input-field--value'}>{getValue()}</div>
        <div className='icon-wrapper'>{icon}</div>
      </div>
    )
  },
)

SearchBarInputField.displayName = 'SearchBarInputField'

export default SearchBarInputField
