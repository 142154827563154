import { FC, useEffect, useRef, useState } from 'react';
import './CreditCard.scss';
import CreditIcon from '../assets/padlock-check.svg';
import AppleIcon from '../assets/apple-icon.svg';
import CVVIcon from '../assets/cvv.svg';
import Mastercard from '../assets/mastercard.svg.png';
import Visa from '../assets/visa.svg.png';
import Secureshield from '../assets/secureshield.svg';
import Padlock from '../assets/padlock.svg';
import Atba from '../assets/atba.svg';
import Atol from '../assets/atol.svg';
import Tick from '../assets/tick.svg';
import { Button } from '../Button';

import moment from 'moment';
import { StylesSelect } from './CreditCardSelectStyling';
import { CustomDropdownIndicator } from '../Room/CustomDropdownIndicator';
import { Option } from '../Autocomplete';
import Select from 'react-select';
import { ICreditCard } from './Interfaces';
import { LoaderSpinner } from '../Loader/Loader';
import { makeBooking, paymentStepOne, paymentStepTwo } from '../../hooks/useRequest';
import { IFrame } from './Iframe';
import { useNavigate } from 'react-router-dom';

// get all year dates
const yearOptions: Option[] = [];
for (let i = 0; i < 50; i++) {
  const date = moment().add(i, 'years');
  yearOptions.push({ value: date.year(), label: date.year() });
}

const datesOptions: Option[] = [];
[
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].forEach((month, index) => {
  datesOptions.push({ value: index, label: month });
});

export const CreditCard: FC<ICreditCard> = ({ price, sessionId, onBooking }) => {
  const paymentForm = useRef<HTMLFormElement>(null);
  const gatewayForm = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({});

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [completedIndicator, setCompletedIndicator] = useState(false);
  const [bookingId, setBookingId] = useState(0);

  const [values, setValues] = useState<Record<string, string>>({
    expiryMonth: '',
    expiryYear: '',
    cardName: '',
    cvvNumber: '',
    cardNumber: '',
  });

  const [PGWJWT, setPGWJWT] = useState('');
  const [paymentSession, setPaymentSession] = useState('');

  const updateErrorsById = (id: string, _values: any) => {
    formErrors[id] = _values[id].length === 0 ? true : false;
    setFormErrors({ ...formErrors });
  };

  const updateErrors = () => {
    const errors: Record<string, boolean> = {};
    Object.keys(values).forEach((value) => {
      if (values[value]?.length === 0) {
        errors[value] = true;
      } else {
        errors[value] = false;
      }
    });
    setFormErrors({ ...errors });
  };
  const updateValue = (e: any, id: string) => {
    values[id] = e.value;
    setValues({ ...values });
    updateErrorsById(id, values);
  };

  const updateInputValue = (e: any) => {
    values[e.currentTarget.id] = e.currentTarget.value;
    setValues({ ...values });
    updateErrorsById(e.currentTarget.id, values);
  };

  useEffect(() => {
    if (PGWJWT && paymentSession && gatewayForm.current) {
      setTimeout(() => {
        // step 2.
        // if (gatewayForm.current) gatewayForm.current.submit();
      }, 100);
    }
  }, [PGWJWT, paymentSession]);

  const showSuccess = () => {
    setCompletedIndicator(true);
    setTimeout(() => {
      navigate('/booking-confirmed/');
    }, 3000);
  };

  const payment = async () => {
    let bookingResults: any = {};
    let _bookingId = bookingId;
    if (bookingId === 0) {
      bookingResults = await makeBooking(sessionId);
      setBookingId(bookingResults?.basket_new_booking?.result?.booking.id);
      _bookingId = bookingResults?.basket_new_booking?.result?.booking.id;
      if (onBooking) onBooking(_bookingId);
    }
    if (_bookingId !== 0) {
      const paymentResults = await paymentStepOne(
        sessionId,
        {
          cardNumber: values.cardNumber,
          expMonth: parseInt(values.expiryMonth),
          expYear: parseInt(values.expiryYear),
          name: values.cardName,
          securityNo: values.cvvNumber,
        },
        Number(price?.toFixed(2)),
        _bookingId,
      );

      if (paymentResults?.booking_make_a_payment?.result?.completed === 0) {
        if (paymentResults?.booking_make_a_payment?.result?.payment_session) {
          setPaymentSession(paymentResults.booking_make_a_payment.result.payment_session);
        }
        if (paymentResults?.booking_make_a_payment?.result?.pgw_response) {
          setPGWJWT(paymentResults.booking_make_a_payment.result.pgw_response);
        }

        if (
          paymentResults.booking_make_a_payment?.result?.payment_session &&
          paymentResults.booking_make_a_payment?.result?.payment_session.length > 0 &&
          paymentResults.booking_make_a_payment?.result?.pgw_response &&
          paymentResults.booking_make_a_payment?.result?.pgw_response.length > 0
        ) {
          const paymentStepTwoResults = await paymentStepTwo(
            sessionId,
            {
              cardNumber: values.cardNumber,
              expMonth: parseInt(values.expiryMonth),
              expYear: parseInt(values.expiryYear),
              name: values.cardName,
              securityNo: values.cvvNumber,
            },
            bookingResults?.basket_new_booking.result.booking.cost,
            bookingResults?.basket_new_booking.result.booking.id,
            paymentResults.booking_make_a_payment.result.payment_session,
          );
          if (paymentStepTwoResults?.booking_make_a_payment?.result?.completed === 1) {
            showSuccess();
          } else {
            setLoadingIndicator(false);
          }
        } else {
          setLoadingIndicator(false);
        }
      } else if (paymentResults?.booking_make_a_payment?.result?.completed === 1) {
        showSuccess();
      } else {
        setLoadingIndicator(false);
      }
    }
  };

  return (
    <>
      <IFrame>
        <form
          ref={gatewayForm}
          id='collectionForm'
          method='POST'
          action='https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect'
        >
          <input type='text' name='Bin' value={`${values.cardNumber}`} />
          <input type='text' name='JWT' value={`${PGWJWT}`} />
        </form>
      </IFrame>
      <div className={`credit-card-container ${!completedIndicator ? 'credit-card-hidden' : ''}`}>
        <div className='credit-card-loader-text'>Your payment was successful.</div>
        <div className='credit-card-loader'>
          <div className='credit-card-rel-container'>
            <div className='credit-card-circle'>
              <img className='credit-card-tick' src={Tick} height={100} width={100} />
            </div>
          </div>
          <div className='credit-card-loader-tag'>Peace of mind</div>
          <div className='credit-card-flex-center'>
            <div>
              <img src={Atba} height={170} width={170} />
            </div>
            <div>
              <img src={Atol} height={75} width={75} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`credit-card-container ${
          !loadingIndicator || completedIndicator ? 'credit-card-hidden' : ''
        }`}
      >
        <div className='credit-card-loader-text'>Proceesing your payment.</div>
        <div className='credit-card-loader'>
          <div className='credit-card-rel-container'>
            <LoaderSpinner />
            <img
              className='credit-card-img-padlock-absolute'
              src={Padlock}
              height={75}
              width={75}
            />
          </div>
          <div className='credit-card-loader-tag'>Peace of mind</div>
          <div className='credit-card-flex-center'>
            <div>
              <img src={Atba} height={170} width={170} />
            </div>
            <div>
              <img src={Atol} height={75} width={75} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`credit-card-container ${
          loadingIndicator || completedIndicator ? 'credit-card-hidden' : ''
        }`}
      >
        <h4 className='credit-card-title'>
          <img className='credit-card-icon' src={CreditIcon} height='30' width='30' />
          <span>Payment Method</span>
        </h4>
        {/* <div className='credit-card-button-container'>
          <Button label='Pay' icon={AppleIcon} />
        </div>
        <div className='grid-3-3'>
          <div className='credit-card-line' />
          <div className='credit-card-pay-with-card'>or pay with card </div>
          <div className='credit-card-line' />
        </div> */}
        <form
          ref={paymentForm}
          onSubmit={(e) => {
            e.preventDefault();
            updateErrors();

            let errorsFound = false;
            Object.keys(values).forEach((value) => {
              if (values[value]?.length === 0) {
                errorsFound = true;
              }
            });
            if (!errorsFound) {
              setLoadingIndicator(true);
              payment();
            }
          }}
        >
          <div className='grid-2-2 grid-1-1-mob'>
            <div className='credit-card-input-container'>
              <label aria-required={true} className='credit-card-label'>
                Name on card*
                {formErrors.cardName && <span className='required-field'> Required field.</span>}
              </label>
              <input
                id='cardName'
                onChange={updateInputValue}
                className='credit-card-input'
                type='text'
                name='cc-name'
                autoComplete='cc-name'
              />
            </div>
            <div className='credit-card-input-container'>
              <label aria-required={true} className='credit-card-label'>
                Card number*
                {formErrors.cardNumber && <span className='required-field'> Required field.</span>}
              </label>
              <input
                onChange={updateInputValue}
                id='cardNumber'
                className='credit-card-input'
                type='text'
                name='cardnumber'
                autoComplete='cc-number'
              />
            </div>
          </div>
          <div className='grid-4-3 grid-2-2-mob'>
            <div className='credit-card-input-container'>
              <label aria-required={true} className='credit-card-label'>
                Expiry Month*
                {formErrors.expiryMonth && <span className='required-field'> Required field.</span>}
              </label>
              <Select
                onChange={(e) => {
                  updateValue(e, 'expiryMonth');
                }}
                inputId='expiryMonth'
                options={datesOptions}
                styles={StylesSelect}
                components={{ DropdownIndicator: CustomDropdownIndicator as any }}
              />
            </div>
            <div className='credit-card-input-container'>
              <label aria-required={true} className='credit-card-label'>
                Expiry Year*
                {formErrors.expiryYear && <span className='required-field'> Required field.</span>}
              </label>
              <Select
                onChange={(e) => {
                  updateValue(e, 'expiryYear');
                }}
                inputId='expiryYear'
                options={yearOptions}
                styles={StylesSelect}
                components={{ DropdownIndicator: CustomDropdownIndicator as any }}
              />
            </div>
            <div className='credit-card-input-container'>
              <label aria-required={true} className='credit-card-label'>
                CVV Number*
                {formErrors.cvvNumber && <span className='required-field'> Required field.</span>}
              </label>
              <input
                onChange={updateInputValue}
                id={'cvvNumber'}
                className='credit-card-input'
                type='text'
              />
            </div>
            <div>
              <img className='credit-card-cvv' src={CVVIcon} height={75} width={75} />
            </div>
          </div>
          <div className='credit-card-overview'>
            <h2>Total payments: £{price?.toFixed(2)} </h2>
            <div className='credit-card-overview-p'>
              {'By clicking "book now" you agreeing to our'}
            </div>
            <div className='credit-card-overview-p'>Agency terms and Booking conditions</div>
          </div>
          <Button
            onClick={() => {
              if (paymentForm && paymentForm.current) {
                paymentForm.current.requestSubmit();
              }
            }}
            label='Book now'
            primary={true}
          />
        </form>
        <div className='credit-card-book-now'></div>
        <div className='grid-3-3-safe-checkout'>
          <div className='credit-card-line' />
          <div className='credit-card-pay-with-card'>GURRANTEED SAFE CHECKOUT </div>
          <div className='credit-card-line' />
        </div>
        <div className='grid-3by3 grid-3by3-mob'>
          <div className='credit-card-align-right'>
            <img className='credit-card-visa-img' src={Visa} />
          </div>
          <div className='credit-card-align-center'>
            <img className='credit-card-mastercard-img' src={Mastercard} />
          </div>
          <div className='credit-card-ssl-container grid-custom-mob'>
            <img className='credit-card-ssl-img' src={Secureshield} />
            <span className='credit-card-ssl'>Secure SSL</span>
          </div>
        </div>
      </div>
    </>
  );
};
