import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import './MonthSelect.scss';
import arrowDown from '../../assets/down-arrow.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import moment from 'moment';
type MonthSelectorProps = {
  value: string | null | undefined;
  years: string[];
  onChange: (monthYear: { month: string; year: string }) => void;
};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const MonthSelect: FunctionComponent<MonthSelectorProps> = ({
  value,
  years,
  onChange,
}: MonthSelectorProps) => {
  const [month, setMonth] = useState(
    months[moment(value).month()] || months[new Date().getMonth()],
  );
  const [year, setYear] = useState((value && moment(value).year() + '') || years[0]);
  const [open, setOpen] = useState(false);
  const selectRef: React.RefObject<HTMLDivElement> = useRef(null);
  const selectOption = (month: string, year: string) => {
    setOpen(false);
    setMonth(month);
    setYear(year);
    onChange({ month, year });
  };

  useEffect(() => {
    setMonth(months[moment(value).month()])
    setYear( moment(value).year() + '')
  }, [value])

  const getCurrentMonthIndex = () => {
    return months.findIndex((item) => item === month);
  };

  const getCurrentYearIndex = () => {
    return years.findIndex((item) => item === year);
  };

  const onArrowClick = async (type: string) => {
    let curentMonthIndex = getCurrentMonthIndex();
    const currentYearIndex = getCurrentYearIndex();

    if (type === 'next') {
      if (curentMonthIndex === 11 && currentYearIndex + 1 === years.length) {
        return;
      }

      curentMonthIndex = curentMonthIndex === 11 ? 0 : curentMonthIndex + 1;
      setYear(years[currentYearIndex + (curentMonthIndex === 0 ? 1 : 0)]);
      setMonth(months[curentMonthIndex]);
    } else {
      if (!currentYearIndex && !curentMonthIndex) {
        return;
      }
      curentMonthIndex = curentMonthIndex === 0 ? 11 : curentMonthIndex - 1;
      setYear(years[currentYearIndex - (curentMonthIndex === 11 ? 1 : 0)]);
      setMonth(months[curentMonthIndex]);
    }
  };

  useEffect(() => {
    onChange({ month, year });
  }, [month, year]);

  const toggleList = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useOutsideClick(selectRef, handleClickOutside);

  return (
    <div className='custom-select' ref={selectRef}>
      <img
        src={arrowDown}
        className={'arrow arrow-prev'}
        onClick={() => onArrowClick('prev')}
      ></img>
      <div className={'custom-select--title'} onClick={toggleList}>
        <span>
          {month} {year}
        </span>
        <img src={arrowDown} alt='' />
      </div>
      <img
        src={arrowDown}
        className={'arrow arrow-next'}
        onClick={() => onArrowClick('next')}
      ></img>

      {open && (
        <ul className={'custom-select--items'}>
          {years.map((y) =>
            months.map((m) => (
              <li
                className={cn('custom-select--item', {
                  active: `${m} ${y}` === `${month} ${year}`,
                })}
                key={`${m}-${y}`}
                onClick={() => selectOption(m, y)}
              >{`${m} ${y}`}</li>
            )),
          )}
        </ul>
      )}
    </div>
  );
};

export default MonthSelect;
