import React, { FunctionComponent } from 'react';
import './FlightDetails.scss';
import { Button } from '../../Button/Button';
import plane from '../../assets/plane.svg';
import moment from 'moment/moment';

export interface FlightDetailsState {
  id?: number;
  date: string;
  duration: number;
  airlineLogo: string;
  airlineCode: string;
  flightType: string;
  departureTime: string;
  departurePlace: string;
  arrivalTime: string;
  arrivalPlace: string;
  isViewAlternatives?: boolean;
  price?: number;
  outBound?: boolean;
}

interface FlightDetailsProps {
  state: FlightDetailsState;
}

const FlightDetails: FunctionComponent<FlightDetailsProps> = ({ state }: FlightDetailsProps) => {
  const {
    date,
    flightType,
    airlineLogo,
    airlineCode,
    departureTime,
    departurePlace,
    arrivalPlace,
    arrivalTime,
    isViewAlternatives,
    outBound,
  } = state;

  const generateFlightTime = () => {
    const departing = departureTime;
    const arrival = moment(arrivalTime) || null;
    if (!departing || !arrival) {
      return '';
    }

    const diff = departing && arrival && moment.duration(arrival.diff(departing));
    if (!diff) {
      return '';
    }

    const hours = Math.floor(diff.asHours());
    const minutes = Math.round(diff.asMinutes()) - hours * 60;
    return `${hours}h ${minutes}m`;
  };

  return (
    <>
      <div className='flight-details-wrapper'>
        <div className='flight-details--top-section'>
          <div className='top-date-details'>
            <img src={plane} alt='' />
            <div className={'info-section'}>
              <span className='title'>{date}</span>
              <span className='sub-title'>{flightType}</span>
            </div>
          </div>
          <div className='flight-details--main-section'>
            <ul className='route-point'>
              <li className={'route-point--item'}>
                <span className='time'>{moment(departureTime).format('HH:mm')}</span>
                <span className='place-name'>{departurePlace}</span>
                <span className='flight-time'>{generateFlightTime()}</span>
              </li>
              <li className={'route-point--item'}>
                <span className='time'>{moment(arrivalTime).format('HH:mm') || '10:15'}</span>
                <span className='place-name'>{arrivalPlace}</span>
              </li>
            </ul>
          </div>
          {!outBound && (
            <div className='flight-info'>
              <span className='airline-logo-text'>{airlineLogo}</span>
              <span className='airline-code'>({airlineCode})</span>
            </div>
          )}
        </div>
        {isViewAlternatives && <Button label={'View alternative flights'}></Button>}
      </div>
    </>
  );
};

export default FlightDetails;
