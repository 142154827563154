import { FC, useState } from 'react';
import { ILuggage, ILuggageBasket, ILuggageOptions } from './Interfaces';
import './Luggage.scss';
import LuggageIcon from '../assets/luggage.svg';
import { Stepper } from '../Stepper/Stepper';
import { stepDirection } from '../Stepper/Interfaces';
const Luggage: FC<ILuggage> = ({ options, description, onChange }) => {
  const [basket, setBasket] = useState<Record<string, ILuggageBasket>>({});
  const [grandTotal, setGrandTotal] = useState<string>();

  const stepperAmount = (
    amount: number,
    luggageOption: ILuggageOptions,
    direction?: stepDirection,
  ) => {
    basket[luggageOption.id] = {
      amount: amount,
      price: luggageOption.price,
      description: `${amount} x ${luggageOption.title} : £${(amount * luggageOption.price).toFixed(
        2,
      )}`,
      classNames: '',
      id: luggageOption.id,
      productId: luggageOption.productId,
    };

    // for the animation.
    setTimeout(() => {
      if (basket[luggageOption.id]) {
        basket[luggageOption.id].classNames = 'animate-toFadeInAndOut';
        setBasket({ ...basket });
      }
    }, 1);

    // if (amount === 0) delete basket[luggageOption.id];
    setBasket({ ...basket });
    let total = 0;
    Object.values(basket).forEach((item) => {
      total += item.amount * item.price;
    });
    if (total > 0) setGrandTotal(`Grand total on Luggage: £${total.toFixed(2)}`);
    else setGrandTotal('');
    if (onChange) {
      onChange(basket[luggageOption.id], direction);
    }
  };

  return (
    <div className='luggage-container'>
      <h4 className='luggage-title'>
        <img className='luggage-icon' src={LuggageIcon} height='30' width='30' />
        <span>Luggage</span>
      </h4>
      <p className='luggage-description'>{description}</p>
      <p className='luggage-info'>More information </p>

      {options.map((luggageOption, index) => {
        return (
          <div className='luggage-container-child' key={`${luggageOption.title}-luggage-${index}`}>
            <span className='luggage-option-title'>{luggageOption.title}</span>
            <div
              className={`luggage-option-info ${basket[luggageOption.id]?.classNames || ''}`}
            >{`You've added ${basket[luggageOption.id]?.amount || 0} x ${
              luggageOption.title
            }`}</div>
            <div className='luggage-option-price'>
              {`£${luggageOption.price.toFixed(2)}`}
              <span className='luggage-option-price-perbag'>per bag</span>
            </div>
            <div className='luggage-stepper-container'>
              <Stepper
                minValue={0}
                maxValue={luggageOption.maxValue}
                onChange={(value, direction) => {
                  stepperAmount(value, luggageOption, direction);
                }}
              />
              <span className='luggage-option-inline-price'>
                {`£${luggageOption.price.toFixed(2)}`}
                <div className='luggage-option-price-inline-perbag'>per bag</div>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { Luggage };
