import React, { FunctionComponent, useState, useRef, useCallback } from 'react'
import { InfoBox, OverlayView } from '@react-google-maps/api'
import cn from 'classnames'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import { Place, GoogleMapType } from './Map'
import { HolidayTopDetails } from '../HolidayDetails'
import image from '../../components/assets/image.png'

import './MapModal.scss'

interface CustomMarkerProps {
  place: Place
  isActive?: boolean
  map?: GoogleMapType
  hotelName?: string
  hotelLocation?: string
  rating?: number
  tripadvisorRating?: number
  tripadvisorReviews?: string
  price?: number
  shouldShowInfoBox?: boolean
  onClick: (place: Place) => void
}

const CustomMarker: FunctionComponent<CustomMarkerProps> = ({
  place,
  map,
  isActive = false,
  hotelName,
  hotelLocation,
  rating,
  tripadvisorRating,
  tripadvisorReviews,
  price,
  shouldShowInfoBox = false,
  onClick,
}: CustomMarkerProps) => {
  const [active, setActive] = useState<boolean>(isActive)
  const innerRef = useRef<HTMLDivElement>(null)
  const outsideClickCallback = useCallback(() => setActive(false), [])

  const onMarkerClickHandler = (place: Place) => {
    setActive(true)
    onClick(place)
  }
  
  useOutsideClick(innerRef, outsideClickCallback)

  return (
    <div className='custom-marker-wrapper' ref={innerRef}>
      <OverlayView position={place.position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div className={cn('custom-marker', { active: active })}>
          <button
            className='marker-button'
            onClick={() => onMarkerClickHandler(place)}
            type='button'
          >
            {place.price ? `£${place.price.toFixed(2)} pp` : place.title}
          </button>
          <div className='triangle-left'>
            <div className='inner-triangle'></div>
          </div>
          <div className='shadow'></div>
          {active && shouldShowInfoBox && (
            <InfoBox position={place.position as any} options={{ closeBoxMargin: '0' }}>
              <div className='custom-info-window-content'>
                <div className='info-window-image'>
                  <img src={image} alt={hotelName} />
                </div>
                <div className='info-box-information-wrapper'>
                  <HolidayTopDetails
                    hotelName={hotelName || ''}
                    hotelLocation={hotelLocation || ''}
                    rating={rating || 5}
                    tripadvisorRating={tripadvisorRating || 5}
                    tripadvisorReviews={tripadvisorReviews || ''}
                  />
                  { price && <span className='price'>{`£${price.toFixed(2)} pp`}</span> }
                </div>
              </div>
            </InfoBox>
          )}
        </div>
      </OverlayView>
    </div>
  )
}

export default React.memo(CustomMarker)
