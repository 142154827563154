import React, { FunctionComponent, ReactNode } from 'react';
import { noop, uniqBy } from 'lodash';
import Select, { StylesConfig } from 'react-select';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import './Autocomplete.scss';
import { CustomBoardOption } from '../Board/CustomBoardOption';
import { CustomOption } from './CustomOption';

export interface Option {
  value: string | number | string[];
  label: string | number | ReactNode;
  type?: string;
}

export interface AutocompleteProps {
  options: Option[];
  additionalStyles?: StylesConfig;
  selectedValues?: Option[];
  onChange?: (option: Option) => void;
  onInputChange?: (text: string) => void;
}

const Autocomplete: FunctionComponent<AutocompleteProps> = ({
  options,
  additionalStyles,
  selectedValues,
  onChange = noop,
  onInputChange = noop,
}: AutocompleteProps) => {
  const mobStyles: StylesConfig = {
    control: () => ({
      minHeight: 24,
      borderRadius: 6,
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderColor: '#9eb7cc',
      borderStyle: 'solid',
      borderWidth: 1,
      cursor: 'default',
      display: 'flex',
      justifyContent: 'space-between',
      label: 'control',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    menu: () => ({
      height: 'auto',
      width: '100%',
      zIndex: 1,
      marginBottom: 16,
      marginTop: 0,
      boxSizing: 'border-box',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '12px 8px',
    }),
    multiValue: () => ({ display: 'none' }),
    menuList: (base) => ({
      ...base,
      maxHeight: '570px',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#01447B',
    }),
    ...additionalStyles,
  };

  const customStyles: StylesConfig = {
    control: () => ({
      minHeight: 24,
      borderRadius: 6,
      alignItems: 'center',
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderColor: '#9eb7cc',
      borderStyle: 'solid',
      borderWidth: 1,
      cursor: 'default',
      display: 'flex',
      justifyContent: 'space-between',
      label: 'control',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    menu: () => ({
      height: 'auto',
      width: '100%',
      zIndex: 1,
      marginBottom: 16,
      marginTop: 16,
      boxSizing: 'border-box',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '2px 8px',
    }),
    multiValue: () => ({ display: 'none' }),
    menuList: (base) => ({
      ...base,
      maxHeight: '300px',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#01447B',
    }),
    ...additionalStyles,
  };

  const handleSelectChange = (option: any) => {
    const duplicates: any = [];
    const map = new Map();

    for (const obj of option) {
      if (map.has(JSON.stringify(obj))) {
        duplicates.push(obj);
      } else {
        map.set(JSON.stringify(obj), true);
      }
    }

    if (duplicates) {
      onChange(option.filter((o: any) => !duplicates.some((d: any) => d.label === o.label)));
    } else {
      onChange(option);
    }
  };

  return (
    <>
      <div className='autocomplete-container autocomplete-container-mobile'>
        <Select
          placeholder='+ Add another'
          isMulti
          controlShouldRenderValue={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          menuIsOpen={true}
          onChange={handleSelectChange}
          options={selectedValues ? uniqBy([...selectedValues, ...options], 'label') : options}
          components={{
            Option: (props: any) => (
              <CustomOption
                {...props}
                isMobile={true}
                isSelected={
                  selectedValues &&
                  selectedValues.some((selectedOption) => selectedOption.label === props.data.label)
                }
              ></CustomOption>
            ),
          }}
          value={selectedValues}
          styles={mobStyles}
          onInputChange={(value) => onInputChange(value)}
        />
      </div>
      <div className='autocomplete-container autocomplete-container-desktop'>
        <Select
          placeholder='+ Add another'
          isMulti
          controlShouldRenderValue={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          menuIsOpen={true}
          onChange={handleSelectChange}
          options={selectedValues ? uniqBy([...selectedValues, ...options], 'label') : options}
          components={{
            Option: (props: any) => (
              <CustomOption
                {...props}
                isMobile={false}
                isSelected={
                  selectedValues &&
                  selectedValues.some((selectedOption) => selectedOption.label === props.data.label)
                }
              ></CustomOption>
            ),
          }}
          value={selectedValues}
          styles={customStyles}
          onInputChange={(value) => onInputChange(value)}
        />
      </div>
    </>
  );
};

export default Autocomplete;
