import { FC, useState } from 'react';
import { ISelectTransfers, ISelectTransferPrice, ISelectTransfersOptions } from './Interfaces';
import './SelectTransfer.scss';
import TaxiIcon from '../assets/taxi.svg';
import { Button } from '../Button';

const RenderPrice: FC<ISelectTransferPrice> = ({ price }) => {
  return (
    <span>
      {`+ £${price}`} <br />{' '}
      <span className='select-transfer-container-per-person'>Per Person</span>
    </span>
  );
};

const SelectTransfer: FC<ISelectTransfers> = ({ options }) => {
  const [selectedTransfer, setSelectedTransfer] = useState<number>(0);

  const setSelected = (selected: number, selectTransferOption?: ISelectTransfersOptions) => {
    setSelectedTransfer(selected);
  };

  return (
    <div>
      <div className='select-transfer-container'>
        <h4 className='select-transfer-title'>
          <img className='select-transfer-icon' src={TaxiIcon} height='20' width='20' />
          <span className='select-transfer-title-text'>Select Transfers</span>
        </h4>
        {/* Mobile */}
        <div
          className={`select-transfer-container-child-mobile ${
            selectedTransfer === 0 ? 'select-transfer-container-child-mobile-active' : ''
          }`}
        >
          <div>
            <h4>No Transfer</h4>
          </div>
          <div>
            <Button
              overrideClassNames={`select-transfer-button ${
                selectedTransfer === 0 ? 'select-transfer-button-active' : ''
              }`}
              label={selectedTransfer === 0 ? 'Selected' : 'Select'}
              isTransparent={true}
              onClick={() => {
                setSelected(0);
              }}
            />
          </div>
        </div>
        {options.map((selectTransferOption, index) => {
          return (
            <div
              key={selectTransferOption.id + 'selectTransferOption'}
              className={`select-transfer-container-child-mobile ${
                selectedTransfer === index + 1
                  ? 'select-transfer-container-child-mobile-active'
                  : ''
              }`}
            >
              <div>
                <h4 className='select-transfer-heading'>{selectTransferOption.title}</h4>
                <div className='select-transfer-container-duration'>
                  Estimated journey time: <b>{selectTransferOption.duration}</b>
                </div>
              </div>
              <div>
                {selectTransferOption.price && (
                  <div className='select-transfer-container-price'>
                    <RenderPrice price={selectTransferOption.price} />
                  </div>
                )}
                <Button
                  overrideClassNames={`select-transfer-button ${
                    selectedTransfer === index + 1 ? 'select-transfer-button-active' : ''
                  }
                  ${selectTransferOption.price ? 'select-transfer-button-has-price' : ''}`}
                  label={selectedTransfer === index + 1 ? 'Selected' : 'Select'}
                  isTransparent={true}
                  onClick={() => {
                    setSelected(index + 1, selectTransferOption);
                  }}
                />
              </div>
            </div>
          );
        })}
        {/* Desktop */}
        <div
          className={`select-transfer-container-child ${
            selectedTransfer === 0 ? 'select-transfer-container-child-active' : ''
          }`}
        >
          <div>
            <h4>No Transfer</h4>
          </div>
          <div></div>
          <div>
            <Button
              overrideClassNames={`select-transfer-button ${
                selectedTransfer === 0 ? 'select-transfer-button-active' : ''
              }`}
              label={selectedTransfer === 0 ? 'Selected' : 'Select'}
              isTransparent={true}
              onClick={() => {
                setSelected(0);
              }}
            />
          </div>
        </div>
        {options.map((selectTransferOption, index) => {
          return (
            <div
              key={selectTransferOption.id + 'selectTransferOption'}
              className={`select-transfer-container-child ${
                selectedTransfer === index + 1 ? 'select-transfer-container-child-active' : ''
              }`}
            >
              <div>
                <h4>{selectTransferOption.title}</h4>
              </div>
              <div className='select-transfer-container-duration'>
                Estimated journey time: <b>{selectTransferOption.duration}</b>
              </div>
              <div className='select-transfer-container-price'>
                {selectTransferOption.price && <RenderPrice price={selectTransferOption.price} />}
              </div>
              <div>
                <Button
                  overrideClassNames={`select-transfer-button ${
                    selectedTransfer === index + 1 ? 'select-transfer-button-active' : ''
                  }`}
                  label={selectedTransfer === index + 1 ? 'Selected' : 'Select'}
                  isTransparent={true}
                  onClick={() => {
                    setSelected(index + 1, selectTransferOption);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { SelectTransfer, RenderPrice };
