import React, { useEffect, useState } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import './PassengerDetailsPage.scss';
import { noop } from 'lodash';
import { PassengerDetails } from '../../components/PassengerDetails';
import { Basket } from '../../components/Basket/Basket';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { ProgressBarDefault } from '../../components/ProgressBar/ProgressBarDefaults';
import { BasketDummy } from '../ExtrasPage/BasketDummy';
import { useNavigate } from 'react-router-dom';

export default function PassengerDetailsPage() {
  const [passengersInformation, setPassengersInformation] = useState<any>();
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const {
    RootStore: {
      sessionStore: { userSession },
    },
  } = useStores() as { RootStore: RootStore };

  const navigate = useNavigate();
  
  useEffect(() => {
    if (!userSession.sessionId) {
      navigate('/');
    }
  }, [userSession?.sessionId]);

  const handleChange = (value: any, type: string) => {
    if (passengersInformation && passengersInformation[type]) {
      const newValue = { ...passengersInformation[type], ...value };

      setPassengersInformation({
        ...passengersInformation,
        [type]: newValue,
      });
    } else {
      setPassengersInformation({
        ...passengersInformation,
        [type]: value,
      });
    }
  };

  return (
    <BaseLayout onLogin={noop} openMenu={noop} progressBar={ProgressBarDefault}>
      <div className='passengers-details-wrapper'>
        <PassengerDetails
        passengersData={passengersInformation}
          additionalPassengers={1}
          sessionId={userSession.sessionId || ''}
          onChange={handleChange}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
        <div className='passengers-details-submit-button-mobile'>
          <input
            className='passengers-details-submit-button'
            type='submit'
            form='passengersForm'
            onClick={() => setIsFormValid(false)}
            value='Continue'
          />
        </div>
        <div className='extras-page-col extras-page-col-basket'>
          <Basket {...BasketDummy} />
          <div className='extras-page-divider' />
          <div className='passengers-details-submit-button-desktop'>
            <input
              className='passengers-details-submit-button'
              type='submit'
              form='passengersForm'
              onClick={() => setIsFormValid(false)}
              value='Continue'
            />
          </div>
          <div className='extras-page-divider' />
        </div>
      </div>
    </BaseLayout>
  );
}
