import { FC, useState } from 'react';
import { ISelectPayment, IDeposit, IFullPayment, IMonthlyPayments } from './Interfaces';
import './SelectPayment.scss';
import CreditCardIcon from '../assets/creditcard.svg';
import { Button } from '../Button';
import moment from 'moment';

const MonthlyPayments: FC<IMonthlyPayments> = ({ instalments, selected, onClick }) => {
  return (
    <div
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>Low Deposit</span>
      </h4>
      <div className='select-payment-grid-50-50'>
        <div>
          <div className='select-payment-breakdown'>
            <div className='select-payment-circle select-payment-circle-active'>
              {instalments.length !== 0 && instalments.length !== 1 && (
                <div className='select-payment-circle-line select-payment-circle-line-active' />
              )}
            </div>
            <div className='select-payment-breakdown-title'>Pay today </div>
            <div className='select-payment-breakdown-price'>£{instalments[0].price}</div>
          </div>
          {instalments
            .filter((val, index) => {
              if (index === 0) {
                return false;
              }
              return true;
            })
            .map((instalment, index) => {
              return (
                <div className='select-payment-breakdown' key={'instalment' + index}>
                  <div className='select-payment-circle'>
                    {instalments.length !== index + 2 && (
                      <div className='select-payment-circle-line select-payment-circle-line' />
                    )}
                  </div>
                  <div className='select-payment-breakdown-title select-payment-breakdown-title-with-date'>
                    Payment
                    <div className='select-payment-breakdown-date'>
                      {moment(instalment.date).format('DD-MM-YYYY')}{' '}
                    </div>
                  </div>
                  <div className='select-payment-breakdown-price'> £{instalment.price} </div>
                </div>
              );
            })}
        </div>
        <div className='select-payment-button-container'>
          <Button
            onClick={onClick}
            overrideClassNames={`select-payment-button ${
              selected ? 'select-payment-button-active' : ''
            }`}
            label={`${selected ? 'Selected' : 'Select'}`}
          />
        </div>
      </div>
    </div>
  );
};

const DepositPayment: FC<IDeposit> = ({ deposit, difference, selected, onClick }) => {
  return (
    <div
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>Low Deposit</span>
      </h4>
      <div className='select-payment-breakdown'>
        <div className='select-payment-circle select-payment-circle-active'>
          <div className='select-payment-circle-line select-payment-circle-line-active' />
        </div>
        <div className='select-payment-breakdown-title'> Pay today </div>
        <div className='select-payment-breakdown-price'>£{deposit.toFixed(2)}</div>
        <div className='select-payment-button-container'>
          <Button
            onClick={onClick}
            overrideClassNames={`select-payment-button ${
              selected ? 'select-payment-button-active' : ''
            }`}
            label={`${selected ? 'Selected' : 'Select'}`}
          />
        </div>
      </div>
      <div className='select-payment-breakdown'>
        <div className='select-payment-circle' />
        <div className='select-payment-breakdown-title'> Final payment </div>
        <div className='select-payment-breakdown-price'> £{difference.toFixed(2)} </div>
      </div>
    </div>
  );
};

const FullPayment: FC<IFullPayment> = ({ total, selected, onClick }) => {
  return (
    <div
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>Pay in full</span>
      </h4>
      <div className='select-payment-breakdown'>
        <div className='select-payment-circle select-payment-circle-active'></div>
        <div className='select-payment-breakdown-title'> Full payment</div>
        <div className='select-payment-breakdown-price'>£{total.toFixed(2)}</div>
        <div className='select-payment-button-container'>
          <Button
            onClick={onClick}
            overrideClassNames={`select-payment-button ${
              selected ? 'select-payment-button-active' : ''
            }`}
            label={`${selected ? 'Selected' : 'Select'}`}
          />
        </div>
      </div>
    </div>
  );
};

const SelectPayment: FC<ISelectPayment> = ({ payment, basket, instalments, onPriceChange }) => {
  const [selectedPayment, setSelectedPayment] = useState<number>(0);

  const setSelected = (selected: number, price?: number) => {
    setSelectedPayment(selected);
    if (onPriceChange && price) onPriceChange(price);
  };

  const deposit = basket.basketData?.basket?.result?.deposit_price || 0;

  return (
    <div>
      <div className='select-payment-container'>
        <h4 className='select-payment-title'>
          <img className='select-payment-icon' src={CreditCardIcon} height='20' width='20' />
          <span className='select-payment-title-text'>Payment Options</span>
          {payment.instalments && (
            <MonthlyPayments
              selected={selectedPayment === 1}
              onClick={() => {
                setSelected(1, instalments?.[0].price);
              }}
              instalments={instalments || []}
            />
          )}
          {deposit > 0 && (
            <DepositPayment
              deposit={deposit}
              onClick={() => {
                setSelected(2, deposit);
              }}
              selected={selectedPayment === 2}
              difference={basket.basketData?.basket?.result?.price_deposit_diff || 0}
            />
          )}
          <FullPayment
            onClick={() => {
              setSelected(3, basket.basketData?.basket?.result?.price);
            }}
            selected={selectedPayment === 3}
            total={basket.basketData?.basket?.result?.price || 0}
          />
        </h4>
      </div>
      <div className='select-payment-whitespace' />
    </div>
  );
};

export { SelectPayment };
