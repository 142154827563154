import React, { FunctionComponent } from 'react';
import { noop } from 'lodash';
import './SearchBar.scss';
import search from '../../components/assets/search-icon.svg';

interface SearchButtonProps {
  searchParams?: any; // TODO Fix typing
  isOverlayOpen?: boolean;
  onClick?: () => void;
  onSearchItemClick?: (type: string) => void;
}

const SearchButton: FunctionComponent<SearchButtonProps> = ({
  searchParams = null,
  isOverlayOpen,
  onSearchItemClick = noop,
  onClick = noop,
}: SearchButtonProps) => {
  if (!searchParams) return null;

  const icon = <img src={search} width='22' alt='calendar' />;

  const onSearchItemClickHandler = (type: string) => {    
    onSearchItemClick(type);
    
    !isOverlayOpen && onClick();
  }

  const selectedParams = (params: any) => {
    const airport = params?.airports && params?.airports[0]?.label;
    const destination = params?.destinations && params?.destinations[0]?.label;
    const duration = (params?.duration && params?.duration.label) || '7 days';
    const persons = () => {
      let total = 0;
      
      params?.rooms && Object.values(params?.rooms).map((room: any) => {
        total = total + room.adults + room.children;
      });

      return `Total ${total} guests`;
    };
    
      return (
        <div className='search-button-values'>
          {<span onClick={() => onSearchItemClickHandler('Airports')}>{airport ? airport : 'Any Airports'}</span>}
          {<span onClick={() => onSearchItemClickHandler('Destination')}>{destination ? destination : 'Any Destination'}</span>}
          <span onClick={() => onSearchItemClickHandler('Duration')}>{duration}</span>
          <span onClick={() => onSearchItemClickHandler('Rooms')}>{persons()}</span>
        </div>
      );
  };

  return (
    <div className='search-button-wrapper'>
      <div className='search-button-text'>{selectedParams(searchParams)}</div>
      <div className='search-button-icon' onClick={onClick}>{icon}</div>
    </div>
  );
};

export default SearchButton;
