import React, { FunctionComponent, useRef, useState, ReactNode, useEffect, FC } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './RoomsSelect.scss';
import { useModal } from '../../hooks/useModal';
import { Modal } from '../Modal';
import { Room, Guests } from '../Room';
import { Button } from '../Button';
import person from '../assets/person.svg';
import { noop } from 'lodash';
import { useAvailablePax } from '../../hooks/useRequest';
import {
  getAirportsFromParams,
  getDepartureDateFromParams,
  getDestinationsFromParams,
} from '../../pages/SearchResultsPage/SearchResultsPage';

interface RoomsSelectProps {
  clickApplyHandler: (rooms: { [key: string]: Guests }, name: string) => void;
  clickCancelHandler?: () => void;
  resetItemType?: (type: string) => void;
  searchItemType?: string;
  searchParams: any;
  searchParamsUrl?: any;
}
const values = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
];

interface IRoomSelector {
  roomNumber: number;
  updateRoomState?: (num: number) => void;
  handleApply?: (room: Guests, num: number) => void;
  selectedValues: Record<string, Guests>;
  roomDelete: (num: number) => void;
  refreshKey: number;
  isLoading?: boolean;
  data?: any;
}

const RoomSelector: FC<IRoomSelector> = ({
  selectedValues,
  roomNumber,
  updateRoomState,
  handleApply,
  roomDelete,
  refreshKey,
  isLoading,
  data,
}) => {
  let largestAdult = 1;
  let largestChildren = 1;
  const adultRecord: Record<string, number> = {};
  const childrenRecord: Record<string, number> = {};

  if (data) {
    data.forEach((result: any) => {
      if (result.adults > largestAdult) largestAdult = result.adults;
      if (result.children > largestChildren) largestChildren = result.children;
      adultRecord[result.adults] = result.children;
      childrenRecord[result.children] = result.adults;
    });
  }
  const vals = data
    ? [...Array(largestAdult).keys()].map((i: number) => {
        return {
          value: i + 1,
          label: i + 1,
        };
      })
    : [];
  const childVals = data
    ? [...Array(largestChildren + 1).keys()].map((i: number) => {
        return {
          value: i,
          label: i,
        };
      })
    : [];

  return (
    <div className='rooms-container'>
      {[...Array(roomNumber).keys()].map((idx) => {
        return (
          <Room
            key={idx + refreshKey}
            defaultValues={
              selectedValues[idx] || {
                adults: 2,
                children: 0,
              }
            }
            adultsOptions={
              isLoading
                ? []
                : vals.filter((x) => {
                    if (selectedValues && selectedValues[idx] && selectedValues[idx].children > 0) {
                      return childrenRecord[selectedValues[idx].children] >= x.value;
                    } else {
                      return true;
                    }
                  })
            }
            childrenOptions={
              isLoading
                ? []
                : childVals.filter((x) => {
                    if (selectedValues && selectedValues[idx]) {
                      return adultRecord[selectedValues[idx].adults] >= x.value;
                    } else {
                      return true;
                    }
                  })
            }
            roomNumber={idx + 1}
            removeRoom={(num) => {
              if (updateRoomState) {
                updateRoomState(roomNumber - 1);
                roomDelete(Number(num) - 1);
              }
            }}
            onApply={(room: Guests) => {
              if (handleApply) handleApply(room, idx);
            }}
          />
        );
      })}

      <Button
        label='Add Room'
        isTransparent
        isPlusIcon
        onClick={() => {
          if (updateRoomState) updateRoomState(roomNumber + 1);
        }}
      />
    </div>
  );
};

const RoomsSelect: FunctionComponent<RoomsSelectProps> = ({
  clickApplyHandler,
  clickCancelHandler,
  resetItemType = noop,
  searchItemType,
  searchParams,
  searchParamsUrl,
}) => {
  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [roomsNumber, setRoomsNumber] = useState<number>(
    Object.keys(searchParamsUrl?.rooms || {}).length || 1,
  );
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: Guests }>(
    searchParamsUrl.rooms || {
      0: { adults: 2, children: 0, childAge: {} },
    },
  );

  const [refreshKey, setRefreshKey] = useState(Math.random());
  const airports = getAirportsFromParams(searchParams.airports);
  const departureDate = getDepartureDateFromParams(searchParams, 'value');
  const departureType = getDepartureDateFromParams(searchParams, 'label');
  const destinations = getDestinationsFromParams(searchParams, 'destination');
  const regions = getDestinationsFromParams(searchParams, 'region');
  const resorts = getDestinationsFromParams(searchParams, 'resort');

  const { data, isLoading } = useAvailablePax(
    JSON.stringify(airports),
    departureDate,
    departureType,
    JSON.stringify(destinations),
    JSON.stringify(regions),
    JSON.stringify(resorts),
  );

  useEffect(() => {
    if (searchItemType === 'Rooms' && !selectExpanded) {
      resetItemType('');
      setSelectExpanded(true);
    }
  }, [searchItemType]);

  useEffect(() => {
    clickApplyHandler(selectedValues, 'rooms');
  }, [selectedValues]);

  const searchBarInputFieldRef = useRef<HTMLDivElement>(null);

  const handleApplyValues = (room: Guests, id: number) => {
    setSelectedValues({
      ...selectedValues,
      [id]: room,
    });
  };

  const handleApplyButtonClick = () => {
    setSelectExpanded(false);
  };

  const handleCancelButtonClick = () => {
    setSelectedValues({ 0: { adults: 2, children: 0, childAge: {} } });
    setRoomsNumber(1);
    setSelectExpanded(false);
  };

  const getGuestsNumber = () => {
    let guests = 0;

    for (const val in selectedValues) {
      guests = guests + selectedValues[val].adults + selectedValues[val].children;
    }

    return `Total ${guests} guests`;
  };

  const icon = <img src={person} width='22' alt='person' />;

  const modalHeader: ReactNode = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Edit Rooms</h5>
    </div>
  );

  const modalFooter: ReactNode = (
    <div className='modal-mobile-footer'>
      <Button label='Cancel' isTransparent onClick={handleCancelButtonClick} />
      <Button label='Apply' primary onClick={() => handleApplyButtonClick()} />
    </div>
  );

  const deleteRoom = (num: number) => {
    const newSelectedValues: Record<string, Guests> = {};
    delete selectedValues[num];
    Object.keys(selectedValues).forEach((val, index) => {
      newSelectedValues[String(index)] = selectedValues[val];
    });
    setSelectedValues({
      ...newSelectedValues,
    });
    setRefreshKey(Math.random());
  };

  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            setSelectExpanded(false);
          }}
          className='room-select-overlay'
        ></div>
      )}
      <div className='rooms-select-container'>
        <SearchBarInputField
          title='Edit Rooms'
          value={getGuestsNumber()}
          onClick={() => setSelectExpanded(!selectExpanded)}
          icon={icon}
          ref={searchBarInputFieldRef}
        />
        {selectExpanded && (
          <div className='room-select-desktop-view'>
            <RoomSelector
              roomDelete={deleteRoom}
              updateRoomState={setRoomsNumber}
              handleApply={handleApplyValues}
              roomNumber={roomsNumber}
              selectedValues={selectedValues}
              refreshKey={refreshKey}
              isLoading={isLoading}
              data={data?.available_pax?.result}
            />
          </div>
        )}
      </div>

      <div className='room-select-mobile-view'>
        <Modal
          isOpen={selectExpanded}
          toggle={handleCancelButtonClick}
          header={modalHeader}
          footer={modalFooter}
        >
          <RoomSelector
            roomDelete={deleteRoom}
            selectedValues={selectedValues}
            updateRoomState={setRoomsNumber}
            handleApply={handleApplyValues}
            roomNumber={roomsNumber}
            refreshKey={refreshKey}
            data={data?.available_pax?.result}
          />
        </Modal>
      </div>
    </>
  );
};

export default RoomsSelect;
