import React, { FunctionComponent } from 'react';
import './OfferFlightDetails.scss';
import { FlightDetails } from '../../../HolidaySummary/FlightDetails';
import { Button } from '../../../Button';

interface IFlight {
  date: string;
  duration: number;
  airlineLogo: string;
  airlineCode: string;
  flightType: string;
  departureTime: string;
  departurePlace: string;
  arrivalTime: string;
  arrivalPlace: string;
}

interface OfferFlightDetailsProps {
  date?: string;
  inbound?: IFlight;
  outbound?: IFlight;
}

const OfferFLightDetails: FunctionComponent<OfferFlightDetailsProps> = ({
  date,
  inbound,
  outbound,
}: OfferFlightDetailsProps) => {
  const state = {
    date: 'Thu 24 Jun 2023',
    duration: 5,
    airlineLogo: 'RYANAIR',
    airlineCode: 'RYR2840',
    flightType: 'Outbound',
    departureTime: 'Mon Jan 25 2023 19:23:11',
    departurePlace: 'Tenerife South',
    arrivalTime: 'Mon Jan 25 2023 23:58:11',
    arrivalPlace: 'London Gatwick',
  };

  return (
    <div className={'offer-flight-details-wrapper'}>
      <FlightDetails state={outbound || state} />
      <FlightDetails state={inbound || state} />
      <Button label={'View alternative flights'} primary={false} />
    </div>
  );
};

export default OfferFLightDetails;
