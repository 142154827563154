import React, { FunctionComponent, useState } from 'react';
import { FlightDetails, FlightDetailsState } from '../HolidaySummary/FlightDetails';
import './SelectFlights.scss';
import { Button } from '../Button';
import cn from 'classnames';
import { Pagination } from '../Pagination/Pagination';

interface SelectFlightsProps {
  title: string;
  titleImage: string;
  flightsDetails: FlightDetailsState[][];
  selectedId?: number;
  onClick?: (details: FlightDetailsState, inbounds: FlightDetailsState) => void;
}

const SelectFlights: FunctionComponent<SelectFlightsProps> = ({
  title,
  titleImage,
  flightsDetails = [[], []],
  selectedId,
  onClick,
}: SelectFlightsProps) => {
  const isAirlineSelected = (id: number) => {
    return selectedId === id;
  };

  const [paginationPage, setPaginationPage] = useState(0);
  const by = 3;

  return (
    <div className={'select-flights-wrapper'}>
      <div className='select-flights-wrapper--header'>
        <img src={titleImage} alt='' />
        <span>{title}</span>
      </div>
      <div className='select-flights-wrapper--body'>
        <div className='select-flights-list'>
          {flightsDetails[0]
            .slice(paginationPage * 3 || 0, paginationPage * 3 + by)
            .map((values, index) => {
              return (
                <div
                  className={cn('select-flights-list--item', {
                    'selected-airline': isAirlineSelected(values.id || 0),
                  })}
                  key={values.id + 'select-flights-row'}
                >
                  <div className='airline-wrapper'>
                    <div className={'airline-item-wrapper'} key={index}>
                      <FlightDetails state={values} />
                      <FlightDetails state={flightsDetails[1][index]} />
                    </div>
                  </div>
                  <div
                    className={cn('airline-order-section', {
                      'selected-airline': isAirlineSelected(values.id || 0),
                    })}
                  >
                    <div className='airline-order-section--save'>
                      <span className={'save-price'}>{`+ £${values.price}`}</span>
                      <span className={'save-label'}>{'per person'}</span>
                    </div>

                    <Button
                      label={isAirlineSelected(values.id || 0) ? 'Selected' : 'Select'}
                      isTransparent={true}
                      onClick={() => {
                        if (onClick) onClick(values || {}, values || {});
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        {flightsDetails[0].length > 3 && (
          <div className='select-flights-align-center'>
            <Pagination
              by={by}
              count={flightsDetails[0].length}
              onChange={(val) => {
                setPaginationPage(val);
              }}
              keyValue='pagination-flights'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFlights;
