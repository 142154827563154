import React, { FunctionComponent, useEffect, useState } from 'react';
import './FiltersSidebar.scss';
import { Toggle } from '../Toggle/Toggle';
import { FilterSection } from '../FilterSection/FilterSection';
import { Modal } from '../Modal';
import { Button } from '../Button/Button';
import { camelCase, filter } from 'lodash';

interface FiltersSidebarProps {
  isModalOpen: boolean;
  filtersData: any;
  selectedValues: any;
  isTotalPriceFlag: boolean;
  onFiltersChange: (changedFilters: any) => void;
  onTotalPriceChange: (val: any) => void;
  toggleModal?: (val: boolean) => void;
}

export const FiltersSidebar: FunctionComponent<FiltersSidebarProps> = ({
  isModalOpen = false,
  filtersData,
  onFiltersChange,
  selectedValues,
  isTotalPriceFlag,
  onTotalPriceChange,
  toggleModal,
}: FiltersSidebarProps) => {
  const [state, setState] = useState<any>({
    boardType: [
      {
        title: 'Room Only',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Self Catering',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Bed & Breakfast',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Half Board',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Full Board',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'All Inclusive',
        numberItems: '',
        isChecked: false,
      },
    ],
    ratings: [
      {
        title: 5,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 4,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 3,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 2,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 1,
        numberItems: '',
        isChecked: false,
      },
    ],
    facilities: [
      {
        title: 'Close to Beach',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Couples',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Family',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Golfing',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Kids club',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Water park',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Young & Lovely',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Water Slides',
        numberItems: '',
        isChecked: false,
      },
      {
        title: 'Beach Front',
        numberItems: '',
        isChecked: false,
      },
    ],
    tripRatings: [
      {
        title: 5,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 4.5,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 4,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 3.5,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 3,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 2.5,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 2,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 1.5,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 1,
        numberItems: '',
        isChecked: false,
      },
      {
        title: 0.5,
        numberItems: '',
        isChecked: false,
      },
    ],
  });

  const updateState = (updatedData: any) => {
    const combineState = { ...state, ...updatedData };
    setState(combineState);
  };

  const addActualNumberItemsAndInitialChecked = (type: any) => {
    const mapActualCount = (tempItem: any, index: any) => {
      let temp = null;
      switch (type) {
        case 'boardType':
        case 'facilities':
          return filtersData[type][index]?.count || 0;
        case 'ratings':
        case 'tripRatings':
          temp = filtersData[type].find(
            (filterDataItem: any) => tempItem.title === filterDataItem.result,
          );
          return (temp && temp.count) || 0;
      }
    };

    const mapInitialCheckedValues = (tempItem: any, type: string) => {
      const tempTitle = tempItem.title;
      if (selectedValues && selectedValues[type]) {
        const isChecked = selectedValues[type].find((i: any) => i.title === tempTitle);
        return !!isChecked;
      }

      return false;
    };

    return {
      keyName: type,
      [type]: state[type].map((tempItem: any, index: number) => {
        const actualCount = mapActualCount(tempItem, index);
        const isChecked = mapInitialCheckedValues(tempItem, type);

        return {
          title: tempItem.title,
          isChecked: isChecked,
          numberItems: actualCount,
        };
      }),
    };
  };

  useEffect(() => {
    const typesMapped: any = {};
    Object.keys(filtersData).forEach((filterTitle: string) => {
      const t: any = addActualNumberItemsAndInitialChecked(filterTitle);
      typesMapped[t.keyName] = t[t.keyName];
    });
    if (typesMapped && Object.keys(typesMapped.length > 0)) {
      updateState(typesMapped);
    }
  }, [filtersData]);

  const starsItems = [
    {
      title: '',
      numberItems: '123',
      isChecked: false,
    },
    {
      title: '',
      numberItems: '87',
      isChecked: false,
    },
    {
      title: '',
      numberItems: '32',
      isChecked: false,
    },
    {
      title: '',
      numberItems: '',
      isChecked: false,
    },
    {
      title: '',
      numberItems: '14',
      isChecked: false,
    },
  ];

  const openFilters = () => {
    return true;
  };

  const onToggleChange = () => {
    return true;
  };

  const modalFooter = () => {
    return (
      <div className={'modal-footer-wraper'}>
        <Button
          label={'Cancel'}
          onClick={() => {
            if (toggleModal) toggleModal(false);
          }}
          isTransparent={true}
          size={'medium'}
        ></Button>
        <Button
          label={'Apply'}
          onClick={() => {
            if (toggleModal) toggleModal(false);
          }}
          primary={true}
          size={'medium'}
        ></Button>
      </div>
    );
  };

  const getChangedFilters = (filters: any) => {
    const tempObj: any = {};
    Object.keys(filters).map((key: any) => {
      tempObj[key] = filters[key].filter((i: any) => i.isChecked);
      return tempObj[key];
    });
    return tempObj;
  };

  const filterChanged = (type: any, item: any) => {
    const filterName = camelCase(type);

    state[filterName] = state[filterName].map((tempItem: any) => {
      const isSelectedItem = tempItem.title === item.title;
      if (isSelectedItem) {
        tempItem.isChecked = !tempItem.isChecked;
      }
      return tempItem;
    });

    setState(state);

    onFiltersChange(getChangedFilters(state));
  };

  return (
    <>
      <div className='filters-wrapper-desktop'>
        <div className='filter-section'>
          <Toggle
            onToggleChange={(v) => onTotalPriceChange(v)}
            value={isTotalPriceFlag}
            size={'large'}
            firstLabel={'Price Per Person'}
            secondLabel={'Total Price'}
          />
        </div>

        <div className='filter-section'>
          <FilterSection
            title={'Board Type'}
            type={'string'}
            items={state.boardType}
            onChange={(type, item) => filterChanged(type, item)}
          />
        </div>

        <div className='filter-section'>
          <FilterSection
            title={'Star Rating'}
            type={'stars'}
            items={state.ratings}
            onChange={(type, item) => filterChanged(type, item)}
          />
        </div>

        <div className='filter-section'>
          <FilterSection
            title={'Tripadvisor'}
            type={'circle'}
            items={state.tripRatings}
            onChange={(type, item) => filterChanged(type, item)}
          />
        </div>

        <div className='filter-section'>
          <FilterSection
            title={'Facilities'}
            type={'string'}
            items={state.facilities}
            onChange={(type, item) => filterChanged('facilities', item)}
          />
        </div>
      </div>

      <Modal
        footer={modalFooter()}
        mobileHeading={'Sort & Filter'}
        isOpen={isModalOpen}
        toggle={() => {
          if (toggleModal) toggleModal(false);
        }}
      >
        <div className='filters-wrapper-mobile'>
          <div className='filter-section'>
            <Toggle
              onToggleChange={(v) => onTotalPriceChange(v)}
              value={isTotalPriceFlag}
              size={'small'}
              firstLabel={'Price Per Person'}
              secondLabel={'Total Price'}
            />
          </div>

          <div className='filter-section'>
            <FilterSection
              title={'Board Type'}
              type={'string'}
              items={state.boardType}
              onChange={(type, item) => filterChanged(type, item)}
            />
          </div>

          <div className='filter-section'>
            <FilterSection
              title={'Star Rating'}
              type={'stars'}
              items={state.ratings}
              onChange={(type, item) => filterChanged(type, item)}
            />
          </div>

          <div className='filter-section'>
            <FilterSection
              title={'Tripadvisor'}
              type={'circle'}
              items={state.tripRatings}
              onChange={(type, item) => filterChanged(type, item)}
            />
          </div>

          <div className='filter-section'>
            <FilterSection
              title={'Facilities'}
              type={'string'}
              items={state.facilities}
              onChange={(type, item) => filterChanged(type, item)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
