import { FunctionComponent, useEffect, useState } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { noop } from 'lodash';
import './ExtrasPage.scss';
import CarHire from '../../components/CarHire/CarHire';
import { CarHireDummy } from './CarHireDummy';
import { SelectTransfer } from '../../components/SelectTransfers/SelectTransfer';
import { SelectTransferDummy } from './SelectTransferDummy';
import { LuggageDummy } from './LuggageDummy';
import { Luggage } from '../../components/Luggage/Luggage';
import { Basket } from '../../components/Basket/Basket';
import { observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { useNavigate } from 'react-router-dom';
import { ProgressBarDefault } from '../../components/ProgressBar/ProgressBarDefaults';
import { Button } from '../../components/Button';
import { Loader } from '../../components/Loader/Loader';
import Logo from '../../components/assets/logo.svg';
import {
  addDummyLeadPassenger,
  addDummyPassenger,
  addProduct,
  checkProductDetails,
  getBasket,
  getBasketFlightExtras,
  getSelectTransfer,
  removeProduct,
} from '../../hooks/useRequest';
import { IBasketFlights } from '../../hooks/Interfaces';
import { ILuggage } from '../../components/Luggage/Interfaces';
import { stepDirection } from '../../components/Stepper/Interfaces';

interface HomePageProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

const ExtrasPage: FunctionComponent<HomePageProps> = ({ onLogin = noop, openMenu = noop }) => {
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);
  const [productHotelLoaded, setProductHotelLoaded] = useState<boolean>(false);
  const [productFlightLoaded, setProductFlightLoaded] = useState<boolean>(false);

  const [flightsXHR, setFlightsXHR] = useState<boolean>(false);
  const [productHotelData, setProductHotelData] = useState<any>(true);
  const [productLuggageData, setProductLuggageData] = useState<ILuggage>({
    title: 'Luggage',
    description: '1 x Small carry on bag per person included',
    options: [],
  });

  const [everythingLoaded, setEverythingLoaded] = useState<boolean>(false);

  const {
    RootStore: {
      sessionStore: { setUserSession, userSession },
      basketStore: { basket, setBasket },
      holidayStore: { holiday },
    },
  } = useStores() as { RootStore: RootStore };
  const navigate = useNavigate();

  useEffect(() => {
    if (!userSession.sessionId) {
      navigate('/');
    }
  }, [userSession?.sessionId]);

  useEffect(() => {
    if (!flightsXHR && userSession.sessionId && basket.basketData) {
      const products = basket.basketData?.basket.result.products || [];
      const sess = userSession.sessionId || '';

      const addDummyLeadAndFetchProductData = async () => {
        await addDummyLeadPassenger(sess);
        await addDummyPassenger(sess);

        products.forEach(async (product) => {
          await checkProductDetails(sess, product.id, product.product_type.id);

          if (product.product_type.id === 1) {
            const flightDetails = {
              arrival: basket.outbounds[0].destination.arrivedDate,
              returning: basket.inbounds[0].destination.arrivedDate,
              accommodationId: 21223,
              arrivalAirportIata: 'ibz',
              returnAirportIata: 'man',
              outboundFlightNumber: basket.outbounds[0].destination.flight.flightNumber || '',
              inboundFlightNumber: basket.inbounds[0].destination.flight.flightNumber || '',
            };
            const transferResults = await getSelectTransfer(sess, flightDetails);

            const luggageResults: IBasketFlights = await getBasketFlightExtras(sess, product.id);

            const luggage: ILuggage = {
              title: 'Luggage',
              description: '1 x Small carry on bag per person included',
              options: [],
            };
            luggageResults.basket_flight.result?.extras?.forEach((extra) => {
              /**
               * Return only extras with code lug or lus as we are popoulating luggage and this is code that retains luggage information.
               */
              if (extra.code === 'LUS' || extra.code === 'LUG') {
                luggage.options.push({
                  id: extra.id || 0,
                  title: extra.description || '',
                  weight: 0,
                  maxValue: extra.quantity_available,
                  minValue: 0,
                  price: extra.price,
                  productId: extra.product_type?.id,
                });
              }
            });
            setProductFlightLoaded(true);
            // temp solution for now load transfers information.
            setProductHotelLoaded(true);
            setProductLuggageData(luggage);
          }
        });
      };

      // sets a dummy lead passenger we should do a check to see if a passenger exists before adding ?
      addDummyLeadAndFetchProductData();
      setFlightsXHR(true);
    }
  }, [flightsXHR, userSession?.sessionId]);

  ProgressBarDefault.forEach((progress) => {
    progress.isActive = false;
  });
  ProgressBarDefault[1].isActive = true;

  let fadeOut = false;
  if (productHotelLoaded && productFlightLoaded && !everythingLoaded) {
    fadeOut = true;
    setTimeout(() => setEverythingLoaded(true), 1000);
  }

  if (!productHotelLoaded || !productFlightLoaded || !everythingLoaded) {
    return (
      <div className={`loader-page-container ${fadeOut ? 'loader-page-fadeout' : ''}`}>
        <img className='loader-page-logo' src={Logo} height={100} />
        <Loader
          gridTemplate='loader-grid-auto'
          disable={{ extras: false, rooms: true, flights: true }}
          loading={{
            extras: productHotelLoaded && productFlightLoaded,
          }}
        />
      </div>
    );
  }
  const combineBasket = { ...basket, ...(holiday || {}) };

  return (
    <BaseLayout
      onLogin={onLogin}
      openMenu={openMenu}
      progressBar={ProgressBarDefault}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
    >
      <div className='extras-page-container'>
        <div className='extras-page-col extras-page-col-basket extras-page-col-basket-mobile'>
          {basket && <Basket {...combineBasket} showMoreButton={true} />}
        </div>
        <div className='extras-page-col'>
          {productLuggageData.options.length > 0 && (
            <Luggage
              onChange={(luggage, direction) => {
                const asyncXHRAdd = async () => {
                  if (userSession.sessionId && luggage.productId) {
                    await addProduct(userSession.sessionId, luggage.id, luggage.productId);
                    const basketResponse = await getBasket(userSession.sessionId);
                    setBasket({ ...basket, basketData: basketResponse });
                  }
                };
                const asyncXHRDown = async () => {
                  if (userSession.sessionId && luggage.productId) {
                    await removeProduct(userSession.sessionId, luggage.id, luggage.productId);
                    const basketResponse = await getBasket(userSession.sessionId);
                    setBasket({ ...basket, basketData: basketResponse });
                  }
                };
                if (direction === stepDirection.UP) {
                  asyncXHRAdd();
                } else if (direction === stepDirection.DOWN) {
                  asyncXHRDown();
                }
              }}
              {...productLuggageData}
            />
          )}
          <div className='extras-page-divider'></div>
          <SelectTransfer {...SelectTransferDummy} />
          <div className='extras-page-divider'></div>
          {/* Car hire to be develop on in the future leaving a reference to it here */}
          {/* <CarHire {...CarHireDummy} /> */}
          <div className='extras-page-divider'></div>
          <div className='extras-page-continue-mobile'>
            <Button
              label={'Payment'}
              primary={true}
              isTransparent={false}
              onClick={() => {
                navigate('/payment');
              }}
            />
          </div>
        </div>
        <div className='extras-page-col extras-page-col-basket'>
          {basket && <Basket {...combineBasket} showMoreButton={false} />}
          <div className='extras-page-divider' />
          <div>
            <Button
              label={'Passengers'}
              primary={true}
              isTransparent={false}
              onClick={() => {
                navigate('/passengers');
              }}
            />
          </div>
          <div className='extras-page-divider' />
        </div>
      </div>
    </BaseLayout>
  );
};

export default observer(ExtrasPage);
