import React, { useEffect, useState } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import './HolidayPage.scss';
import { noop } from 'lodash';
import moment from 'moment';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { PageLoader } from '../../components/PageLoader';
import {
  AccommodationOffersByMonth,
  generateRoomString,
  useOffersById,
} from '../../hooks/useRequest';
import { PriceDatepicker } from '../../components/PriceDatepicker';
import { HolidaySummary } from '../../components/HolidaySummary';
import { HolidayDetails } from '../../components/HolidayDetails';
import beach from '../../components/assets/beach.svg';
import hotel from '../../components/assets/hotel.svg';
import { SearchBar } from '../../components/SearchBar';
import { Toggle } from '../../components/Toggle/Toggle';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { IImage } from '../../stores/HolidayStore';
import { Image } from '../../components/ImageCarousel';
import { getDepartureDateFromParams } from '../SearchResultsPage/SearchResultsPage';
import { ModalWithTabs } from '../../components/ModalWithTabs';
import { useModal } from '../../hooks/useModal';

interface AccommodationOffers {
  id: string;
  flights: [
    {
      departing: string;
    },
  ];
  price: number;
  price_per_person: number;
}

interface DatepickerDataStructure {
  [key: string]: number;
}

interface DatepickerIdsStructure {
  [key: string]: string;
}

export function HolidayPage() {
  const {
    RootStore: {
      holidayStore: { setHoliday },
      basketStore: { setDefault },
    },
  } = useStores() as { RootStore: RootStore };

  const params: any = useParams();
  const [searchParams] = useSearchParams();
  const [selectedId, setSelectedId] = useState<string>(params.id);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState<Date>(new Date());
  const [isTotalPrice, setIsTotalPrice] = useState<boolean>(true);
  const parsedSearchParams = JSON.parse(Object.fromEntries([...searchParams]).params);
  const [selectedSearchParams, setSelectedSearchParams] = useState<any>(parsedSearchParams || null);

  const { data, isSuccess } = useOffersById(
    selectedId,
    JSON.stringify(generateRoomString(selectedSearchParams.rooms)),
  );

  const { result } = (data && data.offer_by_id) || {};
  const { accommodation, flights } = result || {};
  const [accommodationId, setAccommodationId] = useState<number>(0);
  const [images, setImages] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<number>();

  const [accommodationResponse, setAccommodationResponse] = useState<AccommodationOffers[]>([]);
  const [datepickerData, setDatepickerData] = useState<any>({
    totalPrices: {},
    prices: {},
    ids: {},
  });

  // save an original copy of accomidation that doesn't reload.
  const [accommodationOriginal, setAccommodationOriginal] = useState<any>(false);
  const [isOpen, toggle] = useModal(false);

  useEffect(() => {
    if (!accommodationId && accommodation) {
      setAccommodationId(accommodation.id);
      setAccommodationOriginal(accommodation);
    }
  }, [accommodation]);

  const navigate = useNavigate();

  useEffect(() => {
    const loadMonth = async () => {
      const accommodationOffersByMonth = await AccommodationOffersByMonth(
        accommodationId,
        moment(selectedMonthAndYear).format('YYYY-MM-DD HH:MM'),
        selectedSearchParams,
      );
      const response: AccommodationOffers[] =
        (accommodationOffersByMonth &&
          accommodationOffersByMonth.accommodation_offers_by_month.result) ||
        [];

      const prices = generateDatepickerData(response);

      setAccommodationResponse(response);
      const combinedTotal = { ...(datepickerData.totalPrices || {}), ...prices.totalPrices };
      const combinedPrices = { ...(datepickerData.prices || {}), ...prices.prices };
      const combinedIds = { ...(datepickerData.ids || {}), ...prices.ids };
      setDatepickerData({ totalPrices: combinedTotal, prices: combinedPrices, ids: combinedIds });

      if (selectedDate) {
        const formattedString = moment(selectedDate).format('YYYY-MM-DD');
        if (prices.ids[formattedString]) {
          setSelectedDate(new Date(formattedString));
          setSelectedId(prices.ids[formattedString]);
        }
      }
    };

    if (accommodationId !== 0) {
      loadMonth();
    }
  }, [accommodationId, selectedMonthAndYear, selectedSearchParams]);

  useEffect(() => {
    if (accommodationResponse && accommodationResponse.length > 0) {
      const prices = generateDatepickerData(accommodationResponse);

      const combinedTotal = { ...(datepickerData.totalPrices || {}), ...prices.totalPrices };
      const combinedPrices = { ...(datepickerData.prices || {}), ...prices.prices };
      const combinedIds = { ...(datepickerData.ids || {}), ...prices.ids };
      setDatepickerData({ totalPrices: combinedTotal, prices: combinedPrices, ids: combinedIds });
    }
  }, [isTotalPrice]);

  const generateDatepickerData = (offers: any) => {
    const datepickerStructure: {
      totalPrices: DatepickerDataStructure;
      prices: DatepickerDataStructure;
      ids: DatepickerIdsStructure;
    } = {
      prices: {},
      ids: {},
      totalPrices: {},
    };
    offers &&
      offers.forEach((offer: AccommodationOffers) => {
        const key: string = offer.flights[0].departing.split(' ')[0];

        datepickerStructure.totalPrices[key] = Number(offer['price'].toFixed(0));
        datepickerStructure.prices[key] = Number(offer['price_per_person'].toFixed(0));
        datepickerStructure.ids[key] = offer.id;
      });

    return datepickerStructure;
  };

  const onHolidaySelect = () => {
    const images = data.offer_by_id.result?.accommodation.images || [];
    const mappedToImage: IImage[] = images.map((image: Image) => {
      return {
        url: image.url,
        alt: '',
      };
    });

    setDefault();
    setHoliday({
      destination: {
        title: accommodation.name || '',
        subtitle: accommodation.address || '',
        breakfast: true,
        roomType: '',
        adults: 2,
        children: 0,
        images: mappedToImage,
      },
      rating: {
        tripAdvisor: accommodation.trip_advisor_rating,
        holidayPeople: parseInt(accommodation.rating),
        amount: 2300,
      },
      people: {
        adults: 2,
        children: 0,
      },
      accommodation: {
        id: accommodationId,
        date: moment(selectedDate).format('YYYY-MM-DD 00:00'),
        duration: selectedSearchParams?.duration?.value || 7,
        rooms: selectedSearchParams?.rooms || ['2'],
        arrival: flights[0]?.arrival_airport.code,
        departure: flights[0]?.departure_airport.code,
      },
    });

    const formattedString = moment(selectedDate).format('YYYY-MM-DD');
    if (datepickerData.ids[formattedString]) {
      navigate('/select');
    }
  };

  const toggleChanged = (val: boolean) => {
    setIsTotalPrice(val);
  };

  const holidayPageState = {
    hotelName: (accommodationOriginal && accommodationOriginal.name) || '',
    hotelLocation: (accommodationOriginal && accommodationOriginal.address) || '',
    rating: (accommodationOriginal && +accommodationOriginal.rating) || 3,
    tripadvisorRating: (accommodationOriginal && +accommodationOriginal.trip_advisor_rating) || 2,
    tripadvisorReviews: '1,088 reviews',
    hotelInformationLink: '#',
    mapLink: '#',
    weatherLink: '#',
    name: '',
    location: 'Canaries, Tenerife, Los Cristianos',
    date: '24 Jun 2023 - 7 nights',
    meals: 'Bed & Breakfast',
    airport: 'London Gatwick - Flight Details',
    price: 689,
    salePrice: '£524 pp',
    link: '#',
    datePrices: datepickerData,
    headerTitle: 'Holiday Summary',
    hotelBenefits: 'Bed & Breakfast',
    hotelMembers:
      (flights && `${flights[0].adults || 0} Adults, ${flights[0].children || 0} Children`) ||
      '2 Adults, 0 Children',
    oldPrice: (result && result.original_price.toFixed(2)) || null,
    pricePerPerson: (result && result.price_per_person.toFixed(2)) || null,
    totalPrice: (result && result.price.toFixed(2)) || null,
    flightDetailsState: {
      date: moment(flights && flights[0].departing).format('ddd DD MMM YYYY') || '',
      duration: (result && result.duration) || {},
      airlineLogo: flights?.[0].airline || '',
      airlineCode: (flights && flights[0].number) || 'RYR2840',
      flightType: 'Outbound',
      departureTime: (flights && flights[0].departing) || '07:00',
      departurePlace: (flights && flights[0].departure_airport.name) || 'Tenerife South',
      arrivalTime: (flights && flights[0].arrival) || '11:25',
      arrivalPlace: (flights && flights[0].arrival_airport.name) || 'London Gatwick',
    },
    flightDetailsStateReturn: {
      date: moment(flights && flights[0].return_departing).format('ddd DD MMM YYYY') || '',
      duration: 0,
      airlineLogo: flights?.[0].airline || '',
      airlineCode: (flights && flights[0].return_number) || 'RYR2840',
      flightType: 'Inbound',
      departureTime: (flights && flights[0].return_departing) || '07:00',
      departurePlace: (flights && flights[0].arrival_airport.name) || 'Tenerife South',
      arrivalTime: (flights && flights[0].return_arrival) || '11:25',
      arrivalPlace: (flights && flights[0].departure_airport.name) || 'London Gatwick',
    },
    childrenOptions: [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
    ],
    onHolidaySelect: () => onHolidaySelect(),
  };

  const selectedNewDate = (date: Date | null | undefined | [Date | null, Date | null]) => {
    let formattedString = '';
    if (Array.isArray(date)) {
      formattedString = moment(date[0]).format('YYYY-MM-DD');
    } else {
      formattedString = moment(date).format('YYYY-MM-DD');
    }

    if (datepickerData.ids[formattedString]) {
      setSelectedDate(new Date(formattedString));
      setSelectedId(datepickerData.ids[formattedString]);
    }
  };

  const onSearchParamsChange = (params: any) => {
    setDatepickerData({ totalPrices: {}, prices: {}, ids: {} });
    setSelectedSearchParams({ ...params });
  };

  // stop the images from reloading;
  useEffect(() => {
    if (images && images.length === 0) {
      const img = data?.offer_by_id?.result?.accommodation?.images
        ? data?.offer_by_id?.result?.accommodation?.images
        : [];
      setImages(img);
    }

    if (flights) {
      setSelectedDate(new Date(flights[0].departing));
    }
  }, [data]);

  const onLinkClickHandler = (tab: number) => {
    setActiveTab(tab);

    toggle();
  };

  return (
    <BaseLayout onLogin={noop} openMenu={noop}>
      {/* <div style={{ display: isLoading ? 'block' : 'none' }}> */}
      {/* <PageLoader /> */}
      {/* </div> */}
      <div className='holiday-page-layout'>
        <Tabs>
          <TabList>
            <Tab>
              <img src={beach} alt='' />
              <span>Flight & Hotel</span>
            </Tab>
            {/* <Tab>
              <img src={hotel} alt='' />
              <span>Hotel Only</span>
            </Tab> */}
          </TabList>

          <TabPanel>
            <div className={'holiday-page-wrapper'}>
              <div className='card-section'>
                <HolidayDetails
                  hotelName={holidayPageState.hotelName}
                  hotelLocation={holidayPageState.hotelLocation}
                  rating={holidayPageState.rating}
                  tripadvisorRating={holidayPageState.tripadvisorRating}
                  tripadvisorReviews={holidayPageState.tripadvisorReviews}
                  images={images}
                  onLinkClick={onLinkClickHandler}
                />
              </div>
              <div className='main-wrapper'>
                <div className='component-wrapper'>
                  <p className={'component-wrapper--title'}>Refine your holiday</p>
                  <div className={'component-wrapper--component'}>
                    <SearchBar
                      isBoardType={true}
                      onSearchParamsChange={(params) => onSearchParamsChange(params)}
                    />
                  </div>
                </div>
                <div className='component-wrapper'>
                  <p className={'component-wrapper--title'}>Price Calendar</p>
                  <div className={'component-wrapper--component'}>
                    <div className='holiday-price-datepicker'>
                      <PriceDatepicker
                        value={selectedDate}
                        datePrices={
                          isTotalPrice
                            ? holidayPageState.datePrices.totalPrices
                            : holidayPageState.datePrices.prices
                        }
                        onChange={(val) => selectedNewDate(val)}
                        onMonthChange={(val) => {
                          setSelectedMonthAndYear(val);
                        }}
                      />
                      <Toggle
                        firstLabel={'Price Per Person'}
                        secondLabel={'Total Price'}
                        value={isTotalPrice}
                        onToggleChange={(val: boolean) => toggleChanged(val)}
                      />
                      <div className='info-section'>
                        <span className={'cheapest'}>= Cheapest Price</span>
                        <span className='selected'>= Selected</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='summary-section'>
                <HolidaySummary
                  headerTitle={holidayPageState.headerTitle}
                  hotelName={holidayPageState.hotelName}
                  hotelBenefits={holidayPageState.hotelBenefits}
                  hotelMembers={holidayPageState.hotelMembers}
                  oldPrice={holidayPageState.oldPrice}
                  pricePerPerson={holidayPageState.pricePerPerson}
                  totalPrice={holidayPageState.totalPrice}
                  childrenOptions={holidayPageState.childrenOptions}
                  flightDetailsState={holidayPageState.flightDetailsState}
                  flightDetailsStateReturn={holidayPageState.flightDetailsStateReturn}
                  onHolidaySelect={holidayPageState.onHolidaySelect}
                />
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel> */}
          {/*  <p>Content for Hotel Only</p>*/}
          {/* </TabPanel> */}
        </Tabs>
      </div>
      {isSuccess ? (
        <ModalWithTabs
          isOpen={isOpen}
          toggle={toggle}
          activeTabNumber={activeTab}
          center={{
            lat: parseFloat(data?.offer_by_id.result.accommodation.lat),
            lng: parseFloat(data?.offer_by_id.result.accommodation.long),
          }}
          places={[
            {
              id: selectedId,
              title: holidayPageState.hotelName,
              position: {
                lat: parseFloat(data?.offer_by_id.result.accommodation.lat),
                lng: parseFloat(data?.offer_by_id.result.accommodation.long),
              },
            },
          ]}
          hotelName={holidayPageState.hotelName}
          hotelLocation={holidayPageState.hotelLocation}
          rating={holidayPageState.rating}
          tripadvisorRating={holidayPageState.tripadvisorRating}
          tripadvisorReviews={holidayPageState.tripadvisorReviews}
          price={holidayPageState.price}
          images={images}
        />
      ) : null}
    </BaseLayout>
  );
}

export default observer(HolidayPage);
