import React, { FC, useState } from 'react';
import { IStepper, stepDirection } from './Interfaces';
import './Stepper.scss';

export const Stepper: FC<IStepper> = ({
  defaultValue = 0,
  maxValue = 10,
  minValue = 0,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (val > maxValue) {
      setValue(maxValue);
      if (onChange) onChange(maxValue);
    } else if (val < minValue) {
      setValue(minValue);
      if (onChange) onChange(minValue);
    } else {
      setValue(val);
      if (onChange) onChange(val);
    }
  };

  const stepUp = () => {
    const val = value + 1;
    if (val > maxValue) {
      setValue(maxValue);
      if (onChange) onChange(maxValue, stepDirection.UP);
    } else {
      setValue(val);
      if (onChange) onChange(val, stepDirection.UP);
    }
  };

  const stepDown = () => {
    const val = value - 1;
    if (val < minValue) {
      setValue(minValue);
      if (onChange) onChange(minValue, stepDirection.DOWN);
    } else {
      setValue(val);
      if (onChange) onChange(val, stepDirection.DOWN);
    }
  };

  return (
    <div>
      <button
        disabled={minValue === value}
        onClick={stepDown}
        className='stepper-button stepper-left-button'
      >
        {'-'}
      </button>
      <input
        className='stepper-input'
        type={'number'}
        min={minValue}
        max={maxValue}
        onChange={onChangeInternal}
        value={value}
        disabled={true}
      />
      <button
        disabled={maxValue === value}
        onClick={stepUp}
        className='stepper-button stepper-right-button'
      >
        {'+'}
      </button>
    </div>
  );
};
