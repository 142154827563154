import React, { FunctionComponent, useState } from 'react';
import { Board } from './Board';

import './SelectBoard.scss';

export interface Board {
  type: string;
  price?: number;
  id: number;
}

export interface SelectBoardProps {
  boards: Board[];
  onClick?: (board: Record<number, boolean>) => void;
}

const SelectBoard: FunctionComponent<SelectBoardProps> = ({
  boards,
  onClick,
}: SelectBoardProps) => {
  const [selectedBoard, setSelectedBoard] = useState<Record<number, boolean>>({});

  const handleClick = (i: number, board: Board) => {
    selectedBoard[i] = !selectedBoard[i];
    setSelectedBoard({ ...selectedBoard });
    if (onClick) onClick(selectedBoard);
  };

  const getBoardPrice = (price: number) => {
    return `£${price} pp`;
  };

  return (
    <div className='select-board-wrapper'>
      {boards.map((board: Board, i: number) => {
        return (
          <Board
            key={board.type}
            type={board.type}
            priceInfo={board.price ? getBoardPrice(board.price) : undefined}
            onClick={() => handleClick(board.id, board)}
            selected={selectedBoard[board.id]}
          />
        );
      })}
    </div>
  );
};

export default SelectBoard;
