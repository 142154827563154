import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import './HolidayCardPrice.scss'
import { Button } from '../../Button/Button'
import edit from '../../assets/edit.svg'
import { Link } from 'react-router-dom'
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { PriceDatepicker } from '../../PriceDatepicker';

interface HolidayCardProps {
  price: number;
  discount: number;
  id: string;
  searchParams: string;
  isTotalPriceFlag?: boolean;
}

const HolidayCardPrice: FunctionComponent<HolidayCardProps> = ({
  price,
  discount,
  id,
  searchParams,
  isTotalPriceFlag
}: HolidayCardProps) => {
  const salePrice = `£${(price + discount).toFixed(2)} ${!isTotalPriceFlag ? 'pp' : ''}`

  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false)

  const outsideClickCallback = () => {
    setIsCalendarVisible(false);
  }

  const calendarRef = useRef<HTMLDivElement>(null)
  const priceWrapperRef = useRef<HTMLDivElement>(null)
  useOutsideClick([calendarRef, priceWrapperRef], outsideClickCallback)

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }
  }, [calendarRef.current]);

  const onPriceChanged = (value: any) => {
    return true;
  }

  return (
    <div className='price-section'>
      <div ref={priceWrapperRef} onClick={() => setIsCalendarVisible(!isCalendarVisible)} className='price-wrapper'>
        {discount ? <span className='price'>{`£${price.toFixed(2)} pp`}</span> : null}
        <span className='sale-price'>
          {salePrice}
          <img src={edit} alt=''/>
        </span>
      </div>
      {isCalendarVisible && <div className={'holiday-card-price-calendar'} ref={calendarRef}><PriceDatepicker
        onChange={(value) => onPriceChanged(value)}/></div>}

      <Link to={`/holiday/${id}?params=${encodeURIComponent(JSON.stringify(searchParams))}`}>
        <Button size={'medium'} primary={true} label={'View deal'}/>
      </Link>
    </div>
  )
}

export default HolidayCardPrice
