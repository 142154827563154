import React, { FunctionComponent } from 'react';
import './HolidaySummary.scss';
import { Button } from '../Button/Button';
import FlightDetails, { FlightDetailsState } from './FlightDetails/FlightDetails';
import { CustomDropdownIndicator } from '../Room/CustomDropdownIndicator';
import Select, { StylesConfig } from 'react-select';
import { Option } from '../Autocomplete';
import moment from 'moment';

interface HolidaySummaryProps {
  headerTitle: string;
  hotelName: string;
  hotelBenefits: string;
  hotelMembers: string;
  oldPrice: string;
  pricePerPerson: string;
  totalPrice: string;
  childrenOptions: Option[];
  flightDetailsState: FlightDetailsState;
  flightDetailsStateReturn: FlightDetailsState;
  onChildrenSelect?: () => void;
  onHolidaySelect: () => void;
}

const HolidaySummary: FunctionComponent<HolidaySummaryProps> = ({
  headerTitle,
  hotelName,
  hotelBenefits,
  hotelMembers,
  oldPrice,
  pricePerPerson,
  totalPrice,
  childrenOptions,
  onChildrenSelect,
  flightDetailsState,
  onHolidaySelect,
  flightDetailsStateReturn,
}: HolidaySummaryProps) => {
  const customStyles: StylesConfig = {
    container: (base) => ({
      ...base,
      marginTop: '16px',
    }),
    control: (base) => ({
      ...base,
      borderColor: '#99b4ca',
      padding: '10px 15px',
    }),
    input: (base) => ({
      ...base,
      color: '#01447B',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#01447B',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      marginTop: 0,
      marginBottom: 0,
      backgroundColor: 'white',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      borderColor: 'white',
    }),
    menu: (base) => ({
      ...base,
      borderColor: '#99b4ca',
    }),
  };

  const generateTotalHolidayTime = () => {
    const startDate = flightDetailsState.arrivalTime;
    const { duration } = flightDetailsState || {};
    const formattedStartDate = moment(startDate).format('ddd DD MMM YYYY');
    const formattedEndDate = moment(startDate).add(duration, 'days').format('ddd DD MMM YYYY');
    const nightLabel = duration > 1 ? 'nights' : 'night';
    return `${formattedStartDate} - ${formattedEndDate} (${duration} ${nightLabel})`;
  };

  return (
    <>
      <div className='holiday-summary-wrapper'>
        <div className='holiday-summary--header'>
          <span className='holiday-summary--header__span'>{headerTitle}</span>
        </div>
        <div className='holiday-summary-body'>
          <div className='top-section'>
            <div className='top-section--title'>{hotelName}</div>
            <div className='top-section--subtitle'>
              <p>{generateTotalHolidayTime()}</p>
              <p>{hotelBenefits}</p>
              <p>{hotelMembers}</p>
            </div>
          </div>
          <div className='middle-section'>
            <FlightDetails state={flightDetailsState} />
            <FlightDetails state={flightDetailsStateReturn} />
          </div>
          <div className='bottom-section'>
            <div className='bottom-section--price-info'>
              <span className='old-price'>Was {oldPrice}</span>
              <div className={'price-per-person'}>
                <span className={'title'}>Price per person</span>
                <span className={'price'}>{pricePerPerson}</span>
              </div>
              <div className={'total-price'}>
                <span className={'title'}>Total price</span>
                <span className={'price'}>{totalPrice}</span>
              </div>
            </div>

            <div className='bottom-section--payment-options'>
              <Select
                options={childrenOptions}
                onChange={onChildrenSelect}
                styles={customStyles}
                defaultInputValue={'Payment options'}
                components={{ DropdownIndicator: CustomDropdownIndicator as any }}
              />
            </div>
            <Button label={'Select Holiday'} primary={true} onClick={() => onHolidaySelect()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HolidaySummary;
