import React, { FunctionComponent } from 'react'
import { noop } from 'lodash'
import './NameSection.scss'
import mapLinkIcon from '../../assets/mapLink.svg'
import tripLogo from '../../assets/tripadvisor.svg'
import { Rating } from '../Rating'

interface NameSectionProps {
  name: string;
  rating: number;
  location: string;
  tripadvisorRating: number;
  tripadvisorReviews: string;
  isMapLinkVisible?: boolean;
  onMapLinkClick?: () => void;
}

const NameSection: FunctionComponent<NameSectionProps> = ({
                                                            name,
                                                            rating,
                                                            onMapLinkClick = noop,
                                                            location,
                                                            tripadvisorRating,
                                                            tripadvisorReviews,
                                                            isMapLinkVisible = true,
                                                          }: NameSectionProps) => {

  return (
    <div className='name-section-wrapper'>
      <div className='main-info-wrapper'>
        <div className='name-section'>
          <span className={'name'}>{name}</span>
          <Rating type={'star'} rating={rating} />
        </div>

        {isMapLinkVisible && (
          <a className='map-link' onClick={onMapLinkClick}>
            <img src={mapLinkIcon} alt='' />
            <span>View map</span>
          </a>
        )}
      </div>
      <p className={'location'}>{location}</p>
      <div className='tripadvisor-rating'>
        <img src={tripLogo} alt='' className='trip-logo' />
        <Rating type={'circle'} rating={tripadvisorRating} />
        <span className='review-details'>{tripadvisorReviews}</span>
      </div>
    </div>
  )
}

export default NameSection
