import React, { FunctionComponent } from 'react'
import { Rating } from '../HolidayCard/Rating'
import icon from '../assets/tripadvisor_logo.svg'

import './TripadvisorHotelInfo.scss'

interface TripadvisorHotelInfoProps {
  hotelName: string
  hotelLocation: string
  tripadvisorRating: number
  tripadvisorReviews: string
}

export const TripadvisorHotelInfo: FunctionComponent<TripadvisorHotelInfoProps> = ({
  hotelName,
  hotelLocation,
  tripadvisorRating,
  tripadvisorReviews,
}: TripadvisorHotelInfoProps) => {
  
  return (
    <div className='tripadvisor-hotel-wrapper'>
      <span className='tripadvisor-hotel-title'>{hotelName}</span>
      <div className='tripadvisor-ratings-section'>
        <div className='tripadvisor-rating'>
          <img className='tripadvisor-rating-icon' src={icon} alt='Tripadvisor icon' />
          <Rating type='circle' rating={tripadvisorRating} />
          <span className='tripadvisor-rating-reviews'>{tripadvisorReviews} reviews</span>
        </div>
      </div>
      <div className='tripadvisor-location'>#1 of 1 Hotels in {hotelLocation}</div>
    </div>
  )
}
