import React, { FunctionComponent } from 'react';
import './Heart.css'
import heart from '../assets/heart.svg';

interface HeartProps {
  width: number;
  height: number;
  numberFavourites: number;
  onClick?: () => void;
}

export const Heart: FunctionComponent<HeartProps> = ({ width, height, numberFavourites, onClick }: HeartProps) => {
  return (
    <>
      <div className={'heart-icon-wrapper'}>
        <img className={'heart-icon'} width={width} height={height} src={heart} onClick={onClick} alt="Heart icon"/>
        {!!numberFavourites && (<span className={'count-pill'}>{numberFavourites}</span>)}
      </div>
    </>
  )
}
