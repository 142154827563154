import React, { FunctionComponent, useState, useEffect } from 'react';
import { SearchBar } from '../../components/SearchBar';
import { BaseLayout } from '../../components/BaseLayout';
import { Menu } from '../../components/Menu/Menu';
import { noop } from 'lodash';
import './HomePage.scss';
import useMultipleMedia from '../../hooks/useMultipleMedia';
interface HomePageProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

export const HomePage: FunctionComponent<HomePageProps> = ({ onLogin = noop, openMenu = noop }) => {
  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <BaseLayout
      onLogin={onLogin}
      openMenu={() => setMenuOpen(!menuOpen)}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
    >
      {isSearchBarOpen ? (
        <div className='search-bar-wrapper'>
          <SearchBar
            isBoardType={false}
            onOverlayClick={setIsSearchBarOpen}
          />
        </div>
      ) : null}
      {menuOpen && (
        <div className='Fullscreen-cover'>
          <Menu onClose={() => setMenuOpen(false)} />
        </div>
      )}
    </BaseLayout>
  );
};
