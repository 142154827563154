import React, { FunctionComponent } from 'react';
import './ProgressBar.css';
import cn from 'classnames';

export interface StepState {
  title: string;
  isCompleted: boolean;
  isActive: boolean;
}

export interface ProgressBarProps {
  state: Array<StepState>;
  size?: 'small' | 'medium' | 'large';
  onStepChange?: () => void;
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  state,
  size,
  onStepChange,
}: ProgressBarProps) => {
  return (
    <div className={`progress-bar-wrapper progress-bar-wrapper--${size}`}>
      {state.map((item, index) => {
        return (
          <div
            key={index}
            className={cn(
              'progress-bar--item',
              { completed: item.isCompleted },
              { active: item.isActive },
            )}
            onClick={onStepChange}
          >
            <div className='progress-bar--item-counter'>{index + 1}</div>
            <div className='progress-bar--item-name'>{item.title}</div>
          </div>
        );
      })}
    </div>
  );
};
