import React, { FunctionComponent, useState } from 'react'

import './ReadMore.scss'

interface ReadMoreProps {
  children: string
  length?: number
}

const ReadMore: FunctionComponent<ReadMoreProps> = ({ children, length = 200 }: ReadMoreProps) => {
  const [showMore, setShowMore] = useState<boolean>(false)

  const toggleReadMore = () => {
    setShowMore(!showMore)
  }
  return (
    <p className='read-more-text'>
      {showMore ? children : children.slice(0, length) + '...'}
      <span onClick={toggleReadMore} className='read-more-or-less'>
        {showMore ? ' show less' : 'read more'}
      </span>
    </p>
  )
}

export default ReadMore
