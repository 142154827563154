import React, { FunctionComponent } from 'react';
import { noop } from 'lodash';
import { AutocompleteProps } from '../Autocomplete';
import Select, { StylesConfig } from 'react-select';
import '../Autocomplete/Autocomplete.scss';

const Duration: FunctionComponent<AutocompleteProps> = ({
  options,
  onChange = noop,
}: AutocompleteProps) => {
  const customStyles: StylesConfig = {
    control: () => ({
      display: 'none',
    }),
    menu: () => ({
      height: 'auto',
      width: '100%',
      zIndex: 1,
      boxSizing: 'border-box',
      border: 'solid 1px #99b4ca',
      borderRadius: '4px',
      marginTop: '8px',
      color: '#01447B',
      backgroundColor: 'white',
      position: 'absolute',
    }),
  };

  const handleSelectChange = (option: any) => {
    // TODO Fix typing
    onChange(option);
  };

  return (
    <Select
      closeMenuOnSelect={true}
      menuIsOpen={true}
      styles={customStyles}
      options={options}
      placeholder={'Select Duration'}
      onChange={handleSelectChange}
    />
  );
};

export default Duration;
