import React, { ReactNode, FunctionComponent } from 'react';
import { noop } from 'lodash';
import cn from 'classnames';
import { components, OptionProps } from 'react-select';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import './Autocomplete.scss';

export interface CustomOptionProps extends OptionProps {
  isDisabled: boolean;
  isFocused: boolean;
  isMobile: boolean;
  isSelected: boolean;
  children: ReactNode;
  onChange?: () => void;
}

export const CustomOption: FunctionComponent<CustomOptionProps> = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  isMobile,
  children,
  innerProps,
  onChange = noop,
  ...rest
}) => {
  const mobileStyles = isMobile
    ? ({
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        borderBottom: '2px solid #99b4ca',
      } as any) // TODO Fix typing
    : {};

  const style = {
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: 'inherit',
    display: 'flex ',
    padding: '14px 0',
    ...mobileStyles,
  };

  const props = {
    ...innerProps,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div className={cn('autocomplete-custom-checkbox-container', { mobile: isMobile })}>
        <input
          className='autocomplete-custom-checkbox'
          type='checkbox'
          checked={isSelected}
          onChange={onChange}
        />
        <span className='autocomplete-custom-checkbox-checkmark'></span>
      </div>
      <span className='custom-option-content'>{children}</span>
    </components.Option>
  );
};
