import { observable, action, makeObservable } from 'mobx';
import { RootStore } from './RootStore';

export interface IImage {
  url?: string;
  src?: string;
  alt: string;
}

export interface IHoliday {
  destination: {
    title: string;
    subtitle: string;
    images: IImage[];
    breakfast: boolean;
    roomType: string;
    adults: number;
    children: number;
  };
  rating: {
    tripAdvisor: number;
    holidayPeople: number;
    amount: number;
  };
  people: {
    adults: number;
    children: number;
  };
  accommodation: {
    id: number;
    date: string;
    duration: number;
    rooms: string[];
    arrival: string;
    departure: string;
  };
}

export class HolidayStore {
  constructor(RootStoreRef: RootStore) {
    this.rootStore = RootStoreRef;

    makeObservable(this, {
      holiday: observable,
      setHoliday: action,
    });
  }

  rootStore: RootStore;
  holiday?: IHoliday = undefined;

  setHoliday = (_holiday: IHoliday) => {
    this.holiday = _holiday;
  };
}
