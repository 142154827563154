import React, { FunctionComponent, useState, useEffect } from 'react';
import { noop } from 'lodash';
import Select, { StylesConfig } from 'react-select';
import { CustomDropdownIndicator } from '../Room/CustomDropdownIndicator';
import { days, months, years } from './DOB_options';
import { Option } from '../Autocomplete';
import './PassengerInformation.scss';

interface PassengerInformationProps {
  index?: number;
  isFormValid?: boolean;
  onChange: (selectedValues: any, type: string) => void;
}
const PassengerInformation: FunctionComponent<PassengerInformationProps> = ({
  index,
  isFormValid,
  onChange,
}) => {
  const [selectedValues, setSelectedValues] = useState<any>();
  
  const handleChange = (option: Option | string, type: string) => {
    setSelectedValues({
      ...selectedValues,
      [type]: typeof option === 'string' ? option : option.value,
    });
  };

  useEffect(() => {
    handleChange(mr[0], 'title');
  }, []);

  useEffect(() => {
    onChange(selectedValues, index ? `additionalPassenger${index}` : 'leadPassenger');
  }, [selectedValues]);

  const mr: Option[] = [
    { value: 'mr', label: 'Mr' },
    { value: 'mrs', label: 'Mrs' },
  ];

  const customStyles: StylesConfig = {
    container: (base) => ({
      ...base,
      margin: '8px 0 20px 0',
    }),
    control: (base, state) => ({
      ...base,
      borderColor: isFormValid || state.hasValue ? '#99b4ca' : 'red',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#01447B',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      marginTop: 0,
      marginBottom: 0,
      borderColor: '#99b4ca',
    }),
    menu: (base) => ({
      ...base,
      borderColor: '#99b4ca',
      backgroundColor: 'white',
      position: 'absolute',
    }),
  };

  return (
    <div className='passenger-information-container'>
      <div className='passenger-main-info-desktop'>
        <label className='passenger-title'>
          <span className='select-label'>Title*</span>
          <Select
            options={mr}
            defaultValue={mr[0]}
            onChange={(option) => handleChange(option as Option, 'title')}
            name={index ? 'title' + index : 'title'}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
          />
        </label>
        <label className='select-label passenger-first-name'  aria-required>
          First name*
          <div className='passenger-input-wrapper'>
            <input
              className='passenger-info-input'
              type='text'
              name={index ? 'firstName' + index : 'firstName'}
              onChange={(e) => handleChange(e.target.value, 'firstName')}
              required
            />
            <span className='error-message'>Please enter your first name</span>
          </div>
        </label>
        <label className='select-label passenger-last-name' aria-required>
          Last name*
          <div className='passenger-input-wrapper'>
            <input
              className='passenger-info-input'
              type='text'
              name={index ? 'lastName' + index : 'lastName'}
              onChange={(e) => handleChange(e.target.value, 'lastName')}
              required
            />
            <span className='error-message'>Please enter your last name</span>
          </div>
        </label>
      </div>
      <div className='passenger-dob-phone'>
        <div className='dob-inputs-title'>DOB*</div>
        <div className='dob-wrapper'>
          <Select
            className='dob-input'
            options={days}
            placeholder='Day'
            onChange={(option) => handleChange(option as Option, 'day')}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            name={index ? 'day' + index : 'day'}
            required
          />
          <Select
            className='dob-input'
            options={months}
            placeholder='Month'
            onChange={(option) => handleChange(option as Option, 'month')}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            name={index ? 'month' + index : 'month'}
            required
          />
          <Select
            className='dob-input'
            options={years}
            placeholder='Year'
            onChange={(option) => handleChange(option as Option, 'year')}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            name={index ? 'year' + index : 'year'}
            required
          />
          {!isFormValid &&
          (!selectedValues?.day || !selectedValues?.month || !selectedValues?.year) ? (
            <span className='error-message'>Please enter a valid date of birth</span>
          ) : null}
        </div>
        {!index ? (
          <div className='phone-wrapper'>
            <label className='select-label' aria-required>
              Contact number*
              <div className='passenger-input-wrapper'>
                <input
                  className='passenger-info-input'
                  type='text'
                  onChange={(e) => handleChange(e.target.value, 'phone')}
                  name='phone'
                  required
                />
                <span className='error-message'>Please enter your contact number</span>
              </div>
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PassengerInformation;
