import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import './HolidayDetails.scss';
import calendarLines from '../../assets/calendarLines.svg';
import kitchen from '../../assets/kitchen.svg';
import flight from '../../assets/flight.svg';
import { Flight, Room } from '../HolidayCard';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import OfferFlightDetails from './OfferFlightDetails/OfferFlightDetails';
import moment from 'moment';

interface HolidayDetailsProps {
  date: string;
  rooms: Room[];
  flights: Flight[];
}

const HolidayDetails: FunctionComponent<HolidayDetailsProps> = ({
  date,
  rooms,
  flights,
}: HolidayDetailsProps) => {
  const roomsDescription = rooms?.map((room: Room) => room.board_description).join(', ');
  const airports = flights?.map((flight: Flight) => flight.departure_airport.name).join(', ');
  const [isFlightDetailsVisible, setIsFlightDetailsVisible] = useState<boolean>(false);

  const outsideClickCallback = () => {
    setIsFlightDetailsVisible(false);
  };

  const offerFlightRef = useRef<HTMLDivElement>(null);
  const flightsWisiblebtnRef = useRef<HTMLDivElement>(null);
  useOutsideClick([offerFlightRef, flightsWisiblebtnRef], outsideClickCallback);

  useEffect(() => {
    if (offerFlightRef.current) {
      offerFlightRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [offerFlightRef.current]);

  return (
    <div className='holiday-details-wrapper'>
      <div className='holiday-details--item'>
        <img src={calendarLines} alt='' />
        <span>{date}</span>
      </div>
      <div className='holiday-details--item'>
        <img src={kitchen} alt='' />
        <span>{roomsDescription}</span>
      </div>
      <div className='holiday-details--item'>
        <img className={'flight'} src={flight} alt='' />
        {isFlightDetailsVisible && (
          <div ref={offerFlightRef}>
            <OfferFlightDetails
              outbound={{
                date: flights[0].departing,
                duration: Number(
                  moment(flights[0].departing).diff(flights[0].return_arrival, 'days'),
                ),
                airlineLogo: flights[0].airline,
                airlineCode: flights[0].number,
                flightType: 'Outbound',
                departureTime: flights[0].departing,
                departurePlace: flights[0].departure_airport.name,
                arrivalTime: flights[0].arrival,
                arrivalPlace: flights[0].arrival_airport.name,
              }}
              inbound={{
                date: flights[0].return_departing,
                duration: Number(
                  moment(flights[0].departing).diff(flights[0].return_arrival, 'days'),
                ),
                airlineLogo: flights[0].return_airline,
                airlineCode: flights[0].return_number,
                flightType: 'Inbound',
                departureTime: flights[0].return_departing,
                departurePlace: flights[0].arrival_airport.name,
                arrivalTime: flights[0].return_arrival,
                arrivalPlace: flights[0].departure_airport.name,
              }}
            />
          </div>
        )}
        <span>
          {airports} -{' '}
          <span
            ref={flightsWisiblebtnRef}
            onClick={() => setIsFlightDetailsVisible(!isFlightDetailsVisible)}
            className='flight-details'
          >
            Flight Details
          </span>
        </span>
      </div>
    </div>
  );
};

export default HolidayDetails;
