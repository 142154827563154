import React, { FunctionComponent } from 'react';
import './HolidayDetails.scss';
import hotelIcon from '../assets/hotel.svg';
import locationIcon from '../assets/location.svg';
import tripadvisor from '../assets/tripadvisor.svg';
import ImageCarousel, { Image } from '../ImageCarousel/ImageCarousel';
import { HolidayTopDetails } from './HolidayTopDetails';

interface FiltersSidebarProps {
  hotelName: string;
  hotelLocation: string;
  rating: number;
  tripadvisorRating: number;
  tripadvisorReviews: string;
  images: Image[];
  onLinkClick: (tab: number) => void;
}

const HolidayDetails: FunctionComponent<FiltersSidebarProps> = ({
  hotelName,
  hotelLocation,
  rating,
  tripadvisorRating,
  tripadvisorReviews,
  images,
  onLinkClick,
}: FiltersSidebarProps) => {
  return (
    <>
      <div className='holiday-details-component-wrapper'>
        <HolidayTopDetails
          hotelName={hotelName}
          hotelLocation={hotelLocation}
          rating={rating}
          tripadvisorRating={tripadvisorRating}
          tripadvisorReviews={tripadvisorReviews}
        />
        <div className='holiday-details-component-wrapper--slider'>
          {images && images.length > 0 && <ImageCarousel images={images} />}
        </div>
        <div className='holiday-details-component-wrapper--footer-links'>
          <a href={'#'} className='link-wrapper' onClick={() => onLinkClick(0)}>
            <img className='link-icon' src={hotelIcon} alt={'Hotel icon'}></img>
            <span className={'link-text'}>{'Hotel Information'}</span>
          </a>

          <a href={'#'} className='link-wrapper' onClick={() => onLinkClick(1)}>
            <img className='link-icon map-icon' src={locationIcon} alt={'Map icon'}></img>
            <span className={'link-text'}>{'Map'}</span>
          </a>

          <a href={'#'} className='link-wrapper' onClick={() => onLinkClick(2)}>
            <img className='link-icon weather-icon' src={tripadvisor} alt={'Tripadvisor icon'} />
            <span className={'link-text'}>{'Tripadvisor'}</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default HolidayDetails;
