import { StylesConfig } from 'react-select';

export const StylesSelect: StylesConfig = {
  container: (base: any) => ({
    ...base,
    marginTop: '10px',
  }),
  control: (base: any) => ({
    ...base,
    borderColor: '#99b4ca',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#01447B',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
    borderColor: '#99b4ca',
  }),
  menu: (base: any) => ({
    ...base,
    borderColor: '#99b4ca',
    backgroundColor: 'white',
    position: 'absolute',
  }),
};
