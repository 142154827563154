import { FunctionComponent, useRef, useState, useEffect } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './AirportSelect.scss';
import { Modal } from '../Modal';
import { Autocomplete, Option } from '../Autocomplete';
import { Button } from '../Button';
import departure from '../assets/departure.svg';
import { useGetAvailableDeparturePoints } from '../../hooks/useRequest';
import { getDepartureDateFromParams } from '../../pages/SearchResultsPage/SearchResultsPage';
import moment from 'moment';
import { compact, noop, uniqBy } from 'lodash';

interface AirportsSelectProps {
  clickApplyHandler: (option: Option, name: string) => void;
  clickCancelHandler?: () => void;
  resetItemType?: (type: string) => void;
  searchItemType?: string;
  date: string;
  duration: any;
  rooms: string;
  destinations: any;
  selectedValue: any;
  selectedSearchParams: any;
}

const AirportsSelect: FunctionComponent<AirportsSelectProps> = ({
  clickApplyHandler,
  clickCancelHandler,
  resetItemType = noop,
  searchItemType,
  date,
  duration,
  rooms,
  destinations,
  selectedValue,
  selectedSearchParams,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any>(selectedValue); // TODO Fix typing
  const searchBarInputFieldRef = useRef<HTMLDivElement>(null);

  const getDestinationsFromParams = (type: string) => {
    if (!destinations) return [];

    return destinations
      .filter((destination: Option) => destination.type === type)
      .map((destination: Option) => destination.value);
  };

  const parsedDestinations = getDestinationsFromParams('destination');
  const regions = getDestinationsFromParams('region');
  const resorts = getDestinationsFromParams('resort');

  const departureDate = getDepartureDateFromParams(selectedSearchParams, 'value');
  const departureType = getDepartureDateFromParams(selectedSearchParams, 'label');

  const { data, isSuccess } = useGetAvailableDeparturePoints(
    [duration.value, duration, rooms, parsedDestinations, regions, resorts],
    JSON.stringify(parsedDestinations),
    JSON.stringify(regions),
    JSON.stringify(resorts),
    departureDate,
    duration.value || 7,
    rooms || JSON.stringify(['2']),
    departureType,
  );

  const getAllAirports = () => {
    return (
      data?.available_departure_points?.result?.map((departure: any) => {
        return { value: departure.code, label: departure.name };
      }) || []
    );
  };

  const getAirportsByRegions = () =>
    Object.values(
      data?.available_departure_points?.result?.reduce((prevItem: any, currentItem: any) => {
        const region = currentItem.departure_point_region?.name;
        const existing = prevItem[region] || { name: region, codes: [] };

        return {
          ...prevItem,
          [region]: {
            ...existing,
            codes: [...existing.codes, currentItem.code],
          },
        };
      }, {}) || [],
    );

  useEffect(() => {
    if (searchItemType === 'Airports' && !selectExpanded) {
      resetItemType('');
      setSelectExpanded(true);
    }
  }, [searchItemType]);

  useEffect(() => {
    if (isSuccess) {
      const allAirports = getAllAirports();
      const airportsByRegions = getAirportsByRegions()
        .map((region: any) => {
          return { value: region.codes, label: region.name };
        })
        .filter((option) => option.label);

      setOptions(compact([...airportsByRegions, ...allAirports]));
    }
  }, [data, date, duration, rooms, destinations]);

  const handleApplyValues = (option: any) => {
    setSelectedValues([...new Set(uniqBy(option, 'value'))]);
    clickApplyHandler(option, 'airports');
  };

  const getInputFieldText = () => {
    if (selectedValues && selectedValues.length === 1) {
      return selectedValues[0].label;
    } else if (selectedValues && selectedValues.length === 2) {
      return `${selectedValues[0].label} and 1 other`;
    } else if (selectedValues && selectedValues.length > 2) {
      return `${selectedValues[0].label} + ${selectedValues.length - 1} More`;
    } else {
      return 'Any Airports';
    }
  };

  const icon = <img src={departure} width='22' alt='airplane' />;

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Select Airport</h5>
    </div>
  );

  const modalFooter = (
    <div className='modal-mobile-footer'>
      <Button label='Cancel' isTransparent onClick={() => setSelectExpanded(false)} />
      <Button
        label='Apply'
        primary
        onClick={() => {
          handleApplyValues(selectedValues);
          setSelectExpanded(false);
        }}
      />
    </div>
  );

  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            setSelectExpanded(false);
          }}
          className='airport-select-overlay'
        ></div>
      )}
      <div className='search-bar-input-field-autocomplete'>
        <SearchBarInputField
          title='Select Airport'
          value={getInputFieldText()}
          onClick={() => setSelectExpanded(!selectExpanded)}
          icon={icon}
          ref={searchBarInputFieldRef}
        />
        {selectExpanded && (
          <div className='airport-select-desktop-view'>
            <Autocomplete
              options={options}
              onChange={(option) => handleApplyValues(option)}
              selectedValues={selectedValues}
            />
          </div>
        )}
      </div>
      <div className='airport-select-mobile-view'>
        <Modal
          isOpen={selectExpanded}
          toggle={() => {
            setSelectExpanded(false);
          }}
          header={modalHeader}
          footer={modalFooter}
        >
          <Autocomplete
            options={options}
            onChange={setSelectedValues}
            selectedValues={selectedValues}
          />
        </Modal>
      </div>
    </>
  );
};

export default AirportsSelect;
