import { useQuery, useMutation } from 'react-query';
import { GraphQLClient, gql } from 'graphql-request';
import {
  IBasketProducts,
  ICardDetails,
  IProductFlightParams,
  IRoomsProductParams,
  ISelectTransferParams,
} from './Interfaces';
import { getAirportsFromParams } from '../pages/SearchResultsPage/SearchResultsPage';

const graphQLClient = new GraphQLClient(process.env.REACT_APP_GRAPHQL_URL as string, {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_GRAPHQL_BEARER}`,
  },
});

export function useGetBoards() {
  return useQuery('get-boards', async () => {
    const getBoardsList = await graphQLClient.request(gql`
      query {
        available_boards(rooms: ["1-8, 8"], duration: 7, departure_points: ["PIK", "NCL"]) {
          result {
            result
            count
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `);
    return getBoardsList;
  });
}

export function useGetAvailableDeparturePoints(
  tags: any,
  destinations: string,
  regions: string,
  resorts: string,
  departureDate: string,
  duration: number,
  rooms: string,
  departureType: string,
) {
  return useQuery(
    ['available_departure_points', ...tags, departureDate, departureType],
    async () => {
      const availableDeparturePoints = await graphQLClient.request(gql`
      query {
        available_departure_points(
          destinations: ${destinations},
          regions: ${regions},
          resorts: ${resorts},
          duration: ${duration},
          rooms: ${rooms}
          ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
          ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}) {
          result {
            name
            code
            departure_point_region {
              id
              name
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `);
      return availableDeparturePoints;
    },
  );
}

export function useGetAvailableDestinatios(
  destination: string,
  departurePoints: string,
  departureDate: string,
  duration: number,
  rooms: string,
  departureType: string,
) {
  return useQuery(
    ['destination', destination, departurePoints, departureDate, departureType],
    async () => {
      const availableDestinatios = await graphQLClient.request(gql`
    query {
      find_available_destinations(
        destination_name: "${destination}"
        departure_points: ${departurePoints},
        duration: ${duration},
        rooms: ${rooms}
        ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
        ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
        ) {
        result {
          id
          name
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `);
      return availableDestinatios;
    },
    {
      enabled: true,
      keepPreviousData: false,
    },
  );
}

export function useGetAvailableRegions(
  region: string,
  departurePoints: string,
  departureDate: string,
  duration: number,
  rooms: string,
  departureType: string,
) {
  return useQuery(
    ['region', departurePoints, region, departureDate, departureType],
    async () => {
      const availableRegions = await graphQLClient.request(gql`
    query {
      find_available_regions(
        region_name: "${region}",
        departure_points: ${departurePoints},
        duration: ${duration},
        rooms: ${rooms}
        ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
        ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}) {
        result {
          id
          name
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `);
      return availableRegions;
    },
    {
      enabled: true,
      keepPreviousData: false,
    },
  );
}

export function useGetAvailableResorts(
  resort: string,
  departurePoints: string,
  departureDate: string,
  duration: number,
  rooms: string,
  departureType: string,
) {
  return useQuery(
    ['resort', resort, departurePoints, departureDate, departureType, duration],
    async () => {
      const availableResorts = await graphQLClient.request(gql`
    query {
      find_available_resorts(
        resort_name: "${resort}",
        departure_points: ${departurePoints},
        duration: ${duration},
        rooms: ${rooms}
        ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
        ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
        ) {
        result {
          id
          name
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `);
      return availableResorts;
    },
    {
      enabled: true,
      keepPreviousData: false,
    },
  );
}

export function useGetDefaultDestinatios(
  tags: any,
  departurePoints: string,
  departureDate: string,
  duration: number,
  rooms: string,
  departureType: number,
) {
  return useQuery(tags, async () => {
    const defaultDestinatios = await graphQLClient.request(gql`
    query {
      available_default_destinations(
        departure_points: ${departurePoints},
        duration: ${duration},
        rooms: ${rooms}
        ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
        ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
      ) {
        result {
          destinations {
            id
            name
          }
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `);
    return defaultDestinatios;
  });
}

export function useGetAddress(searchTerm: string) {
  return useQuery([searchTerm], async () => {
    const address = await graphQLClient.request(gql`
    query {
      address_lookup(search_term: ${searchTerm}) {
        result {
          addressline1
          addressline2
          addressline3
          addressline4
          posttown
          postcode
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `);
    return address;
  });
}

export function useOffers(
  departurePoints: string,
  destinations: string,
  regions: string,
  resorts: string,
  departureDate: string,
  duration: number,
  rooms: string,
  index: number,
  ratings: string,
  boardsValues: string,
  facilities: string,
  departureType: number,
  tripRatings: string,
) {
  return useQuery(
    [
      'get-available-offers',
      departurePoints,
      destinations,
      regions,
      resorts,
      duration,
      rooms,
      ratings,
      boardsValues,
      facilities,
      departureType,
      tripRatings,
    ],
    async () => {
      const startIndex = index || 0;

      const availableOffers = await graphQLClient.request(gql`
    query {
      offers(
        departure_points: ${departurePoints},
        destinations: ${destinations},
        regions: ${regions},
        resorts: ${resorts},
        duration: ${duration},
        start_index: ${startIndex},
        take: 25,
        rooms: ${rooms}
        ratings: ${ratings}
        boards: ${boardsValues}
        features:${facilities}
        trip_ratings: ${tripRatings}
        ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
        ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}) {
        result {
          id
          price
          price_per_person
          discount
          duration
          rooms {
            board_description
          }
          flights {
            id
            departing
            airline
            arrival
            arrival_airport {
              name
              code
            }
            departure_airport {
              name
              code
            }
            return_arrival
				    return_departing
				    return_number
				    return_airline
            number
          }
          supplements {
            description
          }
          accommodation {
            id
            name
            address
            lat
            long
            resort {
              id
              name
              regions {
                name
                detail
                destinations {
                  name
                }
              }
            }
            images {
              url
            }
            trip_advisor_rating
            rating
          }
        }
        count
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `);
      return availableOffers;
    },
  );
}

export async function productRooms(sessionId: string, params: IRoomsProductParams) {
  const query = await graphQLClient.request(
    gql`
      query ($hwt_session: String!) {
        rooms(
          session: $hwt_session
          arrival: "${params.date}"
          duration: 7
          accommodation_id: ${params.accommodationId}
          rooms: ["2"]
        ) {
          result {
            adults
            children
            rooms {
              id
              cancellation_policy
              deposit_price
              board
              board_description
              price
              description
              price_per_person
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );

  return query;
}

// not a hook because we need the data nested in a query.
export async function productFlights(sessionId: string, params: IProductFlightParams) {
  const query = await graphQLClient.request(
    gql`
      query ($hwt_session: String!) {
        flights(
          session: $hwt_session
          departure_date: "${params.date}"
          duration: ${params.duration}
          departure_airport_iata: "${params.departure}"
          arrival_airport_iata: "${params.arrival}"
        ) {
          result {
            id
            airline
            price
            original_price
            provider_price
            discount
            deposit_price
            margin
            departure_airport {
              name
              code
            }
            arrival_airport {
              name
              code
            }
            departing
            arrival
            number
            return_number
            price_per_person
            discount
            return_arrival
            return_departing
            return_airline
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );

  return query;
}

export async function getBasket(sessionId: string) {
  return await graphQLClient.request(
    gql`
      query ($hwt_session: String!) {
        basket(session: $hwt_session) {
          result {
            deposit_due
            deposit_price
            price
            price_deposit_diff
            products {
              id
              original_price
              price
              price_per_person
              check_time
              product_type {
                id
                description
                name
              }
              provider_price
              booking_component {
                reference
              }
            }
            supplements {
              description
              amount
            }
            booking {
              id
              outstanding_balance
              previous_balance
              cost
              live_cost_difference
              status
              adults
              children
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function removeProduct(sessionId: string, id: number, productType: number) {
  return await graphQLClient.request(
    gql`
      mutation ($hwt_session: String!) {
        basket_remove_product(session: $hwt_session, product_id: ${id}, product_type: ${productType}) {
          result {
            products {
              id
              price
              provider_id
              product_type {
                id
                description
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, id: id },
  );
}

/**
 *
 * @param sessionId
 * @param id
 * @param productType - 0 hotels, 1 flights
 * @returns
 */
export async function addProduct(sessionId: string, id: number, productType: number) {
  return await graphQLClient.request(
    gql`
      mutation ($hwt_session: String!) {
        basket_add_product(session: $hwt_session, product_id: ${id}, product_type: ${productType}) {
          result {
            products {
              id
              price
              provider_id
              product_type {
                id
                description
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

/**
 * first we need to get all our items in the basket, remove the previous flight if any.
 */
export async function addProductPrechecks(
  sessionId: string,
  flightId: number,
  productType: number,
) {
  const responseBasket = await getBasket(sessionId);
  if (
    responseBasket?.basket?.result?.products &&
    responseBasket?.basket?.result?.products.length === 0
  ) {
    await addProduct(sessionId, flightId, productType);
  } else {
    responseBasket?.basket?.result?.products.forEach(async (product: IBasketProducts) => {
      if (product.product_type.id === productType) {
        await removeProduct(sessionId, product.id, productType);
      }
    });
    // going to fast for the server meep meep
    await timeout(1000);
    await addProduct(sessionId, flightId, productType);
  }
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function sessionNew() {
  return await graphQLClient.request(gql`
    query {
      session_new {
        result {
          session
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
  `);
}

export function useOffersById(id: string, rooms: string) {
  return useQuery(['offer_by_id', id, rooms], async () => {
    const offerById = await graphQLClient.request(gql`
    query {
	offer_by_id(id: "${id}" rooms: ${rooms}){
		result {
			id
			duration
			original_price
			price
			price_per_person
			duration
			supplement_total
			deposit_due
			accommodation {
        id
				name
				resort {
					id
				}
        images {
          url
        }
        lat
        long
				address
				rating
				trip_advisor_rating

			}
			flights {
				id
				airline
				arrival
				departing
				check_time
				adults
				number
				children
				price
        return_number
				return_arrival
				return_airline
				return_departing
        
				departure_airport {
					name
					code
					departure_point_region {
						id
						name
					}
				}
				arrival_airport {
					name
					code
				}
        booking_component {
					description
				}
			}
			rooms {
				id
				price
				adults
				children
				description
			}
		}
    error {
        ... on Unexpected_Error {
          message
        }
      }
	}
}
    `);
    return offerById;
  });
}

export function useAccommodationOffersByMonth(
  acommodationId: number,
  departureDate: string,
  options: any,
) {
  return useQuery(['accommodation_offers_by_month', departureDate, options], async () => {
    let queryParams = '';

    options &&
      Object.keys(options).forEach((key) => {
        let departures = [];
        if (key === 'airports') {
          const airportValues: any = getAirportsFromParams(options[key]);
          departures = airportValues.map((a: any) => `"${a}"`);
        }

        switch (key) {
          case 'rooms':
            queryParams += `\nrooms: ${options[key]}\n`;
            break;
          case 'airports':
            queryParams += `departure_points: [${departures}]\n`;
            break;
          case 'duration':
            queryParams += `duration: ${options[key].value}\n`;
            break;
          case 'boardType':
            queryParams += `boards: [${options[key].map((i: any) => i.value)}]\n`;
            break;
        }
      });

    const accommodationOffersByMonth = await graphQLClient.request(gql`
      query {
        accommodation_offers_by_month(
          accommodation_id: ${acommodationId}
          departure_date: "${departureDate}"
          ${queryParams}
        ) {
          result {
            id
            flights{
              departing
            }
            price
            price_per_person
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
    }
    `);
    return accommodationOffersByMonth;
  });
}

export function usePaymentPayMonthly(day: number, sessionId: string, amount: number) {
  return useQuery('basket_payment_plan', async () => {
    return await graphQLClient.request(
      gql`
          query ($hwt_session: String!) {
            basket_payment_plan(session: $hwt_session, desired_day: ${day}, amount: ${amount}) {
              result {
                  id
                  outstanding_balance,
                  plan {
                    date_due,
                    amount
                  }
              }
              error {
                ... on Unexpected_Error {
                  message
                }
              }
            }
          }
        `,
      // eslint-disable-next-line
      { hwt_session: sessionId },
    );
  });
}

export function useAvailableBoards(
  rooms: string,
  departurePoints: string,
  duration: any,
  ratings: string,
  tripRating: string,
  features: string,
  departureDate: string,
  departureType: string,
  regions: string,
  resorts: string,
  destinations: string,
) {
  return useQuery(
    [
      'available_boards',
      ratings,
      tripRating,
      features,
      departureDate,
      departureType,
      regions,
      resorts,
      destinations,
    ],
    async () => {
      const availableBoards = await graphQLClient.request(gql`
        query {
          available_boards(
            rooms: ${rooms}
            duration: ${duration}
            departure_points: ${departurePoints}
            ratings: ${ratings}
            features: ${features}
            trip_ratings: ${tripRating}
            ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
            ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
            regions: ${regions}
            resorts: ${resorts}
            destinations: ${destinations}
          ) {
            result {
              result
              count
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
      `);
      return availableBoards;
    },
  );
}

export function useAvailableRatings(
  rooms: string,
  departurePoints: string,
  duration: any,
  boardsValues: string,
  tripRating: string,
  features: string,
  departureDate: string,
  departureType: string,
  regions: string,
  resorts: string,
  destinations: string,
) {
  return useQuery(
    [
      'available_ratings',
      boardsValues,
      tripRating,
      features,
      departureDate,
      departureType,
      regions,
      resorts,
      destinations,
    ],
    async () => {
      const availableRatings = await graphQLClient.request(gql`
       query {
        available_ratings(
          rooms: ${rooms},
          duration: ${duration},
          departure_points: ${departurePoints},
          boards: ${boardsValues},
          features: ${features},
          trip_ratings: ${tripRating}
          ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
          ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
          regions: ${regions}
          resorts: ${resorts}
          destinations: ${destinations}
        ) {
          result {
            result
            count
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
      `);
      return availableRatings;
    },
  );
}

export function useAvailableFeatures(
  rooms: string,
  departurePoints: string,
  duration: any,
  boardsValues: string,
  ratings: string,
  tripRating: string,
  departureDate: string,
  departureType: string,
  regions: string,
  resorts: string,
  destinations: string,
) {
  return useQuery(
    [
      'available_features',
      boardsValues,
      ratings,
      tripRating,
      departureDate,
      departureType,
      regions,
      resorts,
      destinations,
    ],
    async () => {
      const availableFeatures = await graphQLClient.request(gql`
       query {
        available_features(
          rooms: ${rooms}
          duration: ${duration}
          departure_points: ${departurePoints}
          boards: ${boardsValues},
          ratings: ${ratings}
          trip_ratings: ${tripRating}
          ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
          ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
          regions: ${regions}
          resorts: ${resorts}
          destinations: ${destinations}
        ) {
          result {
            result
            count
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
      `);
      return availableFeatures;
    },
  );
}

export function useAvailableTripRatings(
  rooms: string,
  departurePoints: string,
  duration: any,
  boardsValues: string,
  ratings: string,
  features: string,
  departureDate: string,
  departureType: string,
  regions: string,
  resorts: string,
  destinations: string,
) {
  return useQuery(
    [
      'available_trip_ratings',
      boardsValues,
      ratings,
      features,
      departureDate,
      departureType,
      regions,
      resorts,
      destinations,
    ],
    async () => {
      const availableTripRatings = await graphQLClient.request(gql`
       query {
          available_trip_ratings(
            rooms: ${rooms}
            duration: ${duration}
            departure_points: ${departurePoints}
            ratings: ${ratings}
            features: ${features}
            boards: ${boardsValues}
            ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
            ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}
            regions: ${regions}
            resorts: ${resorts}
            destinations: ${destinations}
          ) {
            result {
              result
              count
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
      `);
      return availableTripRatings;
    },
  );
}
export async function checkProduct(sessionId: string, id: number, productType: number) {
  return await graphQLClient.request(
    gql`
      query ($hwt_session: String!) {
        product_check(product_id: ${id}, product_type: ${productType}, session: $hwt_session) {
          result {
            id
            price
            original_price
            provider_price
            discount
            deposit_price
            margin
            check_time
            product_type {
              id
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function addDummyPassenger(sessionId: string) {
  return await graphQLClient.request(
    gql`
      mutation ($hwt_session: String!) {
        session_add_person(
          title: "Mr"
          first_name: "Dummy"
          second_name: "Test"
          date_of_birth: "1973-01-01 00:00"
          gender: 0
          session: $hwt_session
        ) {
          result {
            id
            title
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function addDummyLeadPassenger(sessionId: string) {
  return await graphQLClient.request(
    gql`
      mutation ($hwt_session: String!) {
        session_add_lead_person(
          title: "Mr"
          first_name: "Dummy"
          second_name: "Account"
          date_of_birth: "1984-09-02 00:00"
          address1: "4 coopeartive villas"
          address2: ""
          address3: "Durham"
          post_code: "DL147DS"
          email_address: "dummy-lead@holidaywebtech.co.uk"
          contact_telephone: "01913782901"
          gender: 0
          session: $hwt_session
        ) {
          result {
            id
          }
          error {
            ... on Not_Found_Error {
              message
            }
            ... on Validation_Error {
              type
              message
              paramater
            }
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function checkProductDetails(
  sessionId: string,
  productType: number,
  productId: number,
) {
  return await checkProduct(sessionId, productId, productType);
}

export async function getBasketFlightExtras(sessionId: string, productId: number) {
  return await graphQLClient.request(
    gql`
      query ($hwt_session: String!) {
        basket_flight(session: $hwt_session, product_id: ${productId}) {
          result {
            id
            extras {
              id
              code
              price
              description
              quantity_available
              product_type {
                id
              }
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function getSelectTransfer(sessionId: string, params: ISelectTransferParams) {
  return await graphQLClient.request(
    gql`
      query ($hwt_session: String!) {
        transfers(
          session: $hwt_session
          arrival: "${params.arrival}"
          returning: "${params.returning}"
          accommodation_id: ${params.accommodationId}
          arrival_airport_iata: "${params.arrivalAirportIata}"
          return_airport_iata: "${params.returnAirportIata}"
          outbound_flight_number: "${params.outboundFlightNumber}"
          inbound_flight_number: "${params.inboundFlightNumber}"
        ) {
          result {
            id
            description
            vehicle_type
            check_time
            price_per_person
            product_type {
              id
              name
              description
            }
            price
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

// no hook version
export async function AccommodationOffersByMonth(
  acommodationId: number,
  departureDate: string,
  options: any,
) {
  let queryParams = '';

  options &&
    Object.keys(options).forEach((key) => {
      let departures = [];
      if (key === 'airports') {
        const airportValues: any = getAirportsFromParams(options[key]);
        departures = airportValues.map((a: any) => `"${a}"`);
      }

      switch (key) {
        case 'rooms':
          if (options[key]) {
            const roomString = generateRoomString(options[key]);
            queryParams += `\nrooms: ${JSON.stringify(roomString)}\n`;
          }
          break;
        case 'airports':
          queryParams += `departure_points: [${departures}]\n`;
          break;
        case 'duration':
          queryParams += `duration: ${options[key].value}\n`;
          break;
        case 'boardType':
          queryParams += `boards: [${options[key].map((i: any) => i.value)}]\n`;
          break;
      }
    });

  return await graphQLClient.request(gql`
      query {
        accommodation_offers_by_month(
          accommodation_id: ${acommodationId}
          departure_date: "${departureDate}"
          ${queryParams}
        ) {
          result {
            id
            flights{
              departing
            }
            price
            price_per_person
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
    }
    `);
}

export async function makeBooking(sessionId: string) {
  return await graphQLClient.request(
    gql`
      mutation basket_new_booking($hwt_session: String!) {
        basket_new_booking(session: $hwt_session) {
          result {
            booking {
              id
              cost
              customer {
                id
                first_name
              }
            }
            session_reference
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function paymentStepOne(
  sessionId: string,
  cardDetails: ICardDetails,
  amount: number,
  bookingId: number,
) {
  return await graphQLClient.request(
    gql`
      mutation booking_make_a_payment {
        booking_make_a_payment(
          booking_id: ${bookingId}
          amount: ${amount}
          card_number: "${cardDetails.cardNumber}"
          name_on_card: "${cardDetails.name}"
          exp_month: ${cardDetails.expMonth}
          exp_year: ${cardDetails.expYear}
          security_no: "${cardDetails.securityNo}"
          payment_session: ""
          data: ""
        ) {
          result {
            step
            pgw_response
            payment_session
            completed
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function paymentStepTwo(
  sessionId: string,
  cardDetails: ICardDetails,
  amount: number,
  bookingId: number,
  paymentSession: string,
) {
  return await graphQLClient.request(
    gql`
      mutation booking_make_a_payment {
        booking_make_a_payment(
          booking_id: ${bookingId}
          amount: ${amount}
          card_number: "${cardDetails.cardNumber}"
          name_on_card: "${cardDetails.name}"
          exp_month: ${cardDetails.expMonth}
          exp_year: ${cardDetails.expYear}
          security_no: "${cardDetails.securityNo}"
          payment_session: "${paymentSession}"
          data: "{\\"dcc_session\\":\\"\\"}"
        ) {
          result {
            step
            pgw_response
            payment_session
            completed
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export function useAvailableDays(
  rooms: string,
  month: number,
  year: number,
  airports: any,
  duration: string,
  destinations: string,
) {
  return useQuery(['available_days', month, year, airports, duration, destinations], async () => {
    const availableDays = await graphQLClient.request(gql`
       query available_days {
          available_days(
            rooms: ${rooms},
            month: ${month},
            year: ${year},
            departure_points: ${airports},
            destinations: ${destinations},
            duration: ${duration}) {
              result
              error {
                ... on Unexpected_Error {
                  message
                }
              }
          }
        }
      `);
    return availableDays;
  });
}

export function useAvailableMonths(
  rooms: string,
  airports: string,
  duration: string,
  destinations: string,
) {
  return useQuery(['available_months', rooms, airports, duration, destinations], async () => {
    const availableMonths = await graphQLClient.request(gql`
       query available_months {
        available_months(
          rooms: ${rooms},
          departure_points: ${airports},
          destinations: ${destinations},
          duration: ${duration}) {
          result  {
            enabled
            month
            month_text
            year
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
      `);
    return availableMonths;
  });
}

export async function getBookingDetails(sessionId: string, bookingId: number) {
  return await graphQLClient.request(
    gql`
      query session_from_booking {
        session_from_booking(
          booking_id: ${bookingId}
        ) {
          result {
            session
            basket {
              booking {
					      date_of_booking
					      session_reference
				      }
              products {
                id
                check_time
                start_date
                end_date
                provider_id
                product_type {
                  id
                  description
                }
                booking_component {
                  id
                  reference
                  cost
                  success
                  description
                }
                price
                original_price
                provider_price
                discount
                deposit_price
                margin
              }
              people {
                id
                first_name
                second_name
              }
              lead_person {
                contact_telephone
              }
            }
          }
          error {
            ... on Not_Found_Error {
              message
            }
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId, reference: sessionId },
  );
}

export async function getBasketFlights(sessionId: string, productId: number) {
  return await graphQLClient.request(
    gql`
      query basket_flight($hwt_session: String!) {
        basket_flight(session: $hwt_session, product_id: ${productId}) {
          result {
            id
            airline
            departure_airport {
              code
              name
            }
            arrival_airport {
              code
              name
            }
            return_airline
            arrival
            return_departing
            return_arrival
            departing
            price
            number
            return_number
            original_price
            provider_price
            discount
            deposit_price
            margin
            product_type {
              id
              description
            }
            extras {
              id
              code
              price
              description
              sub_description
              our_description
              product_type {
                id
                description
              }
              quantity_available
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export async function getBasketRooms(sessionId: string, productId: number) {
  return await graphQLClient.request(
    gql`
      query basket_room($hwt_session: String!) {
        basket_room(session: $hwt_session, product_id: ${productId}) {
          result {
            id
            board
            check_time
            board_description
            description
            accommodation {
              name
              resort {
                name
              }
            }
            people {
              id
              first_name
              second_name
              date_of_birth
            }
            price

            product_type {
              id
              description
            }
          }
          error {
            ... on Unexpected_Error {
              message
            }
          }
        }
      }
    `,
    // eslint-disable-next-line
    { hwt_session: sessionId },
  );
}

export function useAddLeadPerson(sessionId?: string, passenger?: any) {
  return useMutation(async () => {
    return await graphQLClient.request(
      gql`
    mutation ($hwt_session: String!) {
      session_add_lead_person(
        title: "${passenger.title}"
        first_name: "${passenger.firstName}"
        second_name: "${passenger.lastName}"
        date_of_birth: "1984-01-01 00:00"
        address1: "${passenger.address.addressline1}"
        post_code: "${passenger.address.postcode}"
        email_address: "${passenger.email}"
        contact_telephone: "${passenger.phone}"
        gender: 0
        session: $hwt_session
      ) {
        result {
          id
        }
        error {
          ... on Not_Found_Error {
            message
          }
          ... on Validation_Error {
            type
            message
            paramater
          }
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `,
      // eslint-disable-next-line
      { hwt_session: sessionId },
    );
  });
}

export function useAddAdditionalPerson(sessionId?: string, passenger?: any) {
  return useMutation(async () => {
    return await graphQLClient.request(
      gql`
    mutation ($hwt_session: String!) {
      session_add_person(
        title: "${passenger.title}"
        first_name: "${passenger.firstName}"
        second_name: "${passenger.lastName}"
        date_of_birth: "1973-01-01 00:00"
        gender: 1
        session: $hwt_session
      ) {
        result {
          id
        }
        error {
          ... on Unexpected_Error {
            message
          }
        }
      }
    }
    `,
      // eslint-disable-next-line
      { hwt_session: sessionId },
    );
  });
}

export function useAvailableDuration(
  rooms: string,
  airports: string,
  departureDate: string,
  departureType: string,
  destinations: string,
  regions: string,
  resorts: string,
) {
  return useQuery(
    [
      'available_durations',
      rooms,
      airports,
      destinations,
      regions,
      resorts,
      rooms,
      departureDate,
      departureType,
    ],
    async () => {
      const availableDuration = await graphQLClient.request(gql`
       query available_durations {
          available_durations(
          departure_points: ${airports},
          destinations: ${destinations},
          regions: ${regions},
          resorts: ${resorts},
          rooms: ${rooms},
          ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
          ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}) {
            result
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
      `);
      return availableDuration;
    },
  );
}

export const generateRoomString = (options: any) => {
  const roomString: string[] = [];
  Object.values(options).forEach((room: any) => {
    let numberOfBeds = `${room.adults}`;
    if (room.children > 0) {
      numberOfBeds += '-';
    }
    for (let i = 0; i < room.children; i++) {
      const childAge = room.childAge[i] !== undefined ? room.childAge[i] : 0;
      if (i + 1 === room.children) {
        numberOfBeds += childAge;
      } else {
        numberOfBeds += childAge + ',';
      }
    }
    roomString.push(`${numberOfBeds}`);
  });
  return roomString;
};

export function useAvailablePax(
  airports: string,
  departureDate: string,
  departureType: string,
  destinations: string,
  regions: string,
  resorts: string,
) {
  return useQuery(
    ['available_pax', airports, destinations, regions, resorts, departureDate, departureType],
    async () => {
      const availablePax = await graphQLClient.request(gql`
       query available_pax {
          available_pax(
          departure_points: ${airports},
          destinations: ${destinations},
          regions: ${regions},
          resorts: ${resorts},
          ${departureDate ? 'departure_date: "' + departureDate + '",' : ''}
          ${departureDate ? 'departure_date_type: ' + departureType + ',' : ''}) {
            result {
              adults
              children
            }
            error {
              ... on Unexpected_Error {
                message
              }
            }
          }
        }
      `);
      return availablePax;
    },
  );
}
