import React, { FunctionComponent } from 'react'

import './TripadvisorVotes.scss'

interface TripadvisorVotesProps {
  width: number
  votes: number
}

export const TripadvisorVotes: FunctionComponent<TripadvisorVotesProps> = ({
  width,
  votes,
}: TripadvisorVotesProps) => {
  
  return (
    <div className='tripadvisor-votes-amount-wrapper'>
      <div className='tripadvisor-votes-amount-indicator' style={{ width: width }}></div>
      <span className='tripadvisor-votes-amount'>{votes}</span>
    </div>
  )
}
