import React, { FunctionComponent } from 'react';
import '../SummaryCard.scss';

export interface ILuggageSummary {
  title: string;
  quanity: number;
}

export interface ToggleProps {
  title: string;
  icon: string;
  luggage?: ILuggageSummary[];
}

export const ExtrasCard: FunctionComponent<ToggleProps> = ({
  title,
  icon,
  luggage,
}: ToggleProps) => {
  return (
    <div className='summary-card-wrapper'>
      <div className='summary-card-wrapper--header'>
        <img src={icon} alt='Section icon' />
        <span className={'card-title'}>{title}</span>
      </div>

      {luggage && luggage.length > 0 && (
        <div className='summary-card-wrapper--body'>
          <span className={'card-title'}>Luggage</span>
          {luggage.map((lug, index) => {
            return (
              <div key={index + 'luggageextra'} className='summary-card-list'>
                <div className='summary-card-list--item extras-card-list--item'>
                  <span>{lug.title}</span>
                  <span>x {lug.quanity}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {/* not yet implemented */}
      {/* <div className='summary-divider'></div>
        <span className={'card-title'}>Transfer</span>
        <div className='summary-card-list'>
          <div className='summary-card-list--item'>
            <span>Shared Standard Shuttle</span>
            <span> </span>
          </div>
        </div> */}
    </div>
  );
};
