import React, { FunctionComponent, useEffect, useState } from 'react';
import { noop } from 'lodash';
import './BookingConfirmed.scss';
import { BaseLayout } from '../../components/BaseLayout';
import { SummaryCard } from '../../components/SummaryCard/SummaryCard';
import { FlightsCard } from '../../components/SummaryCard/FlightsCard/FlightsCard';
import { ExtrasCard } from '../../components/SummaryCard/ExtrasCard/ExtrasCard';

import docIcon from '../../components/assets/document.svg';
import hotelIcon from '../../components/assets/hotel.svg';
import plane from '../../components/assets/plane.svg';
import plus from '../../components/assets/plus.svg';
import { SuccessIcon } from '../../components/Header/SuccessIcon/SuccessIcon';

import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { getBasketFlights, getBasketRooms, getBookingDetails } from '../../hooks/useRequest';
import moment from 'moment';

interface BookingConfirmedProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

const BookingConfirmed: FunctionComponent<BookingConfirmedProps> = ({
  onLogin = noop,
  openMenu = noop,
}) => {
  const [bookingRequested, setBookingRequest] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);

  const {
    RootStore: {
      sessionStore: { userSession },
      basketStore: { bookingId },
    },
  } = useStores() as { RootStore: RootStore };

  const navigate = useNavigate();

  const [flightState, setFlightState] = useState<any>(false);
  const [bookingState, setBookingState] = useState<any>(false);
  const [hotelState, setHotelState] = useState<any>(false);
  const [luggageState, setLuggageState] = useState<any>(false);

  useEffect(() => {
    if (!userSession.sessionId) {
      navigate('/');
    }
  }, [userSession.sessionId]);

  useEffect(() => {
    if (!bookingRequested) {
      setBookingRequest(true);

      const getHotelData = async (productId: number, checkIn: string, checkOut: string) => {
        const results = await getBasketRooms(userSession.sessionId || '', productId);
        if (results?.basket_room?.result) {
          const basketRoom = results?.basket_room?.result;
          const newHotelState: Record<string, string> = {
            name: basketRoom.accommodation.name,
            destination: basketRoom.accommodation.resort.name,
            duration: moment(checkOut).diff(checkIn, 'days').toString(),
            checkIn: moment(checkIn).format('DD/MM/YYYY'),
            checkOut: moment(checkOut).format('DD/MM/YYYY'),
            roomType: basketRoom.description,
            board: basketRoom.board_description,
          };

          setHotelState(newHotelState);
        }
      };

      const getFlightsData = async (productId: number, extras: Record<number, number>) => {
        const results = await getBasketFlights(userSession.sessionId || '', productId);

        if (results?.basket_flight?.result) {
          const basketFlight = results.basket_flight.result;
          const newFlightState: Record<string, string> = {
            outboundAirline: `${basketFlight.airline} (${basketFlight.number})`,
            outboundDate: `${moment(basketFlight.departing).format('ddd DD MMM yyyy')}`,
            outboundDateRange: `${moment(basketFlight.departing).format('HH:mm')} ${
              basketFlight.departure_airport.name
            } - ${moment(basketFlight.arrival).format('HH:mm')} ${
              basketFlight.arrival_airport.name
            }`,
            inboundAirline: `${basketFlight.return_airline} (${basketFlight.return_number})`,
            inboundDate: `${moment(basketFlight.return_departing).format('ddd DD MMM yyyy')}`,
            inboundDateRange: `${moment(basketFlight.return_departing).format('HH:mm')} ${
              basketFlight.arrival_airport.name
            } - ${moment(basketFlight.return_arrival).format('HH:mm')} ${
              basketFlight.departure_airport.name
            }`,
          };

          setFlightState(newFlightState);

          const extrasState: any = [];
          if (Object.keys(extras).length > 0) {
            Object.keys(extras).forEach((k: any) => {
              const found = basketFlight.extras.find((i: any) => {
                return Number(i.id) === Number(k);
              });

              extrasState.push({
                title: found.description,
                quanity: extras[k],
              });
            });

            setLuggageState(extrasState);
          }
        }
      };

      const getBookingData = async () => {
        if (userSession.sessionId) {
          // await getBookingDetails(userSession.sessionId, bookingId);
        }
        // for development purposes.
        const results = await getBookingDetails('', bookingId);

        if (results?.session_from_booking?.result) {
          const bookingDetails = results?.session_from_booking?.result;
          const passengers = bookingDetails.basket?.people
            ?.map((passenger: any) => {
              return `${passenger.first_name} ${passenger.second_name}`;
            })
            .join('\n');

          setBookingState({
            reference: bookingDetails.basket?.booking?.session_reference,
            bookingDate: moment(bookingDetails.basket?.booking?.date_of_booking).format(
              'DD/MM/YYYY',
            ),
            passengerNames: passengers,
          });
          let flightProductID = 0;
          let hotelProductID = 0;
          let [hotelStartDate, hotelEndDate] = '';

          const extras: Record<number, number> = {};
          bookingDetails.basket?.products?.forEach((product: any) => {
            if (product.product_type.id === 1) {
              flightProductID = product.id;
            } else if (product.product_type.id === 0) {
              hotelProductID = product.id;
              hotelStartDate = product.start_date;
              hotelEndDate = product.end_date;
            } else if (product.product_type.id === 2) {
              if (extras[product.id]) {
                extras[product.id] = extras[product.id] + 1;
              } else {
                extras[product.id] = 1;
              }
            }
          });

          if (flightProductID) getFlightsData(flightProductID, extras);
          if (hotelProductID) getHotelData(flightProductID, hotelStartDate, hotelEndDate);
        }
      };

      getBookingData();
    }
  }, [bookingRequested, userSession?.sessionId, bookingId]);

  const state = {
    extras: {
      luggage: {
        luggageTitle: '20kg hold luggage',
        quanity: 2,
      },
    },
  };

  return (
    <BaseLayout
      onLogin={onLogin}
      openMenu={() => setMenuOpen(!menuOpen)}
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
      isBookingConfirmation={true}
    >
      <div className='booking-confirmed-page'>
        <div className='booking-confirmed-page-wrapper'>
          <SuccessIcon />

          <div className='booking-confirmed-page-wrapper--header'>
            <p className='booking-title'>Dear Mr Joe Bloggs,</p>
            <p className='booking-emil'>Thank you for booking with holidaypeople.co</p>
            <p className='booking-text'>
              We have sent you an email with your holiday vouchers (tickets) attached, please keep
              them safe. We recommend you download and print them at your earliest convenience.
            </p>
          </div>
          <div className='booking-confirmed-page-wrapper--body'>
            <div className='booking-summary-list'>
              {bookingState && (
                <div className='booking-summary-list--item'>
                  <SummaryCard title={'Booking Summary'} icon={docIcon} data={bookingState} />
                </div>
              )}
              {hotelState && (
                <div className='booking-summary-list--item'>
                  <SummaryCard title={'Hotel'} icon={hotelIcon} data={hotelState} />
                </div>
              )}
              {flightState && (
                <div className='booking-summary-list--item'>
                  <FlightsCard title={'Flights'} icon={plane} flightsData={flightState} />
                </div>
              )}
              {luggageState && (
                <div className='booking-summary-list--item'>
                  <ExtrasCard title={'Extras'} icon={plus} luggage={luggageState} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default observer(BookingConfirmed);
