import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './DurationSelect.scss';

import { Modal } from '../Modal';
import { Option } from '../Autocomplete';
import { Button } from '../Button';
import down from '../assets/menu-arrow.svg';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import Duration from '../Duration/Duration';
import { noop } from 'lodash';
import { generateRoomString, useAvailableDuration } from '../../hooks/useRequest';
import {
  createRoomsFromParams,
  getAirportsFromParams,
  getDepartureDateFromParams,
  getDestinationsFromParams,
} from '../../pages/SearchResultsPage/SearchResultsPage';

interface DurationSelectProps {
  clickApplyHandler: (option: Option, name: string) => void;
  clickCancelHandler?: () => void;
  resetItemType?: (type: string) => void;
  searchItemType?: string;
  selected: Option;
  searchParams: any;
}

interface Dates {
  value: number;
  label: string;
}

const DurationSelect: FunctionComponent<DurationSelectProps> = ({
  selected,
  clickApplyHandler,
  clickCancelHandler,
  resetItemType = noop,
  searchItemType,
  searchParams,
}) => {
  const airports = getAirportsFromParams(searchParams.airports);
  const departureDate = getDepartureDateFromParams(searchParams, 'value');
  const departureType = getDepartureDateFromParams(searchParams, 'label');
  const destinations = getDestinationsFromParams(searchParams, 'destination');
  const regions = getDestinationsFromParams(searchParams, 'region');
  const resorts = getDestinationsFromParams(searchParams, 'resort');
  const persons = generateRoomString(
    searchParams.rooms || [
      {
        adults: 2,
        children: 0,
      },
    ],
  );

  const { data, isLoading } = useAvailableDuration(
    JSON.stringify(persons),
    JSON.stringify(airports),
    departureDate,
    departureType,
    JSON.stringify(destinations),
    JSON.stringify(regions),
    JSON.stringify(resorts),
  );
  const [dates, setDates] = useState<Dates[]>([]);
  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<Option>(selected);

  useEffect(() => {
    if (!isLoading) {
      const datesOptions =
        (data as any)?.available_durations?.result?.map((i: any) => {
          const label = i === 1 ? 'Night' : 'Nights';
          return {
            value: i,
            label: `${i} ${label}`,
          };
        }) || [];
      setDates(datesOptions);

      if (selectedValue === null) {
        const optionResult = datesOptions.find((option: any) => {
          if (option.value === 7) {
            return option;
          } else {
            return false;
          }
        });

        if (!optionResult && !selected) {
          setSelectedValue(datesOptions[0]);
        } else {
          setSelectedValue(optionResult);
        }
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (searchItemType === 'Duration' && !selectExpanded) {
      resetItemType('');
      setSelectExpanded(true);
    }
  }, [searchItemType]);

  useEffect(() => {
    clickApplyHandler(selectedValue, 'duration');
  }, [selectedValue]);

  const searchBarInputFieldRef = useRef<HTMLDivElement>(null);

  const handleApplyValues = (option: Option) => {
    setSelectedValue(option);
    clickApplyHandler(option, 'duration');
    setSelectExpanded(!selectExpanded);
  };

  const icon = <img src={down} width='22' alt='arrow-down' />;

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Select Duration</h5>
    </div>
  );

  const modalFooter = (
    <div className='modal-mobile-footer'>
      <Button
        label='Cancel'
        isTransparent
        onClick={() => {
          setSelectExpanded(false);
        }}
      />
      <Button label='Apply' primary onClick={() => handleApplyValues(selectedValue)} />
    </div>
  );

  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            setSelectExpanded(false);
          }}
          className='duration-select-overlay'
        ></div>
      )}
      <div className='search-bar-input-field-autocomplete duration-wrapper'>
        <SearchBarInputField
          title='Select Duration'
          value={selectedValue && (selectedValue.label as string)}
          onClick={() => setSelectExpanded(!selectExpanded)}
          icon={icon}
          ref={searchBarInputFieldRef}
        />
        {selectExpanded && (
          <div className='duration-select-desktop-view'>
            <Duration
              options={dates}
              onChange={(option) => handleApplyValues(option)}
              selectedValues={[selectedValue]}
            />
          </div>
        )}
      </div>

      <div className='duration-select-mobile-view'>
        <Modal
          toggle={() => {
            setSelectExpanded(false);
          }}
          isOpen={selectExpanded}
          header={modalHeader}
          footer={modalFooter}
        >
          <Duration selectedValues={[selectedValue]} options={dates} onChange={setSelectedValue} />
        </Modal>
      </div>
    </>
  );
};

export default DurationSelect;
