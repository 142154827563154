import React, { FunctionComponent, useEffect, useState } from 'react';
import AirportsSelect from './AirportsSelect';
import DestinationSelect from './DestinationSelect';
import RoomsSelect from './RoomsSelect';
import DurationSelect from './DurationSelect';
import { Datepicker } from '../Datepicker';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import { Button } from '../Button';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoardTypeSelect from './BoardType';
import { noop } from 'lodash';
import { createRoomsFromParams } from '../../pages/SearchResultsPage/SearchResultsPage';
import { generateRoomString } from '../../hooks/useRequest';

interface SearchBarProps {
  isBoardType?: boolean;
  isSearchPage?: boolean;
  searchItemType?: string;
  resetItemType?: (type: string) => void;
  onSearchParamsChange?: (params: any) => void;
  onOverlayClick?: (open: boolean) => void;
}

const SearchBar: FunctionComponent<SearchBarProps> = ({
  isBoardType = false,
  isSearchPage = false,
  searchItemType,
  resetItemType,
  onOverlayClick = noop,
  onSearchParamsChange = noop,
}) => {
  const [searchBarExpanded, setSearchBarExpanded] = useState<boolean>(!isSearchPage ? true : false);
  const [searchParams] = useSearchParams();
  const parsedSearchParams = JSON.parse(
    Object.fromEntries(searchParams && [...searchParams]).params || '{}',
  );

  useEffect(() => {
    onSearchParamsChange(parsedSearchParams);
  }, []);

  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const [selectedSearchParams, setSelectedSearchParams] = useState<any>({}); // TODO Fix typing
  const navigate = useNavigate();

  function handleChangeSearchParams(option: any, name: string) {
    // TODO Fix typing
    setSelectedSearchParams({
      ...selectedSearchParams,
      [name]: option,
    });

    onSearchParamsChange({
      ...selectedSearchParams,
      [name]: option,
    });
  }

  const arrStr = encodeURIComponent(JSON.stringify(selectedSearchParams));

  const handleOnSearchClick = () => {
    !isMobile && onOverlayClick(false);

    setSelectedSearchParams({
      ...parsedSearchParams,
    });

    navigate(`/search?params=${arrStr}`);
  };

  const rooms = selectedSearchParams.rooms
    ? JSON.stringify(generateRoomString(selectedSearchParams.rooms))
    : JSON.stringify(['2']);

  return (
    <div className='search-bar-container'>
      {/* {isMobile && !searchBarExpanded && isBoardType && (
        <SearchBarInputField
          additionalSearchBarInputFieldClassName='input-field-main-mobile'
          title='from London Gatwick'
          value='Hello * world * !!!'
          onClick={() => onOverlayClick(!searchBarExpanded)}
        />
      )} */}
      <div className='collapsed-search-options'>
        {isSearchPage && !isMobile && (
          <div className='search-bar-overlay' onClick={() => onOverlayClick(false)}></div>
        )}
        <AirportsSelect
          clickCancelHandler={() => setSearchBarExpanded(false)}
          clickApplyHandler={handleChangeSearchParams}
          date={selectedSearchParams.date}
          duration={selectedSearchParams.duration || 7}
          rooms={rooms}
          destinations={selectedSearchParams.destinations}
          selectedValue={(parsedSearchParams && parsedSearchParams.airports) || null}
          searchItemType={searchItemType}
          resetItemType={resetItemType}
          selectedSearchParams={selectedSearchParams}
        />
        {!isBoardType && (
          <>
            <DestinationSelect
              clickCancelHandler={() => setSearchBarExpanded(false)}
              clickApplyHandler={handleChangeSearchParams}
              date={selectedSearchParams.date}
              duration={selectedSearchParams.duration || 7}
              rooms={rooms}
              airports={selectedSearchParams.airports}
              selectedValue={(parsedSearchParams && parsedSearchParams.destinations) || null}
              resetItemType={resetItemType}
              searchItemType={searchItemType}
              selectedSearchParams={selectedSearchParams}
            />
          </>
        )}
        {!isBoardType && (
          <Datepicker
            selectedSearchParams={selectedSearchParams}
            clickApplyHandler={handleChangeSearchParams}
          />
        )}
        <DurationSelect
          clickCancelHandler={() => setSearchBarExpanded(false)}
          clickApplyHandler={handleChangeSearchParams}
          selected={(parsedSearchParams && parsedSearchParams.duration) || null}
          resetItemType={resetItemType}
          searchItemType={searchItemType}
          searchParams={selectedSearchParams}
        />
        {isBoardType && (
          <BoardTypeSelect
            clickCancelHandler={() => setSearchBarExpanded(false)}
            clickApplyHandler={handleChangeSearchParams}
          />
        )}
        <RoomsSelect
          clickCancelHandler={() => setSearchBarExpanded(false)}
          clickApplyHandler={handleChangeSearchParams}
          resetItemType={resetItemType}
          searchItemType={searchItemType}
          searchParams={selectedSearchParams}
          searchParamsUrl={parsedSearchParams}
        />
        {!isBoardType && (
          <Button label='Search' primary isSearchIcon onClick={handleOnSearchClick} />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
