import React, { FunctionComponent } from 'react';
import {
  Link
} from 'react-router-dom';
import logo from '../assets/logo.svg';

interface LogoProps {
  width: number;
  height: number;
  route: string;
}


export const Logo: FunctionComponent<LogoProps> = ({ width, height, route }: LogoProps) => {
  return (
    <>
      <Link to={route}>
        <img width={width} height={height} src={logo} alt="Logo icon"/>
      </Link>
    </>
  )
}
