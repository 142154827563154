import AccountIcon from '../assets/account.svg'
import AccountOrange from '../assets/account-orange.svg'
import HeartUnfilledWhite from '../assets/heart-unfilled-white.svg'
import DownArrowWhite from '../assets/menu-arrow-white.svg'
import DownArrow from '../assets/menu-arrow.svg'

import { MenuChildItem } from '../MenuChild/Interfaces'

// initalized Icons.
export const Icons = {
  ACCOUNT: AccountIcon,
  ACCOUNT_ORANGE: AccountOrange,
  HEART_UNFILLED_WHITE: HeartUnfilledWhite,
  DOWN_ARROW_WHITE: DownArrowWhite,
  DOWN_ARROW: DownArrow,
}

export enum MenuColor {
  ORANGE = 'orange',
  WHITE = 'white',
}

export interface MenuItem {
  title: string
  active?: boolean
  color?: MenuColor
  icon?: string
  hideDivider?: boolean
  menuChildren?: MenuChildItem[]
  onClick?: () => void
}

export interface IMenuOptions {
  items?: MenuItem[]
  onClose?: () => void
}
